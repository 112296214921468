import instance from "../services";

/**
 * 마이페이지 - 나의 정보 호출 API
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getMyInfo = async () => {
    try {
        return await instance.post('/api/myInfo');
    } catch (error) {
        console.error(error);
        throw error;
    }
};



/**
 * 마이페이지 - 내 심리 검사  리스트 API
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getMyInspectionList = async (params) => {
    try {
        return await instance.post('/api/mypage/myInspection', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * 마이페이지 - 내 리포트 리스트 API
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getMyReportList = async (params) => {
    try {
        return await instance.post('/api/mypage/myReport', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/**
 * 마이페이지 - 결제 리스트 API
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getMyOrderList = async (params) => {
    try {
        return await instance.post('/api/mypage/myOrderList', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/**
 * 마이페이지 - 탈퇴 사유 API
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getWithdrawalType = async () => {
    try {
        return await instance.post('/api/withdrawInfo');
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * 마이페이지 - 탈퇴 API
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const withdrawAccount  = async (leave_reason_code) => {
    try {
        return await instance.post('/api/mypage/leave',{leave_reason_code});
    } catch (error) {
        console.error(error);
        throw error;
    }
};




