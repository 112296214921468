import {useCallback, useEffect, useState} from "react";
import {getFaqList, getInquiryList, getNoticeList} from "../../services/Board/board";
import {getMyInspectionList, getMyOrderList, getMyReportList} from "../../services/MyPage/mypage";
import {useInView} from "react-intersection-observer";
import {getAlarmList} from "../../services/alarm/alarm";

export const useBoardList = (type, initialParams, isOnce = false) => {
    // 리스트
    const [boardCnt, setBoardCnt] = useState(null);
    const [boardList, setBoardList] = useState([]);
    const [topData, setTopData] = useState([]);
    const [params, setParams] = useState(initialParams);
    const {ref, inView} = useInView({
        threshold: 0.1,
    });

    const fetchBoardList = useCallback(async () => {
        try {
            let response;

            switch (type) {
                case 'faq':
                    response = await getFaqList(params)
                    break;
                case 'inquiry':
                case 'counsel':
                    response = await getInquiryList(params);
                    break;
                case 'test':
                    response = await getMyInspectionList(params);
                    break;
                case 'report':
                    response = await getMyReportList(params);
                    break;
                case 'payment':
                    response = await getMyOrderList(params);
                    break;
                case 'notice':
                    response = await getNoticeList(params);
                    break;
                case 'alarm':
                    response = await getAlarmList(params);
                    break;
                default:
                    return;
            }


            if (response.data.code === 200 && response.data.data.data.length > 0) {

                setBoardCnt(response.data.data.cntRow);
                setBoardList((prevList) => [...prevList, ...response.data.data.data]);

                //무한스크롤 사용시 isOnce =false , 한번만 호출 해야할때 isOnce = true
                if (!isOnce) {
                    setParams((prevParams) => ({
                        ...prevParams,
                        offset: prevParams.offset + 1,
                    }));
                }
                if (type === 'notice') {
                    setTopData(response.data.data.topData)
                }
            }
        } catch (error) {
            console.error("데이터 로드 중 오류 발생:", error);
        }
    }, [type, params, isOnce]);


    // 무한 스크롤로 데이터 로드
    useEffect(() => {
        if (inView) {
            if (boardList.length >= Number(boardCnt) && boardCnt) return;
            fetchBoardList();
        }
    }, [inView, boardList.length, boardCnt, fetchBoardList]);


    // 페이지 로드 시 초기 스크롤 위치 설정
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return {boardList, boardCnt, fetchBoardList, setParams, params, setBoardList, topData, ref};
};
