import axios from 'axios';

// 토스 결제 전용 인스턴스 생성
const tossInstance = axios.create({
    baseURL: 'https://api.tosspayments.com/v1',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

// 서버 통신용 인스턴스 생성 (토큰 인터셉터 없음)
const paymentInstance = axios.create({
    baseURL: 'https://admin.iqvitamininside.com',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

export const confirmPayment = async ({ paymentKey, orderId, amount }) => {
    try {
        const response = await tossInstance.post(
            '/payments/confirm',
            {
                paymentKey,
                orderId,
                amount: Number(amount),
            },
            {
                headers: {
                    Authorization: `Basic ${Buffer.from(process.env.REACT_APP_TOSS_SECRET_KEY + ':').toString('base64')}`,
                },
            }
        );
        
        // 토스 결제 성공 후 서버에 결제 정보 전송
        await paymentInstance.post('/api/payment/complete', {
            paymentKey,
            orderId,
            amount: Number(amount),
        });
        
        return response.data;
    } catch (error) {
        console.error('결제 처리 중 오류:', error);
        throw error;
    }
};



//  --기존 소스코드 ----
// import axios from 'axios';

// const TOSS_API_URL = 'https://api.tosspayments.com/v1';

// export const confirmPayment = async ({ paymentKey, orderId, amount }) => {
//     const response = await axios.post(
//         `${TOSS_API_URL}/payments/confirm`,
//         {
//         paymentKey,
//         orderId,
//         amount,
//         },
//         {
//         headers: {
//             Authorization: `Basic ${Buffer.from(process.env.REACT_APP_TOSS_SECRET_KEY + ':').toString('base64')}`,
//             'Content-Type': 'application/json',
//         },
//         }
//     );
    
//     return response.data;
// };