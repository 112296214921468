import instance from '../services';
import {INFORMATION_NICKNAME_URL, INFORMATION_PASSWORD_URL, INFORMATION_VERIFY_URL} from '../../utils/urls';
import useUserStore from '../../store/user/userStore';

// 닉네임 변경
export const updateNickname = async (params) => {
    try {
        const {memberTokens} = useUserStore.getState();
        const {nickname, push} = params
        if (!memberTokens) {
            throw new Error('로그인이 필요합니다.');
        }
console.log(nickname, push)
        const response = await instance.post(INFORMATION_NICKNAME_URL, {
            nickname, push
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// 비밀번호 변경
export const updatePassword = async (passwordData) => {
    try {
        const {userId, newPassword, confirmPassword} = passwordData;

        const response = await instance.post(INFORMATION_PASSWORD_URL, {
            userid: userId,
            password: newPassword,
            repassword: confirmPassword
        });
        return response;
    } catch (error) {
        throw error;
    }
};

// 점유인증 요청
export const verifyPossession = async (verifyData) => {
    try {
        const {userId, phoneNumber, idCheck} = verifyData;

        const response = await instance.post(INFORMATION_VERIFY_URL, {
            userid: userId,
            phoneNumber: phoneNumber,
            idCheck: idCheck
        });
        return response;
    } catch (error) {
        throw error;
    }
};
