import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import { updatePassword } from "../../services/Information/information";
import {validateInput} from "../../utils/validation";
import {ERROR_MESSAGE} from "../../utils/constants";
import {toast} from "react-toastify";
import { passwordvisible } from "../../utils/passwordvisible";

function NewPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state?.userid; // PasswordFind에서 전달받은 userid
    const errorMapping = {
        PASSWORD: false,
        PASSWORD_NOMATCH: false,
    }
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(errorMapping)
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isShowPw1, setShowPw1] = useState(false);
    const [isShowPw2, setShowPw2] = useState(false);

    useEffect(() => {
        const areFieldsFilled = newPassword.trim() !== '' && confirmPassword.trim() !== '';
        const hasNoErrors = Object.values(error).every(value => !value);

        setIsErrorFree(areFieldsFilled && hasNoErrors);
    }, [error, newPassword, confirmPassword]);
    const handleBackClick = () => {
        window.history.back();
    };

    const checkPwUsage = () => {
        if (newPassword.length < 6 || newPassword.length > 15) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }
        if (!validateInput(newPassword, true)) {
            return setError((prev) => ({
                ...prev,
                PASSWORD: true
            }));
        }

        return setError(errorMapping)
    }

    const checkPwConfirm = () => {
        if (confirmPassword && confirmPassword !== newPassword) {
            return setError((prev) => ({
                ...prev,
                PASSWORD_NOMATCH: true
            }));
        }
        if (confirmPassword&&validateInput(newPassword, true)) {
            return setError(errorMapping)
        }
    }

    const handleNextClick = async () => {
        try {


            const response = await updatePassword({
                userId: userId,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            });

            if (response.data.code === 200) {
                navigate('/newpassword/success');
            } else {
                toast(response.data.message)
            }
        } catch (error) {
            console.error('비밀번호 변경 실패:', error);
        }
    };
    
    return (
        <div className="idfind">
            <div className="content">
                <Header title="비밀번호 찾기" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="nickname_instruction">새 비밀번호로 변경해 주세요</div>
                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">새 비밀번호</div>
                        <div className="comb_input">
                            <input 
                                type={isShowPw1 ? 'text' : 'password'}
                                className="login_input" 
                                value={newPassword}
                                onBlur={() => {
                                    checkPwUsage();
                                    checkPwConfirm();
                                }}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="6~15자의 영문/숫자/특수문자 조합" 
                            />
                            <button 
                                type="button" 
                                className={`input_btn show_pw_btn ${isShowPw1 ? 'show' : ''}`}
                                onClick={() => setShowPw1(!isShowPw1)}
                                onMouseDown={(e) => passwordvisible(e, newPassword)}
                            ></button>
                        </div>
                        
                        {error.PASSWORD &&
                            <div className={`error_message nickname`}>{ERROR_MESSAGE.PASSWORD}</div>}
                            <div className="comb_input">
                                <input 
                                    style={{marginTop:'8px'}}
                                    type={isShowPw2 ? 'text' : 'password'}
                                    className="login_input" 
                                    value={confirmPassword}
                                    onBlur={checkPwConfirm}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="비밀번호 재입력" 
                                />
                                <button 
                                    type="button" 
                                    className={`input_btn show_pw_btn ${isShowPw2 ? 'show' : ''}`}
                                    onClick={() => setShowPw2(!isShowPw2)}
                                    onMouseDown={(e) => passwordvisible(e, confirmPassword)}
                                    style={{marginTop:'4px'}}
                                ></button>
                            </div>
                        
                        {error.PASSWORD_NOMATCH && (
                            <div className="error_message nickname">
                                { ERROR_MESSAGE.PASSWORD_NOMATCH }
                            </div>
                        )}
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button 
                        label="비밀번호 변경" 
                        variant="btn_next" 
                        onClick={handleNextClick}
                        disabled={!isErrorFree}
                        className={isErrorFree ? 'active' : 'disabled'}
                    />
                </div>
            </div>
        </div>
    );
}

export default NewPassword;
