import { useCallback } from "react";
import {getCounselTypeList, getFaqTypeList, getInquiryTypeList} from "../../services/Board/board";
import useMyPageStore from "../../store/mypage/myPageStore";

export const useBoardTypeList = (type) => {
    // 타입
    const { setListCtg, getSelectList } = useMyPageStore();

    const fetchBoardType = useCallback(async () => {
        try {
            let response;
            switch (type){
                case 'counsel':
                    response = await getCounselTypeList();
                    break;
                case 'inquiry':
                    response = await getInquiryTypeList();
                    break;
                case 'faq':
                    response = await getFaqTypeList();
                    break;
                default:
                    return;
            }

            if (response && response.data && response.data.code === 200) {
                type === 'faq'?setListCtg(response.data.data,true):setListCtg(response.data.data);

            } else {
                setListCtg([]);
            }
        } catch (e) {
            console.error( e);
            setListCtg([]);
        }
    }, [type, setListCtg]);

    return { fetchBoardType, getSelectList };
};
