import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { confirmPayment } from '../../services/payment/tossPaymentApi';
import { PAYMENT_ERROR_MESSAGES } from '../../utils/constants';

function PaymentResult() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const processSuccessPayment = async () => {
            try {
                const paymentKey = searchParams.get('paymentKey');
                const orderId = searchParams.get('orderId');
                const amount = searchParams.get('amount');

                const response = await confirmPayment({
                    paymentKey,
                    orderId,
                    amount: Number(amount),
                });

                if (response) {
                    alert('결제가 완료되었습니다.');
                    const redirectUrl = localStorage.getItem('paymentRedirectUrl') || '/childreninformation';
                    navigate(redirectUrl);
                }
            } catch (error) {
                console.error('결제 승인 중 오류:', error);
                alert('결제 승인 중 오류가 발생했습니다.');
                navigate('/');
            }
        };

        const processFailPayment = () => {
            const errorCode = searchParams.get('code');
            const errorMessage = searchParams.get('message');
            
            if (errorCode === 'PAY_PROCESS_CANCELED') {
                alert('결제가 취소되었습니다.');
            } else {
                alert(errorMessage || PAYMENT_ERROR_MESSAGES.DEFAULT);
            }
            
            const failUrl = localStorage.getItem('paymentFailUrl') || '/';
            window.location.href = failUrl;
        };

        const isSuccess = searchParams.get('paymentKey') && 
                         searchParams.get('orderId') && 
                         searchParams.get('amount');

        if (isSuccess) {
            processSuccessPayment();
        } else {
            processFailPayment();
        }

        return () => {
            localStorage.removeItem('paymentRedirectUrl');
            localStorage.removeItem('paymentFailUrl');
        };
    }, [navigate, searchParams]);

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh' 
        }}>
            <h2>결제를 처리중입니다...</h2>
        </div>
    );
}

export default PaymentResult;