import React from "react";
import JoinImg from "../../assets/img/join.png";
import JoinClose from "../../assets/img/join_close.png";
// import AppleLogin from "../../assets/img/apple.png";
import {Link, useNavigate} from "react-router-dom";
import NaverLoginButton from "../../components/Button/NaverLoginButton";
import AppleLoginButton from "../../components/Button/AppleLoginButton";
import {useKakaoLogin} from "../../hooks/login/snsLogin";
import {KAKAO_REDIRECT_URI} from "../../utils/urls";

function Join() {
    const navigate = useNavigate();
    const {handleKakaoLogin} = useKakaoLogin(KAKAO_REDIRECT_URI)


    const handleBackClick = () => {
        navigate('/')
    };


    return (

        <div className="join">

            <div className="content">
                <div className="close_container">
                    <img onClick={handleBackClick} src={JoinClose} alt="Close"/>
                </div>
                <div className="header_container">
                    <div className="logo">
                        <img src={JoinImg} alt="로고"/>
                    </div>
                    <div className="header_content">
                        <div className="tagline">우리 아이 몸맘뇌 케어</div>
                        <div className="product_name">아이큐비타민</div>
                    </div>
                </div>
                <div className="content_body">
                    <div className="image_group">

                        <AppleLoginButton/>
                        <NaverLoginButton/>

                        <button type="button" className="sns_btn left_icon kakao" onClick={handleKakaoLogin}>카카오로 시작하기
                        </button>
                        <Link style={{textDecoration: "none"}} to="/agreement" className="sns_btn join">
                            회원가입
                        </Link>
                    </div>
                    <div className="login_prompt">
                        이미 계정이 있나요?
                        <Link to="/login" style={{textDecoration: "none"}}><span
                            className="login_link"> 로그인</span></Link>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Join;