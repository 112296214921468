import React, { useEffect, useState, useRef } from "react";
import Video from "../VideoPlayer/Video";
import { useLocation, useNavigate } from "react-router-dom";
import { INSPECTION_QUESTION_URL } from "../../utils/urls";
import { instance } from "../../services/services";
import closeImg from '../../assets/img/ic_Close_1.png';

function VideoPlayer(){
    const navigate = useNavigate();
    const location = useLocation(); 
    const { Data } = location.state || {}; 
    const [questionData, setQuestionData] = useState(null);
    const fetchedRef = useRef(false);
    
    const close = () => {
        navigate('/storyinformation', {
            state: {
                goods_seq: Data?.goods_seq,
                order_seq: Data?.orderId,
                item_option_seq: Data?.item_option_seq
            }
        });
    };

    
    useEffect(() => {
        const fetchQuestionData = async () => {
            if (fetchedRef.current) return;
            
            try {
                const response = await instance.post(INSPECTION_QUESTION_URL, {
                    order_seq: Data?.orderId,
                    item_option_seq: Data?.item_option_seq,
                    goods_seq: Data?.goods_seq
                });

                if (response.data.code === 200) {
                    setQuestionData(response.data.data);
                    fetchedRef.current = true;
                } else {
                    console.error('문항 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('문항 데이터 조회 오류:', error);
            }
        };

        if (Data?.orderId && Data?.item_option_seq && Data?.goods_seq) {
            fetchQuestionData();
        }

        return () => {
            fetchedRef.current = false;
        };
    }, [Data]);


    return(
        <>
            <div className="video_header">
                <div className="video_title">
                    {Data?.goods_name}
                </div>
                <div className="video_close">
                    <img src={closeImg} alt="closeImg" className="icon closeImg" onClick={close}/>
                </div>
            </div>

            <div className="video_container">
                <Video 
                    videoData={Data}
                    questionData={questionData}
                />
            </div>
        </>
    );
}

export default VideoPlayer;