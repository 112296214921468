import {create} from 'zustand';
import {persist} from "zustand/middleware";


// 로딩 , 공통 사용 store 담는 곳
const useCommonStore = create(
    persist(
        (set, get) => ({
            alarmCnt: 0,
            setAlarmCnt: (cnt) => set({ alarmCnt: cnt }),
            currentUrl: '',
            setCurrentUrl: (url) => set({ currentUrl: url }),
        }),
        {
            name: 'common-storage', // 로컬 스토리지 키 이름
            partialize: (state) => ({ currentUrl: state.currentUrl }), // currentUrl만 저장
        }
    )
);
export default useCommonStore;

