import styled from "styled-components";

const size = {
    md: {
        padding: "6px 8px",
        display: "inline-block",
        radius: "12px",
        font: "var(--12-label-bold)",
        letterSpacing: "var(--caption1-letter-spacing)",
    },
    sm: {
        padding: "5px 6px",
        display: "inline-block",
        radius: "6px",
        font: "600 10px/100% 'Pretendard', sans-serif",
        letterSpacing: "var(--tiny1-letter-spacing)",
    },
};

const colorList = {
    sm: {
        "primary-50": {
            color: "var(--primary-800)",
        },
        "primary-500": {
            color: "var(--neutral-white)",
        },
        "secondary-50": {
            color: "var(--secondary-400)",
        },
        "neutral-50": {
            color: "var(--neutral-600)",
        },
    },
    "primary-50": {
        bgColor: "var(--primary-50)",
        color: "var(--primary-900)",
    },
    "primary-500": {
        bgColor: "var(--primary-500)",
        color: "var(--neutral-white)",
    },
    "primary-900": {
        bgColor: "var(--primary-900)",
        color: "var(--primary-50)",
    },

    "primary-60": {
        bgColor: "var(--primary-900)",
        color: "var(--primary-50)",
    },
    "secondary-50": {
        bgColor: "var(--secondary-50)",
        color: "var(--secondary-600)",
    },
    "neutral-50": {
        bgColor: "var(--neutral-50)",
        color: "var(--neutral-700)",
    },
    "red":{
        bgColor: "#FDE8E6",
        color: "#FF3D1C",
    }
    

};

const StyledBadge = styled.span`
    padding: ${({ $size }) => size[$size].padding};
    background-color: ${({ $badgeColor }) => colorList[$badgeColor].bgColor};
    color: ${(props) => getColor(props)};
    display: ${({ $size }) => size[$size].display};
    border-radius: ${({ $size }) => size[$size].radius};
    font: ${({ $size }) => size[$size].font};
    letter-spacing: ${({ $size }) => size[$size].letterSpacing};
    line-height: ${({ $size }) => size[$size].lineHeight};
`;

const getColor = ({ $size, $badgeColor }) => {
    return $size === "sm"
        ? colorList[$size]?.[$badgeColor]?.color || "inherit"
        : colorList[$badgeColor]?.color || "inherit";
};

const Badge = ({ badgeText='뱃지', size = "md", badgeColor = "primary-500" }) => {
    return (
        <StyledBadge $size={size} $badgeColor={badgeColor}>
            {badgeText}
        </StyledBadge>
    );
};

export default Badge;
