import React, { useState } from "react";
import { Data }from "../../../data/mock/question";
import Button from "../../../components/Button/Button";
import Test_Img from "../../../assets/img/test_img.png";
import { useNavigate } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";


const ResponseOption = ({ score, isActive, onClick, label }) => (
    <div className="response-option">
        <div className={`response-score ${isActive ? "activeCheck" : ""}`} onClick={onClick}>
            {score}
        </div>
        <div className="response-label">{label}</div>
    </div>
);

const Question = ({ question, index, selectedCheck, onNumberCheck }) => (
    <div className="question-container">
        <div className="question-number">{question.count}</div>
        <div className="question-text">{question.content}</div>
        <div className="response-container">
            {[1, 2, 3, 4, 5].map((score) => (
                <ResponseOption
                    key={score}
                    score={score}
                    isActive={selectedCheck === `Check${score}`}
                    onClick={() => onNumberCheck(index, `Check${score}`)}
                    label={
                        score === 1 ? (
                            <>
                                <span>전혀</span>
                                <br />
                                <span>아니다</span>
                            </>
                        ) : score === 2 ? (
                            <span>아니다</span>
                        ) : score === 3 ? (
                            <span>보통</span>
                        ) : score === 4 ? (
                            <span>그렇다</span>
                        ) : (
                            <>
                                <span>매우</span>
                                <br />
                                <span>그렇다</span>
                            </>
                        )
                    }
                />
            ))}
        </div>
    </div>
);

const QuestionList = ({ questions, selectedCheck, onNumberCheck }) => (
    <div>
        {questions.map((question, index) => (
            <Question
                key={index}
                question={question}
                index={index}
                selectedCheck={selectedCheck[index]}
                onNumberCheck={onNumberCheck}
            />
        ))}
    </div>
);

const GeneralTestDetail = () => {
    const navigate = useNavigate();
    const [tab, setTab] = useState('tab1');
    const [numberCheck, setNumberCheck] = useState({
        tab1: {},
        tab2: {},
        tab3: {},
        tab4: {},
        tab5: {},
        tab6: {},
    });
    const [showImage, setShowImage] = useState(false);
    const [showQuestions, setShowQuestions] = useState(true);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);


    const handleNumberCheck = (questionIndex, selectedCheck, currentTab) => {
        setNumberCheck((prev) => ({
            ...prev,
            [currentTab]: {
                ...prev[currentTab],
                [questionIndex]: selectedCheck,
            },
        }));
    };

    const allAnswered = (numberCheck) => {
        return Object.keys(numberCheck).length === Data.question.length;
    };

    const handleButtonClick = () => {
        if (showQuestions) {
            if (allAnswered(numberCheck[tab])) {
                if (tab === 'tab6') {
                    navigate('/testcomplete'); // tab6에서 문제를 다 푼 후 이동
                } else {
                    setShowQuestions(false);
                    setShowImage(true);
                }
            }
        } else {
            setShowImage(false);
            setTab(tab === 'tab6' ? 'tab1' : `tab${parseInt(tab.replace('tab', '')) + 1}`);
            setShowQuestions(true);
        }
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };


    return (
        <div className="generalTestDetail">
            <div className="content">
                <Header title="일반 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div style={{ display: "flex", gap: "4px" }}>
                        {[...Array(6)].map((_, index) => (
                            <div
                                key={`tab${index + 1}`}
                                className={`tab ${tab === `tab${index + 1}` ? "activeTab" : ""}`}
                                onClick={() => setTab(`tab${index + 1}`)}
                            />
                        ))}
                    </div>

                    <div className="tabContent">
                        <div>
                            {showQuestions && (
                                <QuestionList
                                    questions={Data.question}
                                    selectedCheck={numberCheck[tab]}
                                    onNumberCheck={(index, check) => handleNumberCheck(index, check, tab)}
                                />
                            )}
                            {showImage && (
                                <div className="image-overlay">
                                    <img className="Test_Img" src={Test_Img} alt="설명" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button 
                        label="다음" 
                        variant="btn_next" 
                        className={showImage || (showQuestions && allAnswered(numberCheck[tab])) ? "active" : "disabled"} 
                        onClick={handleButtonClick} 
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
            </div>
        </div>
    );
};

export default GeneralTestDetail;
