import { useState, useCallback } from "react";

const useToggle = (initialState = false) => {
    // 토글
    const [isOpen, setIsOpen] = useState(initialState);

    const toggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    return { isOpen, toggle, close, open };
};

export default useToggle;