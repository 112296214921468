import React, {useEffect} from 'react';
import {useAppleLogin} from "../../hooks/login/snsLogin";
import {useNavigate} from "react-router-dom";

const AppleLoginButton = () => {
    const navigate = useNavigate();
    const {handleAppleLogin,snsInfo} = useAppleLogin('https://www.iqvitamininside.com/agreement')

    useEffect(() => {
        console.log('완료', snsInfo); // snsInfo 업데이트 시점 확인
        if (snsInfo?.fromSns) {
            navigate('/agreement', { state: snsInfo });
        }
    }, [snsInfo, navigate]);

    return (
        <button type="button" className="sns_btn left_icon apple" onClick={handleAppleLogin}>Apple로 시작하기</button>
    );
};

export default AppleLoginButton; 