import React from "react";
import Button from "../../components/Button/Button";
import Ic_result from "../../assets/img/ic_result.png";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";


function PasswordSuccess() {
    const navigate = useNavigate();

    const handleSuccesClick = () => {
        navigate('/');
    }
    return (
        <div className="passwordsuccess">
            <div className="content">

                <Header title="비밀번호 변경 완료" isShowBack={false}/>
                <div className="success_body">
                    <div className="success_message">
                        <div className="success_image">
                            <img src={Ic_result} alt="이미지"/>
                        </div>
                        <div className="registration_message">
                            <div className="success_text">비밀번호가 새롭게 변경되었어요</div>
                            <div className="safety_message">안전한 정보 보호를 위해 주기적으로 <br/>비밀번호를 변경해 주세요.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button
                        label="완료"
                        variant="btn_complete"
                        onClick={handleSuccesClick}/>
                </div>

            </div>
        </div>
    )
}

export default PasswordSuccess;