import React from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Report from '../Report/Report';
import ReactDOM from 'react-dom';

function MyReportList({ listData }) {
    console.log(listData, '::listData');
    const handleDownloadPDF = async (item) => {
        const imagePath = require(`../../assets/report/${item.goods_seq}.png`);
    
        // 이미지 경로가 유효한지 확인
        try {
            await new Promise(resolve => setTimeout(resolve, 2000)); // 2초 대기
    
            const pdf = new jsPDF('p', 'mm', 'a4');
    
            // 이미지 캔버스 생성
            const imgCanvas = document.createElement('canvas');
            const imgCtx = imgCanvas.getContext('2d');
            const img = new Image();
            img.src = imagePath;
    
            img.onload = async () => {
                const a4WidthInPixels = 210 * 3.78; // A4 width in pixels
                imgCanvas.width = a4WidthInPixels; // A4 width in pixels
                imgCanvas.height = (img.height * imgCanvas.width) / img.width; // 비율에 맞게 높이 설정
                imgCtx.drawImage(img, 0, 0, imgCanvas.width, imgCanvas.height);
    
                const imgData = imgCanvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 0, 0, imgCanvas.width / 3.78, imgCanvas.height / 3.78); // PDF에 추가할 때 해상도 조정
                pdf.addPage(); // 새로운 페이지 추가
    
                // Report 컴포넌트 렌더링
                const tempDiv = document.createElement('div');
                document.body.appendChild(tempDiv);
                ReactDOM.render(
                    <Report 
                        reportData={item} 
                        listData={listData}  // listData 전체를 전달
                    />, 
                    tempDiv
                );
    
                await new Promise(resolve => setTimeout(resolve, 1000)); // 렌더링 대기
    
                const reportCanvas = await html2canvas(tempDiv, {
                    scale: 2, // 해상도를 높임
                    useCORS: true,
                    logging: false,
                    backgroundColor: null,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: tempDiv.scrollHeight,
                });
    
                const reportImgData = reportCanvas.toDataURL('image/png');
                // Report 이미지 크기를 줄이기 위해 width와 height를 조정
                const reportImgWidth = 210 * 1.1;
                const reportImgHeight = (reportCanvas.height * reportImgWidth) / reportCanvas.width; // 비율에 맞게 높이 설정
                
                // 중앙 정렬을 위해 x 좌표를 계산
                const xOffset = (210 - reportImgWidth) / 2; // A4 너비에서 이미지 너비를 빼고 2로 나누어 중앙 정렬
                
                pdf.addImage(reportImgData, 'PNG', xOffset, 0, reportImgWidth, reportImgHeight); // 중앙 정렬된 위치에 추가
                pdf.save(`${item.goods_name}_report.pdf`);
    
                document.body.removeChild(tempDiv);
            };
    
            img.onerror = () => {
                alert('이미지를 불러오는 데 실패했습니다. 해당 이미지가 존재하는지 확인해주세요.');
            };
        } catch (error) {
            console.error('PDF 생성 중 오류:', error);
            alert('PDF 생성에 실패했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className="forward_type my_list">
            {listData.map((item, index) => (
                <div className="assessment_info childrenDetail" key={`${item.goods_seq}-${index}`}>
                    <div className="assessment_details">
                        <div className="assessment_title ellipsis1">{item.goods_name}</div>
                        <div className="assessment_meta group_line" style={{ paddingTop: "6px" }}>
                            {item?.story_title_sub &&
                                <div className="assessment_code group_item">{item.story_title_sub}</div>}
                            {item?.inspection_cnt &&
                            <div className="assessment_code group_item">{item.inspection_cnt}회차</div>}
                        </div>
                        <button 
                            type="button" 
                            className="resend_button"
                            onClick={() => handleDownloadPDF(item)}
                        >
                            PDF 다운받기
                        </button>
                    </div>
                    <div className="children_image_container">
                        <img className="Children_Img" src={item.list_image} alt="Children_Img" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MyReportList;
