import { instance } from '../services';
import { LOGIN_INFO_URL, TOKEN_URL, LOGIN_KAKAO_INFO_URL, LOGIN_NAVER_INFO_URL, APPLE_REGISTER_URL } from '../../utils/urls';

// 로그인
export const loginUser = async (userid, password, autoLogin = false) => {
    try {
        console.log('로그인 요청 데이터:', {
            userid,
            password,
            keepLoginChk: autoLogin ? 'Y' : 'N'
        });

        const response = await instance.post(LOGIN_INFO_URL, {
            userid,
            password,
            keepLoginChk: autoLogin ? 'Y' : 'N',
        });

        console.log('로그인 응답:', response);
        return response;
    } catch (error) {
        console.error('로그인 실패 상세:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        throw error;
    }
};



// 카카오 회원가입
export const registerKakaoUser = async (userPayload, accessToken) => {
    try {
        const { id_token } = userPayload;  // body에서 id_token 추출
        
        console.log('카카오 회원가입 요청:', {
            body: userPayload,
            idToken: id_token
        });

        const response = await instance.post(
            LOGIN_KAKAO_INFO_URL, 
            userPayload,
            {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        console.log('카카오 회원가입 응답:', response.data);

        return {
            token: response.data.access_token,
            userInfo: response.data.userInfo
        };
    } catch (error) {
        console.error('카카오 회원가입 실패:', error);
        throw error;
    }
};

// 네이버 회원가입
export const registerNaverUser = async (userPayload) => {
    try {
        const response = await instance.post(LOGIN_NAVER_INFO_URL, userPayload);
        console.log(response, '::::response 회원가입 함수');

        // 토큰값과 유저 정보 리턴
        return {
            token: response.data.token,
            userInfo: response.data.userInfo,
        };
    } catch (error) {
        console.error('회원가입 실패:', error);
        throw new Error('회원가입 중 문제가 발생했습니다.'); 
    }
};

// 애플 회원가입
export const registerAppleUser = async (userPayload) => {
    try {
        // identityToken 제거하고 필요한 데이터만 전송
        const requestData = {
            email: userPayload.email,
            user_name: userPayload.user_name,
            nickname: userPayload.nickname,
            mem_phone: userPayload.mem_phone,
            id_token: userPayload.id_token
        };

        console.log('애플 회원가입 요청 데이터:', requestData);

        const response = await instance.post(APPLE_REGISTER_URL, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userPayload.id_token}`
            },
        });

        console.log('서버 응답:', response);

        if (response.data.success === false) {
            throw new Error(response.data.message);
        }

        return response.data;
    } catch (error) {
        console.error('애플 회원가입 실패:', {
            status: error.response?.status,
            data: error.response?.data,
            message: error.message
        });
        throw error;
    }


};

/**
 * 토큰 갱신
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const refreshToken = async () => {
    try {
        return await instance.post(TOKEN_URL);
    } catch (error) {
        console.error(error);
        throw error;
    }
}