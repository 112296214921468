import React, { useState, useEffect } from "react";
import Bag_Img from "../../assets/img/ic_bag.png";
import Menu_Img from "../../assets/img/ic_Sort.png";
import Story_Img from "../../assets/img/storyimg.png"; 
import Tab from "../../components/Tab/Tab";
import Icon_heart_prescription from '../../assets/img/Vector215.png';
import Icon_story from '../../assets/img/Icon_story.png';
import Icon_home from '../../assets/img/ic_home.png';
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import Badge from "../../components/Badge/Badge";
import { STORY_TEST_URL } from "../../utils/urls";
import instance from "../../services/services";

function StoryTestPage(){
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false); 
    const [storyTData, setStoryData] = useState(null); // 동화에 대한 데이터 상태
    console.log(storyTData, ':::storyTData');

        // API 호출
        useEffect(() => {
            const fetchStoryData = async () => {
                try {
                    const response = await instance.post(STORY_TEST_URL, {
                        packageInfoChk: "N"
                    });
    
                    if (response.data.code === 200) {
                        setStoryData(response.data.data);
                    } else {
                        console.error('스토리 데이터 조회 실패:', response.data.message);
                    }
                } catch (error) {
                    console.error('스토리 데이터 조회 오류:', error);
                }
            };
    
            fetchStoryData();
        }, []);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev); // 메뉴 상태 토글
    };

    const handleNextClick = (item) => {
        navigate('/callpayment', {
        });
    };

    const handlePageClick = (item) => {
        navigate('/callsinglepayment', {
            state: {
                goodsSeq: item.goods_seq,
                packageCnt: item.packageCnt,
                category: getCategoryName(item.goods_name),  // 카테고리 정보 추가
            }
        });
    };

    // 카테고리 매핑
    const getCategoryName = (goodsName) => {
        console.log(goodsName, '::goodsName');
        const categoryMap = {
            '건강하게 자라기': '신체발달',
            '건강하게 공부하기': '학습',
            '건강하게 생활하기': '정서행동',
            '건강하게 표현하기': '성격행동',
            '건강하게 관계맺기': '애착',
            '건강하게 사귀기': '사회성',
            '건강하게 결정하기': '도덕성',
            '건강하게 키우기': '신체발달'
        };
        return categoryMap[goodsName] || '';
    };

    const renderTestTypes = () => {
        if (!storyTData) return null;

        return (
            <>
                <div className="test_type_options"> 
                    <div 
                        className="test_type" 
                        onClick={() => handleNextClick(storyTData[0])}
                    >
                        <div className="test_type_title">
                            검사 유형<br />
                            전체 구매
                        </div>
                        <div className="test_type_count">{storyTData[0]?.packageCnt}권</div>
                    </div>
                    <div 
                        className="test_type" 
                        onClick={() => handlePageClick(storyTData[1])}
                    >
                        <div className="test_type_title">
                            {storyTData[1]?.goods_name.split(' ').map((word, i) => (
                                <React.Fragment key={i}>
                                    {word}
                                    {i === 0 && <br />}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="test_type_count">
                            <div>
                                {getCategoryName(storyTData[1]?.goods_name)}
                                <span className="vertical-bar"></span>
                                <span>{storyTData[1]?.packageCnt}권</span>
                            </div>
                        </div>
                    </div>
                </div>

                {[2, 4, 6].map((startIdx) => ( 
                    <div key={startIdx} className="test_type_options">
                        {storyTData.slice(startIdx, startIdx + 2).map((item) => (
                            <div 
                                key={item.goods_seq} 
                                className="test_type"
                                onClick={() => handlePageClick(item)}
                            >
                                <div className="test_type_title">
                                    {item.goods_name.split(' ').map((word, i) => (
                                        <React.Fragment key={i}>
                                            {word}
                                            {i === 0 && <br />}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="test_type_count">
                                    <div>
                                        {getCategoryName(item.goods_name)}
                                        <span className="vertical-bar"></span>
                                        <span>{item.packageCnt}권</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}

                <div className="test_type_options">
                    <div 
                        className="test_type"
                        onClick={() => handlePageClick(storyTData[8])}
                    >
                        <div className="test_type_title">
                            {storyTData[8]?.goods_name.split(' ').map((word, i) => (
                                <React.Fragment key={i}>
                                    {word}
                                    {i === 0 && <br />}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="test_type_count">
                            <div>
                                {getCategoryName(storyTData[8]?.goods_name)}
                                <span className="vertical-bar"></span>
                                <span>{storyTData[8]?.packageCnt}권</span>
                            </div>
                        </div>
                    </div>
                    <div className="test_type hidden_test_type"></div>
                </div>
            </>
        );
    };

    const tabsData = [
        { icon: Icon_story, label: '' },
        { icon: Icon_home, label: '홈' },
        { icon: Icon_heart_prescription, label: '마음처방'},
    ];

    const handleCartClick = () => {
        navigate('/shoppingcart', {
            state: {
                goods_seq: String(storyTData?.[0]?.goods_seq), // 현재 선택된 상품의 goods_seq
                ea: "1"
            }
        });
    };

    const alertIcons = (
        <div className='title_icon'>
            <div onClick={handleCartClick} style={{ cursor: 'pointer' }}>
                <img className='icon notification_icon' src={Bag_Img} alt="알림 아이콘" />
            </div>
            <img 
                className='icon menu_icon' 
                onClick={toggleMenu} 
                style={{ cursor:'pointer'}} 
                src={Menu_Img} 
                alt="메뉴 아이콘" 
            />
        </div>
    );

    return(
        <div className="StoryTestPage">
            <div className="content">
                <div className="main_title">
                        <Link to="/" className="counsel_title text_none">동화 검사를 진행해 보세요!</Link>
                        {alertIcons}
                        <NavBar isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}/>
                </div>
                <div className="content_body">
                    <div className="intro_section">
                    <Badge badgeColor="primary-60" badgeText="동화" />
                        <div className="intro_description_test">
                            <div className="test_title">비타민 웰빙 동화 검사</div>
                            <div className="test_info">비타민 웰빙 솔루션 동화는 생활 밀착형 스토리를 기반으로 하는 감성 동화입니다. 동화 검사를 진행하여 자녀 심리/정서 맞춤 솔루션을 제공하고, 아이의 성장 발달 문제를 예방하는 심리 정서 교육서입니다.</div>
                        </div>
                    </div>
                    
                    <div className="book_info">
                        <div className="total_books">본 동화 총 <span className="books_number">60</span>권</div>
                        <div className="parent_child-books">아이 동화 51권, 아이와 함께 읽는 부모 동화 9권</div>
                        <div className="book_image">
                            <img src={Story_Img} className="Story_Img" alt="Story_Img" />
                        </div>
                    </div>

                    <div className="test_type_selection">
                        <div className="test_type_header">동화 검사 유형을 선택해 주세요</div>
                        <div className="test_type_container">
                            {renderTestTypes()}
                        </div>
                    </div>
                    <div>
                        <Tab tabs={tabsData}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoryTestPage;