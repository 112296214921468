const question = [
    {count: "Q1", content: "나는 힘들어도 잘 울지 않아요"},
    {count: "Q2", content: "나는 힘들어도 잘 울지 않아요2"},
    {count: "Q3", content: "나는 힘들어도 잘 울지 않아요3"},
    {count: "Q4", content: "나는 힘들어도 잘 울지 않아요4"},
    // { count: "Q2", content: "나는 힘들어도 잘 울지 않아요2"},
    // { count: "Q3", content: "나는 힘들어도 잘 울지 않아요3"},
    // { count: "Q4", content: "나는 힘들어도 잘 울지 않아요4"},
    // { count: "Q5", content: "나는 힘들어도 잘 울지 않아요5"},
    // { count: "Q6", content: "나는 힘들어도 잘 울지 않아요6"},
    // { count: "Q7", content: "나는 힘들어도 잘 울지 않아요7"},
    // { count: "Q8", content: "나는 힘들어도 잘 울지 않아요8"},
    // { count: "Q9", content: "나는 힘들어도 잘 울지 않아요9"},
    // { count: "Q10", content: "나는 힘들어도 잘 울지 않아요10"},
];

const question1 = [
    {count: "Q1", content: "감사한", title: "다음의 감정 중 내가 친구들을 생각할 때의 느낌에 해당되는 것을 체크해 주세요"},
    {count: "Q2", content: "슬픈 우울한", title: "다음의 감정 중 내가 친구들을 생각할 때의 느낌에 해당되는 것을 체크해 주세요"},
    {count: "Q3", content: "재미있는", title: "다음의 감정 중 내가 친구들을 생각할 때의 느낌에 해당되는 것을 체크해 주세요"},
    // { count: "Q4", content: "나는 힘들어도 잘 울지 않아요4"},
    // { count: "Q5", content: "나는 힘들어도 잘 울지 않아요5"},
    // { count: "Q6", content: "나는 힘들어도 잘 울지 않아요6"},
    // { count: "Q7", content: "나는 힘들어도 잘 울지 않아요7"},
    // { count: "Q8", content: "나는 힘들어도 잘 울지 않아요8"},
    // { count: "Q9", content: "나는 힘들어도 잘 울지 않아요9"},
    // { count: "Q10", content: "나는 힘들어도 잘 울지 않아요10"},
];


const Data = {question};
const Data1 = {question1}
export {Data, Data1}




