import React, {useState} from "react";
import dayjs from "dayjs";
import Header from "../../components/Header/Header";
import {useNavigate} from 'react-router-dom';
import {useBoardList} from "../../hooks/board/useBoardList";

function Alert() {
    const navigate = useNavigate();
    const [backgroundType] = useState("bg-white");
    const initialParams = {offset: 1};
    const {
        boardList,
        ref,
    } = useBoardList("alarm", initialParams);

    const handleBackClick = () => {
        navigate(-1);
    };

    return (

        <div className="alert">
            <Header title="알림" handleBack={handleBackClick}/>
            <div className={`content ${backgroundType}`}>

                <div className="default_margin">
                    {boardList.length > 0 ? (
                        boardList.map((alarm, index) => (
                            <div key={index} className="alert_item">
                                <div className="alert_content">{alarm.message}</div>
                                <div className="alert_time">{dayjs(alarm.insert_date).format('MM월 DD일')}</div>
                            </div>
                        ))
                    ) : (
                        <div className="no_data ">데이터가 없습니다.</div>
                    )}
                    <div ref={ref}/>
                </div>
            </div>
        </div>
    );
}

export default Alert;
