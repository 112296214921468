import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Badge from "../../../components/Badge/Badge";
import Header from "../../../components/Header/Header";
import instance from "../../../services/services";
import { SYNTHESIS_LIST_URL } from "../../../utils/urls";

function SynthesisTest() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const handleBackClick = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    }
    const [data, setData] = useState([]);
    console.log(data, ':::data');

    useEffect(() => {
        const fetchSynthesisData = async () => {
            try {
                const response = await instance.post(SYNTHESIS_LIST_URL, {
                    goods_type: "compr"
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error("데이터 조회 실패");
                }
            } catch (error) {
                console.error("error 발생");
            }
        };
        fetchSynthesisData();
    }, []);


    const handleNextClick = (goodsSeq) => {
        navigate('/synthesispayment', {
            state: { goods_seq: goodsSeq }
        });
    };

    const handlesingletestClick = (goodsSeq) => {
        navigate('/singletest', {
            state: { goods_seq: goodsSeq }
        });
    };

    return (
        <div className="generalTest">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick} />
                <div className="content_body">
                    <div className="intro_section">
                        <Badge badgeColor="secondary-50" badgeText="종합" />
                        <div className="intro_description_test">
                            <div className="test_title">몸맘뇌 성장 종합 심리 검사</div>
                            <div className="test_info">
                                건강 생활 습관, 사회성 발달 성향, 가족 관계, 종합 학습 성향, 긍정 성격의 총 5가지 검사로 구성된 아동 헬스케어 검사로 만 5세 ~12세 아동의 종합적인 성장 발달과 심리 상태를 확인합니다.
                            </div>
                        </div>
                    </div>
                    <div className="age_selection">
                        <div className="age_selection_title">검사 영역을 선택해 주세요</div>
                        <div className="synthesis-age-options">
                            <div className="synthesis-age-option">
                                <div className="synthesis-age-group-item" onClick={() => handleNextClick("5종 전체")}>
                                    <div className="synthesis-age-label">5종 전체</div>
                                    <div className="synthesis-age-range">802 문항</div>
                                </div>
                                <div className="synthesis-age-group-item" onClick={() => handlesingletestClick(data[4]?.goods_seq)}>
                                    <div className="synthesis-age-label">{data[4]?.goods_name}</div>
                                    <div className="synthesis-age-range">{data[4]?.qstCnt} 문항</div>
                                </div>
                            </div>

                            <div className="synthesis-age-option" style={{ marginTop: "8px" }}>
                                <div className="synthesis-age-group-item" onClick={() => handlesingletestClick(data[2]?.goods_seq)}>
                                    <div className="synthesis-age-label">{data[2]?.goods_name}</div>
                                    <div className="synthesis-age-range">{data[2]?.qstCnt} 문항</div>
                                </div>
                                <div className="synthesis-age-group-item" onClick={() => handlesingletestClick(data[3]?.goods_seq)}>
                                    <div className="synthesis-age-label">{data[3]?.goods_name}</div>
                                    <div className="synthesis-age-range">{data[3]?.qstCnt} 문항</div>
                                </div>
                            </div>

                            <div className="synthesis-age-option" style={{ marginTop: "8px" }}>
                                <div className="synthesis-age-group-item" onClick={() => handlesingletestClick(data[0]?.goods_seq)}>
                                    <div className="synthesis-age-label">{data[0]?.goods_name}</div>
                                    <div className="synthesis-age-range">{data[0]?.qstCnt} 문항</div>
                                </div>
                                <div className="synthesis-age-group-item" onClick={() => handlesingletestClick(data[1]?.goods_seq)}>
                                    <div className="synthesis-age-label">{data[1]?.goods_name}</div>
                                    <div className="synthesis-age-range">{data[1]?.qstCnt} 문항</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SynthesisTest;
