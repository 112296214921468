import React from "react";
import Button from "../../components/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import success_check from '../../assets/img/success_check.png';
import Success_Img from '../../assets/img/Frame 427319007.png';
import dayjs from "dayjs";
import NaverLogin from "../../assets/img/ic_sns_naver.png";
import KakaoLogin from "../../assets/img/ic_sns_KakaoTalk.png";
import AppleLogin from "../../assets/img/ic_sns_AppleTalk.png";
function IdFindSuccess(){
    const navigate = useNavigate();
    const location = useLocation();
    const foundId = location.state?.foundId || '';


    const handleSuccesClick = () => {
        navigate('/login');
    }
    const getSnsIcon = (joinPath) => {
        switch (joinPath) {
            case "kakao":
                return KakaoLogin;
            case "naver":
                return NaverLogin;
            case "apple":
                return AppleLogin;
            default:
                return NaverLogin
        }
    };

    const getSnsWord = (joinPath) => {
        switch (joinPath) {
            case "kakao":
                return '카카오';
            case "naver":
                return '네이버';
            case "apple":
                return '애플';
            default:
                return '네이버'
        }
    };


    return(


        <div className={`${location.state.snsUserData.join_path !== 'direct' ? 'sns':'normal' } passwordsuccess`}>
            <div className="content">
                <div className="content_body">
                    {location.state?.snsUserData.join_path !== 'direct' &&
                        <div className="success_message_container">
                            <div className="success_icon_wrapper">
                                <img src={success_check} alt="success_check" className="success_check"/>
                            </div>
                            <div className="success_message">
                                가입된 계정을 찾았어요
                            </div>
                            <div className="sns_user_con">
                                <img className="sns_user_icon" src={getSnsIcon(location.state?.snsUserData.join_path)} alt="sns Icon"/>
                                <div className="sns_user_info">
                                    <p className="title">{getSnsWord(location.state?.snsUserData.join_path)}에서 계정 정보를 확인하세요</p>
                                    <p className="group_line desc">
                                        <span className="group_item ">{getSnsWord(location.state?.snsUserData.join_path)}</span>
                                        <span className="group_item ">{dayjs(location.state?.snsUserData.registe_date).format('YYYY.MM.DD')} 가입</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {location.state?.snsUserData.join_path === 'direct' &&
                        <div className="success_message_container">
                            <div className="success_icon_wrapper">
                                <img src={success_check} alt="success_check" className="success_check"/>
                            </div>
                            <div className="success_message">
                                고객님의 아이디는<br/><span className="found_userid">{foundId}</span>입니다
                            </div>
                        </div>
                    }

                    <div className="success_image_container">
                        <img src={Success_Img} alt="Success_Img" className="Success_Img"/>
                    </div>

                    <div className="Edit_btn_change">
                        <Button
                            label="로그인 페이지로"
                            variant="btn_complete"
                            onClick={handleSuccesClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IdFindSuccess;