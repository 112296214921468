import React from 'react';
import styled from "styled-components";

const theme = {
    btn_change: {
        background: "#D0D1D3",
        color: "#FFFFFF",
        border: "none",
        borderRadius: "32px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        padding: "20px 16px",
        activeBackground: "#29C5CE",
        activeColor: "#FFFFFF",
    },
    btn_complete: {
        background: "#01BCC8",
        color: "#FFFFFF",
        border: "none",
        borderRadius: "32px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        padding: "20px 16px",
    },
    btn_edit: {
        background: "#FFFFFF",
        border: "1px solid #EEEEEF",
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "100%",
        letterSpacing: "-0.375px",
        marginTop: "24px",
        padding: "15.5px 16px",
        color: "#212223",
    },
    btn_next: {
        background: "#D0D1D3",
        color: "#fff",
        border: "none",
        borderRadius: "32px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        padding: "20px 16px",
        activeBackground: "#29C5CE",
        activeColor: "#FFFFFF",
    },
    btn_join: {
        background: "#DEFDFF",
        color: "#01BCC8",
        border: "none",
        borderRadius: "32px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "16px",
        padding: "20px 16px",
    },
};

const StyledButton = styled.button`
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.color};
    padding: ${(props) => props.theme.padding};
    font-size: ${(props) => props.theme.fontSize};
    font-weight: ${(props) => props.theme.fontWeight};
    line-height: ${(props) => props.theme.lineHeight};
    border: ${(props) => props.theme.border};
    border-radius: ${(props) => props.theme.borderRadius};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    margin-top: ${(props) => props.theme.marginTop || '0'};

    &.active {
        background: #01BCC8;
        color: #FFFFFF;
    }

    &:active {
        background-color: ${(props) => props.theme.activeBackground || props.theme.background};
        color: ${(props) => props.theme.activeColor || props.theme.color};
    }
`;

const Button = ({label, onClick, type = 'button', className = '', disabled = false, variant = ''}) => {
    const buttonTheme = theme[variant] || {};

    return (
        <StyledButton
            type={type}
            onClick={onClick}
            className={`button ${className}`}
            disabled={disabled}
            theme={buttonTheme}
        >
            {label}
        </StyledButton>
    );
};

export default Button;
