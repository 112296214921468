import Header from "../../components/Header/Header";
import React from "react";
import {useNavigate} from "react-router-dom";


function Terms() {
    const navigate = useNavigate()
    const handleBackClick = () => {
        navigate(-1)
    }
    return (
        <div className="terms_container">
            <div className="content">
                <Header title="개인정보처리방침" handleBack={handleBackClick}/>

                <div className="content_body">
                    <h6 className="head_title terms_text">개인정보처리방침</h6>

                    <article className="terms_article">
                        <div className="terms_desc terms_text">
                            ㈜아이큐비타민은 아이큐비타민 검사 서비스 이용자인 정보 주체의 소중한 개인정보 보호를 위해 「 개인정보 보호법 」 및 관계 법령이 정한 바를 준수하여, 적법하게
                            개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「 개인정보 보호법 」 제30조에 따라 정보 주체에게 개인정보 처리에 관한 절차 및 기준을 안내합니다. 본
                            개인정보처리방침은 검사 관련 서비스뿐만 아니라, 검사 서비스와 직간접적으로 연관이 있는 부가 서비스에도 적용됩니다. 이와 관련한 고충을 신속하고 원활하게 처리할 수
                            있도록 하기 위하여 다음과 같이 개인정보 처리 방침을 수립·공개합니다.
                        </div>
                        <p className="sub_title terms_text">
                            제1조 개인정보의 수집 및 이용 목적
                        </p>
                        <div className="terms_desc terms_text">
                            ㈜아이큐비타민은 다음의 목적을 위하여 개인정보를 수집하고 이용합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
                            「개인정보 보호법」 제18조에 의거하여 별도의 동의를 받는 등 필요한 조치를 이행합니다.
                            <p>회원 가입자 관리<br/>
                                회원 정보 등록, 관리 및 관련 서비스 목적으로 개인정보를 처리합니다. 세부적인 사항으로는개인 식별, 불량 회원의 부정 이용 방지와 비인가 사용 방지, 만
                                14세 미만 아동의 개인 정보 수집 시 법정 대리인의 동의 여부 확인, 불만 처리, 고지사항 전달 등과 관련하여 개인정보를 처리합니다.
                            </p>
                            <p>심리 검사 및 피검사자 정보 관리<br/>
                                심리 검사 진행 및 결과 확인, 심리 검사 결과 관리 및 관련 서비스 목적으로 개인정보를 처리합니다.

                            </p>
                            <p> 마케팅 및 광고 활용<br/> 신규 검사 출시 또는 이벤트 등 정보 전달과 ㈜아이큐비타민의 캠페인 및 교육 프로그램 홍보 등을 포함한 이벤트 홍보를 위하여
                                개인정보를 처리합니다.</p>
                            <p> 기록 보관<br/> 관련 법령에 따른 기록 보관 목적으로 개인정보를 처리합니</p>

                        </div>
                    </article>
                    <article className="terms_article">

                        <p className="sub_title terms_text">
                            제2조 개인정보의 이용 및 보유기간
                        </p>
                        <div className="terms_desc terms_text">
                            <p> ㈜아이큐비타민은 법령에 따른 개인정보 보유·이용 기간 또는 정보 주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용 기간 내에서 개인정보를
                                처리·보유합니다.</p>
                            <p> 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br/> ① 홈페이지 회원 이용 및 관리 법령이 정하는 경우를 제외하고는 회원 탈퇴 시까지 보유 및
                                이용합니다.
                                <br/> ② 심리 검사 결과 회원 서비스 제공을 위해 활용하며, 회원 탈퇴 시까지 보관 및 이용합니다.
                                <br/> 단, 학술 연구 및 콘텐츠 연구 개발을 위한 자료 및 통계 활용 시 개인 정보 중 식별 가능한 인적 정보 삭제 후, 식별 불가능한 일반 정보(성별,
                                생년월일/나이, 가족 형태)에 한하여 활용할 수 있습니다.<br/> ③ 탈퇴한 회원의 정보 회원이 홈페이지를 탈퇴한 이후에도 불량 회원의 부정한 거래 및
                                이용과 불량
                                회원의 이용 재발의 방지, 분쟁 해결 및 수사기관의 요청에 따른 협조 등을 위하여 ㈜아이큐비타민은 이용 계약 해지 일로부터 3년간 회원의 정보를 보유할 수
                                있습니다. 이는 홈페이지 운영 방침에 근거합니다. 고지한 기간이 지나면, 지체 없이 정보를 파기합니다.
                            </p>
                            <p> 다만, 관계 법령 위반에 따른 수사·조사 등의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보관합니다.</p>
                        </div>
                    </article>

                    <article className="terms_article">

                        <p className="sub_title terms_text">
                            제3조 개인정보 처리 항목
                        </p>
                        <div className="terms_desc terms_text">
                            ㈜아이큐비타민은 다음의 개인정보 항목을 처리하고 있습니다.<br/>
                            <p>회원 가입자 관리 <br/>- 필수 항목 <br/>① 14세 미만 아동의 정보(성명, 생년월일/나이, 성별, 가족 및 형제의 구성 형태, 거주 지역) <br/>②
                                법정대리인/양육자의
                                정보(성명, 연락처/전화 번호, 생년월일/나이, 아동과의 관계, 직업 군, 학력, 거주 지역)</p>
                            <p>심리 검사 결과 관리 <br/>- 필수 항목<br/> ① 회원 가입 시 입력한 정보 - 피검사자의 정보(성명, 생년월일/나이, 성별, 가족 구성 형태, 형제
                                유무, 거주 지역)
                                <br/>② 검사 결과 보고서(결과값 포함)</p>
                            <p>기록 보관 <br/>- 필수 항목<br/> ① 회원 정보 및 관리 목록 (ID, 성명, 연락처/전화 번호, 생년월일/나이, 성별, 직업 군, 학력, 거주 지역,
                                가족 및 형제
                                구성 형태)</p>

                        </div>
                    </article>

                    <article className="terms_article">

                        <p className="sub_title terms_text">
                            제3조 개인정보 처리 항목
                        </p>
                        <div className="terms_desc terms_text">
                            ㈜아이큐비타민은 다음의 개인정보 항목을 처리하고 있습니다.<br/>
                            <p>회원 가입자 관리 <br/>- 필수 항목 <br/>① 14세 미만 아동의 정보(성명, 생년월일/나이, 성별, 가족 및 형제의 구성 형태, 거주 지역) <br/>②
                                법정대리인/양육자의
                                정보(성명, 연락처/전화 번호, 생년월일/나이, 아동과의 관계, 직업 군, 학력, 거주 지역)</p>
                            <p>심리 검사 결과 관리 <br/>- 필수 항목<br/> ① 회원 가입 시 입력한 정보 - 피검사자의 정보(성명, 생년월일/나이, 성별, 가족 구성 형태, 형제
                                유무, 거주 지역)
                                <br/>② 검사 결과 보고서(결과값 포함)</p>
                            <p>기록 보관 <br/>- 필수 항목<br/> ① 회원 정보 및 관리 목록 (ID, 성명, 연락처/전화 번호, 생년월일/나이, 성별, 직업 군, 학력, 거주 지역,
                                가족 및 형제
                                구성 형태)</p>

                        </div>
                    </article>


                    <article className="terms_article">

                        <p className="sub_title terms_text">
                            제4조 만 14세 미만 아동 및 청소년의 개인정보 처리에 관한 사항
                        </p>
                        <div className="terms_desc terms_text">
                            <p>㈜아이큐비타민은 만 14세 미만 아동 및 청소년에 대한 개인정보를 수집할 시, 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를
                                수집합니다. <br/>- 필수 항목 <br/>① 아이의 성명, 생년월일/나이, 성별, 가족 및 형제 구성 형태, 거주 지역<br/> ② 법정 대리인/양육자의
                                성명, 연락처/휴대폰 번호,
                                성별, 아동과의 관계, 생년월일/나이, 직업 군, 학력, 거주 지역</p>
                            <p>또한, 신규 검사 홍보, 검사 통계 자료 생성, 기타 IR 자료를 위한 일련의 작업 등 ㈜아이큐비타민의 홍보 마케팅을 위해 아동의 개인정보를 수집할 경우,
                                법정대리인으로부터 별도의 동의를 얻습니다.</p>
                            <p>㈜아이큐비타민은 만 14세 미만 아동의 개인정보를 수집할 때에는 아동에게 법정대리인의 성명 등 최소한의 정보를 요구할 수 있으며, 다음의 방법으로 적법한
                                법정대리인이 동의하였는지를 확인합니다.<br/> - 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 개인정보처리자가 그 동의
                                표시를
                                확인했음을 법정대리인의 전자 우편으로 알리는 방법 <br/>- 그 밖에 법적 기준에 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를
                                확인하는
                                방법</p>


                        </div>
                    </article>

                    <article className="terms_article">

                        <p className="sub_title terms_text">
                            제5조 개인정보의 파기 절차 및 방법
                        </p>
                        <div className="terms_desc terms_text">
                            <p> ㈜아이큐비타민은 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</p>
                            <p> 정보 주체로부터 동의를 받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성되었음에도 불구하고, 별도의 기타 법령에 따라 개인정보를 계속 보존하여야 하는
                                경우, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소 및 방법을 달리하여 보존합니다.</p>
                            <p> 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/> ① 파기 절차 <br/>㈜아이큐비타민은 파기 사유가 발생한 개인정보를 선정하고, ㈜아이큐비타민의
                                개인정보
                                보호
                                책임자의 승인을 받아 개인정보를 파기합니다. 회원님이 회원 가입 및 서비스 이용 등을 위해 입력하신 정보는 목적이 달성된 후, 별도의 DB로 옮겨져 내부 방침
                                및 기타 관련 법령에 의한 정보 보호 사유에 따라 일정 기간 저장됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고, 보유 이외의 다른
                                목적으로 이용되지 않습니다. 회원이 홈페이지를 탈퇴하는 경우, 해당 회원의 정보는 법률에 의한 문제를 대비하여 일정 기간 저장된 후, DB에서
                                파기됩니다.<br/> ②
                                파기 방법<br/> ㈜아이큐비타민은 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없는 기술적인 방법을 사용하여 파기하며, 종이 문서에
                                기록·저장된
                                개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
                            <p> 인터넷을 활용하여 서비스를 제공하는 경우, 전자상거래 등에서 정한 규정에 따라 관련 정보를 보유할 수 있습니다.</p>
                        </div>

                        <div className="table_container">
                            <table className="terms_table">
                                <colgroup>
                                    <col style={{width: "40%"}}/>
                                    <col style={{width: "40%"}}/>
                                    <col style={{width: "40%", minWidth: '71px'}}/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <td>관련 법령</td>
                                    <td>내용</td>
                                    <td>보존 기간</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>통신비밀보호법</td>
                                    <td>서비스 이용에 관한 로그 기록
                                    </td>
                                    <td>3개월</td>
                                </tr>
                                <tr>
                                    <td rowSpan={4}>전자상 거래 등에서의 소비자 보호에 관한 법률</td>
                                    <td>표시, 광고에 관한 기록</td>
                                    <td>6개월</td>
                                </tr>
                                <tr>
                                    <td>계약 철회 등에 관한 기록</td>
                                    <td>5년</td>
                                </tr>
                                <tr>
                                    <td>대금 결제 및 재화 등의 공급에 관한 기록</td>
                                    <td>5년</td>
                                </tr>
                                <tr className="border_none">
                                    <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                                    <td>3년</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제6조 장기 미 접속 회원의 계정 분리 보관 및 관리
                        </p>
                        <div className="terms_desc terms_text">
                            <p> ㈜아이큐비타민은 최근 1년 또는 이용자가 기간 이상 서비스 이용 기록이 없는 계정을 “장기 미 접속 계정” 및 “휴면 계정”으로 전환하고, 해당 계정에 포함된
                                회원의 개인정보를 별도로 분리하여 저장 및 보관 관리합니다.</p>
                            <p> ㈜아이큐비타민은 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등
                                회원에게 통지 가능한 방법으로 알릴 수 있습니다.</p>
                            <p> 장기 미 접속으로 인해 휴면 계정 전환을 원하지 않으시는 경우, 휴면 계정 전환 전 서비스 로그인을 통해 휴면 계정을 비활성화할 수 있습니다. 또한, 휴면
                                계정으로 전환된 이후에도 서비스 이용을 위한 회원 동의와 로그인을 통해 휴면 계정 복원 및 정상적인 서비스를 이용할 수 있습니다.</p>
                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제7조 정보 주체와 법정 대리인의 권리·의무와 행사 방법
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 정보 주체는 ㈜아이큐비타민에 대해 언제든지 개인정보 열람·정정·삭제·처리 및 정지 요구 등의 권리를 행사할 수 있습니다. ※ 만 14세 미만 아동에 관한
                                개인정보의 열람 등 요구는 법정 대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보 주체는 정보 주체의 개인정보에 관하여 미성년자 본인이 권리를
                                행사하거나 법정 대리인을 통하여 권리를 행사할 수도 있습니다.</p>
                            <p> 권리 행사는 ㈜아이큐비타민에 대해 「 개인정보 보호법 」 시행령 제41조 제1항에 따라서 서면, 이메일, 모사전송(FAX) 등을 통하여 할 수 있으며,
                                ㈜아이큐비타민은 이에 대해 확인 절차 이후 지체 없이 조치합니다.</p>
                            <p> 권리 행사는 정보 주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있으며, 이 경우 「 개인정보 처리 방법에 관한 고시(제2020-7호) 」
                                별지 제11호 서식에 따른 위임장을 제출하여야 합니다.</p>
                            <p> 개인정보 열람 및 처리 정지 요구는 「 개인정보 보호법 」 제35조 제4항, 제37조 제2항에 의하여 정보 주체의 권리가 제한될 수 있습니다.</p>
                            <p> 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
                            <p> ㈜아이큐비타민은 정보 주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시, 열람 등 요구를 한 자가 본인이거나 정당한 대리인의 여부를
                                확인합니다.</p>

                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제8조 개인정보의 안전성 확보 조치
                        </p>
                        <div className="terms_desc terms_text">
                            <p>㈜아이큐비타민은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                                <br/>①관리적 조치: 내부 관리 계획 수립·시행, 전담 위원회 운영, 정기적 직원 교육
                                <br/>②기술적 조치: 개인정보 처리 시스템 등의 접근 권한 관리, 접근 통제 시스템 설치, 개인정보의 암호화, 검사 결과 점수 DB에 대한 보안 및 관리
                                <br/>③물리적 조치: 자료 보관실 등의 접근 통제</p> ㈜아이큐비타민은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                            <p>㈜아이큐비타민은 개인정보의 안전성을 확보하기 위하여 법령에서 규정하고 있는 사항 이외에도 개인정보 보호 관련 공동 규제 및 자율 규약 참여, 자율 규제
                                단체 활동을 시행하고 있습니다.</p>
                            <p> ㈜아이큐비타민의 개인정보 안전성 확보 조치는 「 개인정보 보호법 」 제29조(안전조치 의무), 제39조의5(개인정보의 보호 조치에 대한 특례) 「
                                개인정보의 안전성 확보 조치 기준 」 (고시 제2020-2호), 「 개인정보의 기술적·관리적 보호 조치 기준 」 (고시 제2020-5호), 「 개인정보의
                                암호화 조치 안내서 」 (2020.12.) 등 관련 법령에 의거합니다.</p>
                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제9조 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항
                        </p>
                        <div className="terms_desc terms_text">
                            <p>㈜아이큐비타민은 회원에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고, 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 이는 「온라인 맞춤형
                                광고 개인정보 보호 가이드라인(방송통신위원회, 2017.2.)」 에 의거합니다.</p>
                            <p> 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 회원의 컴퓨터 브라우저에게 보내는 소량의 정보이며, 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도
                                합니다. <br/>① 쿠키의 사용 목적: 회원이 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안접속 여부 등을 파악하여
                                회원에게
                                최적화된 정보 제공을 위해 사용됩니다. <br/>② 쿠키의 설치·운영 및 거부: 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키
                                저장을
                                거부할 수 있습니다.
                                <br/>- Microsoft Edge: 설정 > 쿠키 및 사이트 권한
                                <br/>- Chrome: 설정 > 개인정보 및 보안 > 쿠키 및 기타 사이트 데이터
                                <br/>그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.<br/> ③ 쿠키 저장을 거부할 경우, 회원 별 맞춤형 서비스 이용에 어려움이 발생할 수
                                있습니다.
                                 쿠키는 이용자의 컴퓨터는 식별하지만 귀하(회원)를 개인적으로 식별하지는 않습니다. 쿠키는 브라우저의 종료 시 또는 로그아웃 시 만료됩니다.

                            </p><p>

                            Google Analytics<br/>
                            Google Analytics 사용 목적 및 이용약관: 회사는 고객에게 더 나은 서비스를 제공하기 위한 목적으로 Google이 제공하는 웹 분석
                            서비스인<br/>
                            Google Analytics를 사용하여 고객들이 회사의 서비스를 어떻게 이용하는지 분석 및 평가하고 고객의 수요를 파악하며, 서비스와 제품을 개선하고
                            효율적인 서비스를 제공하는 것에 목적이 있습니다.<br/>
                            Google Analytics를 통해 수집되는 정보의 처리는 Google 개인정보보호정책과 Google Analytics 이용약관을 적용 받습니다.<br/>
                            - Google 개인정보보호정책: https://www.google.com/intl/ko/policies/privacy/<br/>
                            - Google Analytics 이용약관: https://www.google.com/analytics/terms/kt.html<br/>
                            회사는 Google Analytics를 통해 개인을 식별할 수 있는 정보는 수집하지 않으며, 수집한 정보를 다른 경로를 통해 얻은 개인 식별 정보와
                            결합하지
                            않습니다.<br/>
                            Google Analytics 거부 방법: 이용자는 Google Analytics에 의해 데이터가 수집되는 것을 거부할 수 있습니다.<br/>
                            - Google Analytics 차단: https://tools.google.com/dlpage/gaoptout
                        </p>

                        </div>
                    </article>


                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제10조 개인정보 보호 책임자 및 담당부서
                        </p>
                        <div className="terms_desc terms_text">
                            <p> ㈜아이큐비타민은 정보 주체의 개인정보 자기 결정권을 보장하고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및 피해 구제 등을 위하여 노력하고 있으며,
                                아래와 같이 개인정보 보호 책임자를 지정하고 있습니다. 신고나 상담이 필요한 경우 아래의 담당 부서로 연락해 주시기 바랍니다.<br/>
                                개인정보 보호 책임자 <br/>- 성명: 김진아<br/> - 직책: 대표 <br/>- 연락처 : 02-6959-2377, dvax0102@naver.com,
                                050-4023-2096(FAX)<br/> ※ 개인정보 보호 담당 부서로 연결됩니다.</p>
                            <p> 정보 주체는 ㈜아이큐비타민의 서비스(또는 사업)를 이용하는 동안 발생하는 모든 개인정보 보호 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을 개인정보
                                보호 책임자 및 담당 부서로 문의할 수 있습니다. 이에, ㈜아이큐비타민은 정보 주체의 문의에 대해 지체 없이 처리 가능한 범위 내에서 해결하고자
                                합니다.</p>
                            <p> 정보 주체는 「 개인정보 보호법 」 제35조에 따른 개인정보의 열람 청구를 동일한 부서 및 책임자에게 할 수 있습니다. ㈜아이큐비타민은 정보 주체의 개인정보
                                열람 청구가 신속하게 이루어질 수 있도록 업무를 처리합니다.</p>

                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제11조 권익 침해 구제 방법
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 정보 주체는 개인정보 침해로 인한 구제를 받기 위하여 개인정보 분쟁 조정 위원회, 한국 인터넷 진흥원 개인정보 침해 신고 센터 등에 분쟁 해결이나 상담 등을
                                신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br/> ① 개인정보 분쟁 조정 위원회 :
                                (국번 없이)
                                1833-6972 (www.kopico.go.kr)<br/> ② 개인정보 침해 신고 센터 : (국번 없이) 118
                                (privacy.kisa.or.kr)<br/> ③
                                대검찰청 :
                                (국번 없이) 1301 (www.spo.go.kr)<br/> ④ 경찰청 : (국번 없이) 182 (ecrm.cyber.go.kr)</p>
                            <p> 「 개인정보 보호법 」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리 정지 등)의 규정에 의한 요구에 대하여
                                공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. -
                                중앙행정심판위원회: (국번 없이) 110 (www.simpan.go.kr)</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제12조 개인정보 처리 방침의 변경
                        </p>
                        <div className="terms_desc terms_text">
                            법령 및 정책 또는 보안 기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행일의 최소 7일 전부터 공식 홈페이지 공지사항을 통하여
                            고지합니다. 다만, 회원의 관리 또는 의무에 대한 중요한 내용의 변경 또는 개인정보 처리 방침의 변경이 발생할 시 최소 발생 30일전에 고지하겠습니다.


                        </div>
                    </article>
                    <article className="terms_article">
                        개인정보 처리방침 변경 공고 일자: 2024년 11월 6일
                        개인정보 처리방침 시행 일자: 2024년 11월 13일
                    </article>

                </div>
            </div>
        </div>

)
}

export default Terms;