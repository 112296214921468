import React from 'react';

const OutPop = ({ isOpen, onClose, onYesClick }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='modal-wrapper'> 
                    <div className="modal-title">정말 나가시겠습니까?</div>
                    <div className="modal-message">검사 내용은 임시 저장됩니다.</div>
                </div>
                <div className="modal-buttons">
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>아니요</button>
                    </div>
                    <div className="modal-button-wrapper">
                        <button className="modal-button-yes" onClick={() => { onYesClick(); onClose(); }}>네</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default OutPop;
