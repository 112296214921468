import React, { useState } from "react";
import { Data, Data1 } from "../../../data/mock/question";
import Button from "../../../components/Button/Button";
import Test_Img from "../../../assets/img/test_img2.png"; 
import { useNavigate } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";
import QuestionList from '../../../components/QuestionTypes/QuestionComponent';

const SynthesisTestDetail = () => { 
    const navigate = useNavigate();
    const [tab, setTab] = useState('tab1');
    const [numberCheck, setNumberCheck] = useState({
        tab1: {},
        tab2: {},
        tab3: {},
        tab4: {},
        tab5: {},
        tab6: {},
    });
    const [showImage, setShowImage] = useState(false);
    const [showQuestions, setShowQuestions] = useState(true);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);

    const handleNumberCheck = (questionIndex, selectedCheck, currentTab) => {
        console.log('Selected:', questionIndex, selectedCheck, currentTab);
        setNumberCheck(prev => {
            const newState = {
                ...prev,
                [tab]: {
                    ...prev[tab],
                    [questionIndex]: selectedCheck
                }
            };
            console.log('New state:', newState);
            return newState;
        });
    };

    const allAnswered = (tabAnswers) => {
        if (!tabAnswers) return false;
        
        const currentQuestions = tab === 'tab1' ? Data.question : Data1.question1;
        const answeredCount = Object.keys(tabAnswers).length;
        console.log('Answered count:', answeredCount, 'Total questions:', currentQuestions.length);
        
        return answeredCount === currentQuestions.length;
    };

    const handleButtonClick = () => {
        if (showQuestions) {
            if (allAnswered(numberCheck[tab])) {
                if (tab === 'tab6') {
                    navigate('/synthesiscomplete');
                } else {
                    setShowQuestions(false);
                    setShowImage(true);
                }
            }
        } else {
            setShowImage(false);
            setTab(tab === 'tab6' ? 'tab1' : `tab${parseInt(tab.replace('tab', '')) + 1}`);
            setShowQuestions(true);
        }
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleBackClick = () => {
        setIsOutPopOpen(true);
    };

    return (
        <div className="generalTestDetail">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div style={{ display: "flex", gap: "4px" }}>
                        {[...Array(6)].map((_, index) => (
                            <div
                                key={`tab${index + 1}`}
                                className={`tab ${tab === `tab${index + 1}` ? "activeTab" : ""}`}
                                onClick={() => setTab(`tab${index + 1}`)}
                            />
                        ))}
                    </div>

                    <div className="tabContent">
                        <div>
                            {showQuestions && (
                                <QuestionList
                                    questions={tab === 'tab1' ? Data.question : Data1.question1}
                                    selectedCheck={numberCheck[tab]}
                                    onNumberCheck={(index, check) => handleNumberCheck(index, check, tab)}
                                    questionType={tab === 'tab1' ? 'fivePoint' : 'binary'}
                                />
                            )}
                            {showImage && (
                                <div className="image-overlay">
                                    <img className="Test_Img" src={Test_Img} alt="설명" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button 
                        label="다음" 
                        variant="btn_next" 
                        className={showImage || (showQuestions && allAnswered(numberCheck[tab])) ? "active" : "disabled"} 
                        onClick={handleButtonClick} 
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick} 
                />
            </div>
        </div>
    );
};

export default SynthesisTestDetail;
