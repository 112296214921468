import React, { useState } from "react";
import Complete_Img from "../../../assets/img/complete_img2.png";
import Ellipse from "../../../assets/img/Ellipse 100.png";
import Button from "../../../components/Button/Button";
import OutPop from "../../../components/Popup/OutPop";
import ReportPop from "../../../components/Popup/ReportPop";
import RetestPop from "../../../components/Popup/RetestPop";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";

function SynthesisComplete() {
    const navigate = useNavigate();
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [isReportPopOpen, setIsReportPopOpen] = useState(false);
    const [isRetestPopOpen, setIsRetestPopOpen] = useState(false);

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기 
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleRoportClick = () => {
        navigate("/my-list?type=report")
    }



    const handleReportClick = () => {
        setIsReportPopOpen(true); // ReportPop 열기
    };

    const handleRetestClick = () => {
        setIsRetestPopOpen(true); // RetestPop 열기
    };

    return (
        <div className="TestComplete">
            <div className="content">
            <Header title="종합 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="intro_section">
                        <div className="intro_title_assessment">종합</div>
                        <div className="completion_message">사회성 검사를<br/>완료했어요</div>
                        <div className="result_message">리포트 결과를 확인해 보세요.</div>
                    </div>
                    <div className="Complete_image_section">
                        <img className="Complete_Img" src={Complete_Img} alt="Complete" />
                    </div>
                    <div className="refund_info_container">
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text">리포트 확인 전에는 무제한 재검사가 가능합니다.</div>
                        </div>
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text">리포트 확인 후에는 재검사가 불가합니다.</div>
                        </div>
                    </div>
                </div>
                <div className="button_section">
                    <Button  
                        label="재검사하기" 
                        variant="btn_join" 
                        onClick={handleRetestClick} // 재검사하기 클릭 시 RetestPop 열기
                    />
                    <Button 
                        label="리포트 보기" 
                        variant="btn_complete" 
                        onClick={handleReportClick} // 리포트 보기 클릭 시 ReportPop 열기
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick} 
                />
                <ReportPop
                    isOpen={isReportPopOpen}
                    onClose={() => setIsReportPopOpen(false)}
                    onYesClick={handleRoportClick} 
                />
                <RetestPop
                    isOpen={isRetestPopOpen}
                    onClose={() => setIsRetestPopOpen(false)}
                    onYesClick={handleModalYesClick} 
                />
            </div>
        </div>
    );
}

export default SynthesisComplete;
