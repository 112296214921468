import React, {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {getNoticeDetail} from "../../services/Board/board";
import dompurify from "dompurify";

function NoticeDetail() {
    const {b_seq} = useParams();

    const [noticeDetail, setNoticeDetail] = useState({});
    const getDownloadUrl = async file => {
        fetch(file, {
            method: 'GET',
        })
            .then(res => {
                return res.blob();
            })
            .then(blob => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(blob);
                fileReader.onload = data => {
                    const dataUrl = data.target.result;
                    const a = document.createElement('a');
                    a.href = dataUrl
                    const filename = file.split('/').pop();
                    a.download = filename;

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
            })
            .catch(e => {
                console.error(e);
            });
    };

    const getNoticeDetailData =useCallback( async () => {
        try {
            const response = await getNoticeDetail({b_seq})
            if(response.data.code === 200){
                setNoticeDetail(response.data.data)
            }
        } catch (e) {
            console.error(e)

        }
    }, [b_seq]);
    useEffect(() => {
        getNoticeDetailData();
    }, [getNoticeDetailData]);
    if (!noticeDetail.data)return
        return (

        <div className="content_body">

            <div className="board_detail">

                <div className="board_title_box">

                    <h6 className="board_title notice" dangerouslySetInnerHTML={{__html: dompurify.sanitize(noticeDetail.data.subject)}}></h6>
                    <span className="board_date">{dayjs(noticeDetail.data.regist_date).format('YYYY.MM.DD')}</span>
                </div>
                <div className="board_content" dangerouslySetInnerHTML={{__html: dompurify.sanitize(noticeDetail.data.contents)}}>

                </div>
                <div className="board_bottom">
                    {noticeDetail.files.map((file, idx) => (
                        <button
                            className="board_file_btn left_icon"
                            type="button"
                            onClick={() => getDownloadUrl(file.new_filepath)}
                        > {file.org_filename} </button>
                    ))}
                </div>

            </div>

        </div>
    );
}

export default NoticeDetail;
