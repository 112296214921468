import { ERROR_MESSAGE } from "./constants";

// 닉네임 유효성 검사
export const validateNickname = (nickname) => {
    if (nickname.length < 2 || nickname.length > 10) {
        return ["nickname", ERROR_MESSAGE.NICKNAME_EDIT];
    }
    // 여기에 추가적인 유효성 검사 로직 (예: 중복 검사)도 추가 가능
    return ["success", ERROR_MESSAGE.NICKNAME_EXISTENCE];
};

//숫자 한글 체크 , 천단위 콤마
export const formatLocalString = value => {
    // 입력값이 문자열이고, 숫자로만 구성되어 있다면 숫자로 변환
    if (typeof value === 'string' && !isNaN(value)) {
        value = Number(value);
    }

    // 입력값이 숫자인 경우, toLocaleString을 사용하여 현지화된 문자열로 변환
    if (typeof value === 'number') {
        return value.toLocaleString();
    }

    // 변환할 수 없는 값의 경우 오류 메시지 반환
    throw new Error('Input must be a number or a numeric string.');
};

// 숫자만
export const numericRegex = value => {
    const regex = /[^0-9]/g;
    return value.replace(regex, '');
};

export const validateInput = (input, isChar = false) => {
    const regExp = /[{}[\]?.,;:|)*~`!^_+<>@#$%&=('""]/g;

    const hasSpecialCharacter = regExp.test(input);
    const hasNumber = /\d/.test(input);
    const hasLetter = /[A-Za-z]/.test(input);
    if (isChar) {
        // 특수문자, 숫자, 영문자 중 하나라도 없으면 false 반환
        if (!hasSpecialCharacter || !hasNumber || !hasLetter) {
            return false;
        }
    } else {
        if (hasSpecialCharacter || !hasNumber || !hasLetter) {
            return false;
        }
    }
    return true;
};


export const emailRegex = value => {
    // 이메일 주소를 검사하는 정규식
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // .test() 메서드를 사용하여 주어진 value가 정규식과 일치하는지 검사
    return pattern.test(value);
};