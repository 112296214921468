import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionList from '../../components/QuestionTypes/QuestionComponent';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import instance from '../../services/services';
import { INSPECTION_ANSWER_URL } from '../../utils/urls';

function AfterVideoQuestions() {
    const { state } = useLocation();
    const navigate = useNavigate();

    console.log('전체 state:', state);
    
    const getQuestionType = (answerType) => {
        if (answerType === "2" || answerType === 2) return "binary";
        if (answerType === "3" || answerType === 3) return "threePoint";
        return "fivePoint";
    };

    const [answers, setAnswers] = useState({});

    // 모든 문항이 풀렸는지 확인하는 함수
    const isAllQuestionsAnswered = () => {
        const totalQuestions = state.after_questions.after_questions.length;
        const answeredQuestions = Object.keys(answers).length;
        return totalQuestions === answeredQuestions;
    };

    const handleNumberCheck = (questionIndex, selectedCheck) => {
        setAnswers(prev => ({
            ...prev,
            [questionIndex]: selectedCheck
        }));
        console.log('현재 선택된 답변들:', answers);
    };

    const handleSubmit = async () => {
        try {
            const formattedAnswers = Object.entries(answers).map(([qst_seq, value]) => {
                const question = state.after_questions.after_questions.find(q => q.qst_seq === qst_seq);
                
                return {
                    qst_seq: qst_seq,
                    answer_type: question.answer_type,
                    choice_val: value,
                    short_answer: ""
                };
            });

            // 비디오 답변과 사후 문항 답변 합치기
            const allAnswers = [...(state.video_answers || []), ...formattedAnswers];

            const requestBody = {
                order_seq: state.Data.orderId,
                item_option_seq: state.Data.item_option_seq,
                goods_seq: state.Data.goods_seq,
                age_txt: state.Data.age,
                gender: state.Data.gender,
                test_user_name: state.Data.name,
                answers: allAnswers
            };

            console.log('제출할 데이터:', requestBody);

            const response = await instance.post(INSPECTION_ANSWER_URL, requestBody);
            console.log('응답:', response.data);

            if (response.data.success) {
                navigate('/storycomplete', {
                    state: {
                        goods_seq: state.Data.goods_seq,
                        order_seq: state.Data.orderId,
                        item_option_seq: state.Data.item_option_seq
                    }
                });
            } else {
                alert('답변 제출에 실패했습니다. 다시 시도해주세요.');
            }

        } catch (error) {
            console.error('답변 제출 중 오류:', error);
            alert('답변 제출 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className="afterVideoQuestions">
            <div className="content">
                <Header 
                    title={state.Data.goods_name} 
                    handleBack={() => navigate(-1)}
                />
                
                <div className="content_body">
                    {state.after_questions.after_questions.map((question, index) => {
                        const questionData = {
                            count: "Q-" + (index + 1),
                            content: question.qst_content,
                            title: index === 0 ? state.after_questions.after_questions_title : null,
                            qst_seq: question.qst_seq
                        };

                        return (
                            <QuestionList
                                key={question.qst_seq}
                                questions={[questionData]}
                                selectedCheck={answers[question.qst_seq]}
                                onNumberCheck={(_, value) => handleNumberCheck(question.qst_seq, value)}
                                questionType={getQuestionType(question.answer_type)}
                            />
                        );
                    })}
                </div>

                <div className="Edit_btn_complete">
                    <Button
                        label="다음"
                        className={isAllQuestionsAnswered() ? "active" : "disabled"}
                        variant="btn_change"
                        onClick={handleSubmit}
                        disabled={!isAllQuestionsAnswered()}
                    />
                </div>
            </div>
        </div>
    );
}

export default AfterVideoQuestions;