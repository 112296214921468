import React from 'react';
import { useNavigate } from 'react-router-dom';

const ShoppingPop = ({ isOpen, onClose }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    const hadleNextClick = () => {
        navigate('/shoppingcart');
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='modal-wrapper'> 
                    <div className="modal-title">장바구니로 이동하시겠습니까?</div>
                    <div className="modal-message">동화를 장바구니에 담았습니다.</div>
                </div>
                <div className="modal-buttons">
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>아니요</button>
                    </div>
                    <div className="modal-button-wrapper" onClick={hadleNextClick}>
                        <button className="modal-button-yes">바로가기</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default ShoppingPop;
