import React from "react";
import Children_Img from "../../../assets/img/dummy_2.png";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Badge from "../../../components/Badge/Badge";
import Header from "../../../components/Header/Header";
import { formatLocalString } from "../../../utils/validation";


function SynthesisPayment() {
    const navigate = useNavigate();


    const handleBackClick = () => {
        window.history.back();
    }
    
    const hadleNextClick = () => {
        navigate('/childreninformation')
    }

    return (
        <div className="childrenDetail">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick}/>

                <div className="intro_body">
                    <div className="intro_section">
                        <Badge badgeColor="secondary-50" badgeText="종합"/>
                        <div className="intro_age_group">5종 전체</div>
                    </div>
                </div>
                    <div className="line_height"/>

                <div className="assessment_body">
                    <div className="synthesis_section">
                        <div className="synthesis_info">
                            <div className="assessment_details">
                                <div className="assessment_title  ellipsis1">심리 발달 탄력성(어린이)</div>
                                <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                    <div className="assessment_question_count">53 문항</div>
                                </div> 
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={Children_Img} alt="Children_Img" />
                            </div>
                        </div>
                        <div className="text_line"/>
                    </div>
                    
                    <div className="synthesis_section">
                        <div className="synthesis_info">
                            <div className="assessment_details">
                                <div className="assessment_title  ellipsis1 ">심리 발달 탄력성(어린이)</div>
                                <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                    <div className="assessment_question_count">53 문항</div>
                                </div> 
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={Children_Img} alt="Children_Img" />
                            </div>
                        </div> 
                        <div className="text_line"/>
                    </div>

                    <div className="synthesis_section">
                        <div className="synthesis_info">
                            <div className="assessment_details">
                                <div className="assessment_title  ellipsis1">심리 발달 탄력성(어린이)</div>
                                <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                    <div className="assessment_question_count">53 문항</div>
                                </div> 
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={Children_Img} alt="Children_Img" />
                            </div>
                        </div> 
                        <div className="text_line"/>
                    </div>

                    <div className="synthesis_section">
                        <div className="synthesis_info">
                            <div className="assessment_details">
                                <div className="assessment_title  ellipsis1">심리 발달 탄력성(어린이)</div>
                                <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                    <div className="assessment_question_count">53 문항</div>
                                </div> 
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={Children_Img} alt="Children_Img" />
                            </div>
                        </div> 
                        <div className="text_line"/>
                    </div>

                    <div className="synthesis_section">
                        <div className="synthesis_info">
                            <div className="assessment_details">
                                <div className="assessment_title  ellipsis1">심리 발달 탄력성(어린이)</div>
                                <div className="assessment_meta" style={{paddingTop:"6px"}}>
                                    <div className="assessment_question_count">53 문항</div>
                                </div> 
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={Children_Img} alt="Children_Img" />
                            </div>
                        </div> 
                        <div className="text_line"/>
                    </div>

                    


                    <div className="price_info">
                        <div className="price_label">가격정보</div>
                        <div className="price_value">{5000 ? `${formatLocalString(5000)}원` : '0원'}</div>
                    </div>
                </div>


                <div className="Edit_btn_change">
                    <Button 
                        label="바로 구매하기" 
                        variant="btn_complete" 
                        onClick={hadleNextClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default SynthesisPayment;
