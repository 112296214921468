import {BottomSheet} from 'react-spring-bottom-sheet'

import 'react-spring-bottom-sheet/dist/style.css'

const ListBottomSheet = ({title = '문의 유형', isOpen, listData = [], handledSelectedItem,onClose}) => {


    return (
        <>
            <BottomSheet open={isOpen}
                         onDismiss={onClose} // 배경 클릭 시 닫기
                         snapPoints={({ maxHeight }) => [0.5 * maxHeight, maxHeight]}
            >
                <div className="sheet_list">
                    {title && (<h6 className="sheet_title">{title}</h6>)}

                    <ul>
                        {listData.map((list, idx) =>
                            (
                                <li key={idx} className="sheet_item" onClick={() => handledSelectedItem(list)}>
                                    {list.name}
                                </li>
                            ))}

                    </ul>
                </div>
            </BottomSheet>
        </>
    )
}


export default ListBottomSheet