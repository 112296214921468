import DefaultPopup from "./DefaultPopup";
import Button from "../Button/Button";
import React, {useEffect, useState} from "react";


const InquiryPopup = ({title, isOpen, close, openSelectSheet, selectedType = null, submitInquiryForm}) => {


    const [formData, setFormData] = useState({
        subject: "",
        contents: '',
        board_type: selectedType?.value || '',

    });

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            board_type: selectedType?.value || "",
        }));
    }, [selectedType]);


    const isValidNull = formData.subject && formData.contents && formData.board_type
    const handleInquiryForm = (event, type) => {
        setFormData((prevState) => ({
            ...prevState,
            [type]: event.target.value,
        }))

    }

    useEffect(() => {
        if (!isOpen) {
            setFormData({
                subject: "",
                contents: '',
                board_type: selectedType?.value || '',
            });

        }
    }, [isOpen, selectedType, setFormData, close]);
    if (!isOpen) return null;
    return (
        <>

            <DefaultPopup handleBack={close} title={title}>

                <div className="form_group">

                    <div className="default_input border">
                        <span className="label">문의 유형</span>
                        <button onClick={openSelectSheet} type="button"
                                className={`form_input right_icon right_arrow_btn ${selectedType?.value ? 'active' : ''}`}>
                            {selectedType?.name ? selectedType.name : '문의유형을 선택해주세요.'}
                        </button>
                    </div>
                    <div className="default_input border">
                        <div className="label comb_label">
                            <label htmlFor="subject" className="">제목</label>
                            <span className="count_text">{formData.subject.length}/50</span>
                        </div>
                        <input
                            maxLength="50"
                            value={formData.subject}
                            onChange={(event) => handleInquiryForm(event, 'subject')}
                            id="subject"
                            type="text"
                            placeholder="제목을 입력해 주세요."/>
                    </div>

                    <div className="default_input border">
                        <div className="label comb_label">
                            <label htmlFor="contents" className="">내용 </label>
                            <span className="count_text">{formData.contents.length}/500</span>
                        </div>
                        <textarea
                            maxLength="500"
                            value={formData.contents}
                            onChange={(event) => handleInquiryForm(event, 'contents')}
                            style={{height: '224px'}} id="contents" placeholder=""/>
                    </div>

                </div>
                <Button
                    onClick={() => submitInquiryForm(formData)}
                    label="등록"
                    disabled={!isValidNull}
                    className={isValidNull ? 'active' : 'disabled'}
                    variant="btn_change"
                />
            </DefaultPopup>

        </>
    )
}


export default InquiryPopup