import React, { useEffect, useState, useRef } from "react";
import Player from '@vimeo/player';
import { useNavigate } from "react-router-dom";
import { instance } from "../../services/services";
import { FREE_TEST_URL } from "../../utils/urls";
import RePortImg from "../../assets/img/test_result.PNG";
import ButtonTab from "../../components/Tab/ButtonTab";
import closeImg from '../../assets/img/ic_Close_1.png';
import FreeClosePopup from "../../components/Popup/FreeClosePopup";

function FreeVideo() {
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState(null);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [isReportVisible, setIsReportVisible] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleBackClick = (e) => {
        e.stopPropagation();
        setIsPopupOpen(true);
    }

    const handleEndExperienceClick = () => {
        setIsPopupOpen(true);
    }

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    }

    const handlePopupYesClick = () => {
        setIsPopupOpen(false);
        navigate('/');
    }

    useEffect(() => {
        const fetchFreeTestData = async () => {
            try {
                const response = await instance.post(FREE_TEST_URL);
                
                if (response.data.code === 200) {
                    setVideoData(response.data.data);
                } else {
                    console.error('무료 체험 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('무료 체험 데이터 조회 오류:', error);
            }
        };

        fetchFreeTestData();
    }, []);

    useEffect(() => {
        if (videoRef.current && videoData) {
            // Vimeo Player 인스턴스 생성
            playerRef.current = new Player(videoRef.current);

            // 시간 체크를 위한 이벤트 리스너
            playerRef.current.on('timeupdate', ({ seconds }) => {
                const endTime = videoData.ending_time.split(':').reduce((acc, time) => (60 * acc) + +time, 0);
                
                if (seconds >= endTime) {
                    playerRef.current.pause();
                    setIsVideoEnded(true);
                }
            });

            // 컴포넌트 언마운트 시 정리
            return () => {
                if (playerRef.current) {
                    playerRef.current.off('timeupdate');
                    playerRef.current.destroy();
                }
            };
        }
    }, [videoData, navigate]);

    const getVideoUrl = (url) => {
        if (!url) return '';
        const videoId = url.match(/\d+$/)?.[0];
        return videoId ? `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0` : '';
    }

    const handleReportClick = () => {
        setIsReportVisible(true);
        setIsVideoEnded(true);
    }

    return (
        <div style={{ position: 'relative', zIndex: 100 }}>
            <div className="video_header">
                <div className="video_title">
                    {videoData ? videoData.goods_name : "무료 체험"}
                </div>
                <div className="video_close">
                    <img src={closeImg} alt="closeImg" className="icon closeImg" onClick={handleBackClick}/>
                </div>
            </div>
            <div className="promo-banner">
                {isReportVisible ? (
                    <>
                        <div className="promo-title">리포트 결과 확인</div>
                        <div className="promo-description">
                            동화 검사 결과 리포트 샘플입니다
                        </div>
                    </>
                ) : (
                    <>
                        <div className="promo-title">동화 무료 체험하기</div>
                        <div className="promo-description">
                            동화 검사 진행 과정을 체험해 보시고,<br/> 
                            검사에 따른 리포트 샘플을 확인해 보세요
                        </div>
                    </>
                )}
            </div>
            {isVideoEnded ? (
                <>
                    {isReportVisible ? (
                        <div style={{ position: 'relative', padding: '16px 24px 80px 24px' }}>
                            <img src={RePortImg} alt="결과지" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    ) : null}
                </>
            ) : (
                videoData ? (
                    <iframe
                        ref={videoRef}
                        src={getVideoUrl(videoData.video_url)}
                        width="100%"
                        height="400"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title="Vimeo Video"
                    />
                ) : (
                    <p>비디오를 로드하는 중입니다...</p>
                )
            )}
            <ButtonTab
                buttonText={isReportVisible ? "체험 종료하기" : "리포트 샘플 확인하기"}
                onClick={isReportVisible ? handleEndExperienceClick : handleReportClick}
            />
            <FreeClosePopup 
                isOpen={isPopupOpen} 
                onClose={handlePopupClose} 
                onYesClick={handlePopupYesClick} 
            />
        </div>
    );
}

export default FreeVideo;