import React, { useRef, useEffect, useState } from "react";
import Player from '@vimeo/player';
import { instance } from "../../services/services";
import { INSPECTION_ANSWER_URL, INSPECTION_UPDATE_TIME_URL } from "../../utils/urls";
import { renderQuestionOverlay } from '../../components/QuestionTypes/QuestionOverlays';
import { useNavigate } from "react-router-dom";

function Video({ videoData, questionData }) {
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const [activeQuestions, setActiveQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const playerRef = useRef(null);

    const getVideoUrl = (url) => {
        const videoId = url.match(/\d+$/)[0];
        return `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`;
    }

    const convertTimeToSeconds = (timeStr) => {
        const [minutes, seconds] = timeStr.split(':').map(Number);
        return minutes * 60 + seconds;
    };

    // 비디오 재생 관리
    useEffect(() => {
        const iframe = videoRef.current;
        if (iframe) {
            playerRef.current = new Player(iframe);

            playerRef.current.on('ended', () => {
                console.log('비디오 종료');
                
                if (questionData?.after_questions && questionData.after_questions.length > 0) {
                    const answersArray = Object.entries(answeredQuestions).map(([qst_seq, choice_val]) => ({
                        qst_seq: parseInt(qst_seq),
                        answer_type: "",
                        choice_val: parseInt(choice_val),
                        short_answer: ""
                    }));

                    const uniqueAnswers = Array.from(new Map(
                        answersArray.map(item => [item.qst_seq, item])
                    ).values()).sort((a, b) => a.qst_seq - b.qst_seq);

                    navigate('/aftervideoquestions', {
                        state: {
                            Data: videoData,
                            after_questions: questionData,
                            video_answers: uniqueAnswers,
                            answeredQuestions: answeredQuestions
                        }
                    });
                    console.log('사후 문항 페이지로 이동합니다.');
                } else {
                    console.log('사후 문항이 없습니다. storycomplete 페이지로 이동합니다.');
                    navigate('/storycomplete');
                }
            });
            
            // 비디오 재생 시간 기반으로 문항 활성화 관리
            const checkTime = async () => {
                try {
                    if (!playerRef.current) return;

                    const currentTime = await playerRef.current.getCurrentTime();
                    
                    const unansweredQuestions = questionData.video_questions
                        .filter(question => !answeredQuestions[question.qst_seq])
                        .sort((a, b) => 
                            convertTimeToSeconds(a.opening_time) - convertTimeToSeconds(b.opening_time)
                        );

                    const earliestUnansweredQuestion = unansweredQuestions[0];
                    
                    const isPlaying = await playerRef.current.getPaused().then(paused => !paused);
                    
                    if (earliestUnansweredQuestion && 
                        currentTime > convertTimeToSeconds(earliestUnansweredQuestion.ending_time) &&
                        isPlaying) {
                        
                        await playerRef.current.pause();
                        await new Promise(resolve => setTimeout(resolve, 100));
                        const openingTime = convertTimeToSeconds(earliestUnansweredQuestion.opening_time);
                        await playerRef.current.setCurrentTime(openingTime);
                    }

                    const currentQuestions = questionData.video_questions.filter(question => {
                        const openTime = convertTimeToSeconds(question.opening_time);
                        const endTime = convertTimeToSeconds(question.ending_time);
                        return currentTime >= openTime && currentTime <= endTime;
                    });

                    setActiveQuestions(currentQuestions);
                } catch (error) {
                    console.error('Error getting current time:', error);
                }
            };

            const interval = setInterval(checkTime, 1500);
            return () => clearInterval(interval);
        }
    }, [videoData, questionData, answeredQuestions, navigate]);


    useEffect(() => {
        let lastUpdateTime = 0;
        
        // 시청 시간 업데이트 API 호출
        const updateViewingTime = async (currentTime) => {
            try {
                const response = await instance.post(INSPECTION_UPDATE_TIME_URL, {
                    item_option_seq: videoData.item_option_seq,
                    add_second: 10
                });
                
                if (response.data.code === 200) {
                    console.log('시청 시간 업데이트 성공');
                } else {
                    console.log('시청 시간 업데이트 실패:', response.data.message);
                    if (response.data.message.includes('3회')) {
                        alert(response.data.message);
                        navigate('/');
                    }
                }
            } catch (error) {
                console.error('시청 시간 업데이트 오류:', error);
            }
        };

        // 비디오 재생 시간 기반으로 시청 시간 업데이트
        const checkPlayTime = async () => {
            if (!playerRef.current) return;
            
            try {
                const currentTime = await playerRef.current.getCurrentTime();
                const currentTimeRounded = Math.floor(currentTime);
                
                // 10초 단위로 체크하여 API 호출
                if (currentTimeRounded >= lastUpdateTime + 10) {
                    await updateViewingTime(currentTimeRounded);
                    lastUpdateTime = currentTimeRounded;
                }
            } catch (error) {
                console.error('재생 시간 체크 오류:', error);
            }
        };

        // 1초마다 재생 시간 체크
        const timeInterval = setInterval(checkPlayTime, 1000);

        return () => clearInterval(timeInterval);
    }, [videoData.item_option_seq, navigate]);

    // 문항 클릭 이벤트 처리
    const handleClick = async (questionSeq, answerIndex) => {
        const newAnswerValue = answerIndex + 1;
        const lastQuestionSeq = Math.max(...questionData.video_questions.map(q => parseInt(q.qst_seq)));
        
        setAnsweredQuestions(prev => {
            const newState = {
                ...prev,
                [questionSeq]: newAnswerValue
            };
            console.log('저장된 답변 상태:', newState);
            return newState;
        });

        if (parseInt(questionSeq) === lastQuestionSeq) {
            console.log('마지막 문항 답변 완료');
            
            if (!questionData?.after_questions || questionData.after_questions.length === 0) {
                const answersArray = Object.entries(answeredQuestions).map(([qst_seq, choice_val]) => ({
                    qst_seq: parseInt(qst_seq),
                    answer_type: "",
                    choice_val: parseInt(choice_val),
                    short_answer: ""
                }));

                answersArray.push({
                    qst_seq: parseInt(questionSeq),
                    answer_type: "",
                    choice_val: newAnswerValue,
                    short_answer: ""
                });

                const uniqueAnswers = Array.from(new Map(
                    answersArray.map(item => [item.qst_seq, item])
                ).values()).sort((a, b) => a.qst_seq - b.qst_seq);

                try {
                    const requestBody = {
                        order_seq: videoData.orderId,
                        item_option_seq: videoData.item_option_seq,
                        goods_seq: videoData.goods_seq,
                        age_txt: videoData.age,
                        gender: videoData.gender,
                        test_user_name: videoData.name,
                        answers: uniqueAnswers
                    };

                    console.log('API 요청 데이터:', requestBody);
                    const response = await instance.post(INSPECTION_ANSWER_URL, requestBody);
                    console.log('API 응답:', response.data);
                } catch (error) {
                    console.error('API 오류:', error);
                    alert('답변 제출 중 오류가 발생했습니다.');
                }
            }
        } else {
            // 현재 문항 찾기
            const currentQuestion = questionData.video_questions.find(q => q.qst_seq === questionSeq);
            
            if (currentQuestion) {
                // 현재 문항의 closing_time으로 이동
                const closingTime = convertTimeToSeconds(currentQuestion.closing_time);
                await playerRef.current.setCurrentTime(closingTime);
            }
            
            // 영상 재생
            if (playerRef.current) {
                await playerRef.current.play();
            }
        }

        if (playerRef.current) {
            await playerRef.current.play();
        }
    };

    // 비디오 플레이어 렌더링
    return (
        <div style={{ position: 'relative', zIndex: 100 }}>
            <iframe
                ref={videoRef}
                src={videoData?.video_url ? getVideoUrl(videoData.video_url) : ''}
                width="100%"
                height="600"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Vimeo Video"
                preload= ""
            />

            {activeQuestions.map((question) => (
                <div key={question.qst_seq}>
                    {renderQuestionOverlay(question, handleClick, answeredQuestions)}
                </div>
            ))}
        </div>
    );
}

export default Video;