import React from "react";
import Badge from "../../components/Badge/Badge";
import { useNavigate } from "react-router-dom";

function MyTestList({listData}) {
    const navigate = useNavigate();
    const testBadgeType = {
        'story': {name: '동화', color: 'primary-900'},
        'general': {name: '일반', color: 'primary-50'},
        'compr': {name: '종합', color: 'secondary-50'},
    };

    const handleNextClick = (item) => {
        const { goods_type, order_seq, goods_seq } = item;
        
        // goods_type에 따라 다른 페이지로 이동
        switch(goods_type) {
            case 'story':
                navigate('/storyinformation', {
                    state: {
                        order_seq,
                        goods_seq
                    }
                });
                break;
            case 'general':
                navigate('/childreninformation', {
                    state: {
                        order_seq,
                        goods_seq
                    }
                });
                break;
            case 'compr':
                navigate('/synthesisinformation', {
                    state: {
                        order_seq,
                        goods_seq
                    }
                });
                break;
            default:
                console.error('Unknown goods_type:', goods_type);
        }
    };

    return (
        <div className="my_list my_test">
            {listData.map((item) => (
                <div className="assessment_info childrenDetail" key={item.goods_seq} onClick={() => handleNextClick(item)}>
                    <div className="assessment_details">
                        <div className="assessment_badge">
                            <Badge  badgeText={testBadgeType[item.goods_type].name}
                                    badgeColor={testBadgeType[item.goods_type].color}/>
                        </div>
                        <div className="assessment_title ellipsis1">{item.goods_name}</div>
                        <div className="assessment_meta group_line" style={{paddingTop: "6px"}}>
                            {item?.story_title_sub &&
                                <div className="assessment_code group_item">{item.story_title_sub}</div>}
                            <div className="assessment_code group_item">진행률 {item.progress_rate}%</div>
                        </div>
                    </div>
                    <div className="children_image_container">
                        <img className="Children_Img" src={item.list_image} alt="Children_Img"/>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MyTestList;
