import React from "react";
import Button from "../../components/Button/Button";
import Fail from "../../assets/img/fail.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";


function JoinFail(){
    const navigate = useNavigate();

    const handleFailClick = () => {
        navigate('/join');
    }

    const handleFindClick = () => {
        navigate('/')
    }
    return(
        <div className="passwordsuccess">
            <div className="content">

                <Header title="회원가입 실패" isShowBack={false} />
                <div className="success_body">
                    <div className="success_message">
                        <div className="success_image">
                            <img src={Fail} alt="이미지"/>
                        </div>
                        <div className="registration_message">
                            <div className="success_text">죄송해요 회원가입 과정에서</div>
                            <div className="success_text">문제가 발생했어요</div>
                            <div className="safety_message">조금 뒤에 다시 시도해 주세요.</div>
                        </div>
                    </div>
                </div>

                <div className="error_btn_change">
                        <Button
                            label="가입하러 가기"
                            variant="btn_join"
                            onClick={handleFailClick} />
                        <Button
                            label="다시 찾아볼래요"
                            variant="btn_complete"
                            onClick={handleFindClick} />
                </div>

            </div>
        </div>
    )
}

export default JoinFail;