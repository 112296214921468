import Header from "../Header/Header";

import React from 'react';

const DefaultPopup = ({ children,handleBack,title }) => {
    const [body, footer] = React.Children.toArray(children);
    return(
    <>
        <div className="default_popup ">
            <div className="modal_content">
                <div className="modal_wrap">
                    <Header title={title} isShowBack={false} isRight={true} handleBack={handleBack}/>
                    <div className="modal_body">

                            {body}
                    </div>
                    {footer && <div className="modal_foot">
                        {footer}
                    </div>}

                </div>
            </div>

        </div>
    </>
    )
}


export default DefaultPopup