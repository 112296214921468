import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import instance from "../../services/services";
import useUserStore from '../../store/user/userStore';

export const usePaymentComplete = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const { memberTokens } = useUserStore();

    useEffect(() => {
        const processPayment = async () => {
            const orderId = queryParams.get("orderId");
            const paymentKey = queryParams.get("paymentKey");
            const amount = queryParams.get("amount");

            if (!orderId || !paymentKey || !amount) {
                console.log("결제 파라미터가 없습니다");
                return;
            }

            try {
                // 헤더에 토큰 포함하여 요청
                const response = await instance.post(
                    `/api/pay/payment_request_mobile`,
                    { orderId, paymentKey, amount },
                    {
                        headers: {
                            Authorization: `Bearer ${memberTokens}`
                        }
                    }
                );

                if (response.data.code === 200) {
                    console.log("결제 완료 처리 성공:", response.data);
                    
                    // 결제 관련 파라미터만 제거
                    const newParams = new URLSearchParams();
                    newParams.set('goods_seq', queryParams.get('goods_seq'));
                    newParams.set('orderId', orderId);
                    
                    const newUrl = `${location.pathname}?${newParams.toString()}`;
                    navigate(newUrl, { replace: true });

                    
                    // // URL에서 파라미터 제거
                    // ["orderId", "paymentKey", "amount"].forEach(param => {
                    //     queryParams.delete(param);
                    // });
                    
                } else {
                    console.error("결제 실패:", response.data.message);
                }
            } catch (error) {
                console.error("결제 완료 처리 실패:", error);
            }
        };

        processPayment();
    }, [location.pathname, queryParams, navigate, memberTokens]);

    // orderId 반환
    return {
        orderId: queryParams.get("orderId")
    };
};
