import DropDownItem from "../../components/Page/DropDownItem";
import Header from "../../components/Header/Header";
import TextTab from "../../components/Tab/TextTab";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ListBottomSheet from "../../components/Popup/ListBottomSheet";
import PostActionPopup from "../../components/Popup/PostActionPopup";
import Button from "../../components/Button/Button";
import InquiryPopup from "../../components/Popup/InquiryPopup";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import {useBoardList} from "../../hooks/board/useBoardList";
import useToggle from "../../hooks/common/usePopupToggle";
import {useBoardTypeList} from "../../hooks/board/useBoardTypeList";
import {useBoardWrite} from "../../hooks/board/useBoardWrite";
import {useBoardDelete} from "../../hooks/board/useBoardDelete";

function MyInquiryList() {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [queryType, setQueryType] = useState(queryParams.get("type") || "inquiry");
    const previousTypeRef = useRef('');
    const currentTabList = useRef([]);
    const [activeIdx, setActiveIdx] = useState(null);
    const [activeTab, setActiveTab] = useState("inquiry");
    const [selectedType, setSelectedType] = useState(null);
    const [formData, setFormData] = useState(null);
    const tabList = useMemo(() => ({
        inquiry: [
            {name: "1:1 문의", value: "inquiry"},
            {name: "상담 신청", value: "counsel"},
        ],
        counsel: [
            {name: "1:1 문의", value: "inquiry"},
            {name: "상담 신청", value: "counsel"},
        ],
    }), []);
    const initialParams = {
        boardid: "mbqna",
        member_chk: "Y",
        board_type: "",
        offset: 1
    };
    const {
        ref,
        boardList,
        setParams,
        params,
        setBoardList
    } = useBoardList("inquiry", initialParams);
    const {
        fetchBoardType,
        getSelectList
    } = useBoardTypeList(queryType)
    const boardWrite = useBoardWrite(formData)
    const deleteBoard = useBoardDelete()
    const sheetPopup = useToggle(false);
    const writePopup = useToggle(false);
    const actionPopup = useToggle(false);
    const confirmPopup = useToggle(false);


    const handleBackClick = () => {
        navigate(-1);
    };

    const onTabClick = (item) => {
        setActiveTab(item.value);
        queryParams.set("type", item.value);
        navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
    };

    const handleDropDown = (event, idx) => {
        event.stopPropagation();
        setActiveIdx(activeIdx === idx ? null : idx);
    };

    const inquiryBadge = (isAnswer) => {
        return isAnswer ? {size: "sm", name: "답변완료", color: "primary-50"} : {
            size: "sm",
            name: "답변대기",
            color: "neutral-50"
        };
    };

    const openInquiryWrite = () => {
        writePopup.toggle()
    };

    const openSelectSheet = (event) => {
        event.stopPropagation();
        sheetPopup.toggle();
    };

    const openDotActionPopup = (event, b_seq) => {
        event.stopPropagation();
        deleteBoard.setB_seq(b_seq)
        actionPopup.toggle()
    };

    const handleDelete = () => {
        actionPopup.close()
        confirmPopup.open()
    };

    const handledSelectedItem = (selected) => {
        setSelectedType(selected);
        sheetPopup.close()
    };

    const submitInquiryForm = (data) => {
        setFormData({...data, boardid: params.boardid === "counsel" ? "counsel" : 'mbqna'});
        confirmPopup.open()
    };

    const onYesClick = async () => {
        if (writePopup.isOpen) {
            await boardWrite.handleBoardWrite()

            setBoardList([]);
            setParams((prev) => ({...prev, offset: 1}));
            writePopup.close();
            setSelectedType("");
        } else {
            await deleteBoard.deleteBoardPost()
            const newList = boardList.filter((post) => post.b_seq !== deleteBoard.b_seq)
            setBoardList(newList)
        }
    };

    // 팝업이 열릴 때 타입 리스트 가져오기
    useEffect(() => {
        if (writePopup.isOpen) {
            fetchBoardType();
        }
    }, [writePopup.isOpen, queryType, fetchBoardType]);


    // 리스트 API 호출
    useEffect(() => {
        const newQueryType = queryParams.get("type");

        if (previousTypeRef.current !== newQueryType) {
            setQueryType(newQueryType);
            setParams({
                boardid: newQueryType === "counsel" ? "counsel" : "mbqna",
                board_type: "",
                searchText: "",
                offset: 1,
            });
            setBoardList([]);
            setActiveIdx(null);
            previousTypeRef.current = newQueryType;
            setActiveTab(newQueryType);
        }

        currentTabList.current = tabList[newQueryType] || [];
    }, [queryParams, tabList, setParams, setBoardList]);




    return (
        <div className="inquiry_container">
            <div className="content">
                <Header title="나의 문의/신청" handleBack={handleBackClick}/>

                <div className="my_content_body inner_btm">

                    <TextTab
                        activeValue={activeTab}
                        tabList={currentTabList.current}
                        onTabClick={onTabClick}
                    />
                    <div>
                        {boardList.map((inquiry, idx) => (
                            <DropDownItem
                                key={inquiry.b_seq}
                                type={params.boardid}
                                isOpen={activeIdx === idx}
                                handleDropDown={(event) => handleDropDown(event, idx)}
                                closeDropDown={()=>setActiveIdx(null)}
                                listData={inquiry}
                                badge={inquiryBadge(inquiry.re_contents)}
                                dropClass="btm_border_box"
                                openDotActionPopup={(event) => openDotActionPopup(event, inquiry.b_seq)}
                            />
                        ))}
                        <div ref={ref}/>
                    </div>

                    <PostActionPopup isOpen={actionPopup.isOpen} onClose={actionPopup.close}
                                     handleDelete={handleDelete}/>
                    <InquiryPopup
                        title={params.boardid === "counsel" ? "상담 신청하기" : "1:1 문의하기"}
                        isOpen={writePopup.isOpen}
                        selectedType={selectedType}
                        openSelectSheet={openSelectSheet}
                        submitInquiryForm={submitInquiryForm}
                        close={writePopup.close}
                    />
                    <ListBottomSheet
                        isOpen={sheetPopup.isOpen}
                        onClose={sheetPopup.close}
                        listData={getSelectList()}
                        handledSelectedItem={handledSelectedItem}
                    />
                    <ConfirmPopup
                        onYesClick={onYesClick}
                        isOpen={confirmPopup.isOpen}
                        onClose={confirmPopup.close}
                        title={writePopup.isOpen ? "등록하시겠습니까?" : "정말 삭제하시겠습니까?"}
                    />
                </div>
            </div>
            <div className="Edit_btn_change">
                <Button onClick={openInquiryWrite} label={params.boardid === "counsel" ? "상담 신청하기" : "1:1 문의하기"}
                        variant="btn_complete"/>
            </div>
        </div>
    );
}

export default MyInquiryList;
