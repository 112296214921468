import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { validateNickname } from "../../utils/validation";
import {  useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { updateNickname } from "../../services/Information/information";
import useUserStore from '../../store/user/userStore';
import {toast} from "react-toastify";
import useMyInfo from "../../hooks/common/useMyInfo";


function NicknameEdit(){
    const [nickname, setNickname] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {myInfo} = useMyInfo();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        
        if (token) {
            useUserStore.getState().updateTokens(token);
        }
        if (storedUserInfo) {
            useUserStore.getState().updateUserInfo(storedUserInfo);
        }
        
        if (storedUserInfo?.nickname) {
            setNickname(storedUserInfo.nickname);
        }
    }, []);

    const handleChange = (e) => {
        const newNickname = e.target.value;
        setNickname(newNickname);

        if (newNickname.trim() === '') {
            setError('');
            return;
        }

        const [status, message] = validateNickname(newNickname);
        setError(status === "nickname" ? message : '');
    };

    const handleNicknameChange = async () => {
        try {
            const params = {
                push:myInfo.push,
                nickname: nickname
            };
            const response = await updateNickname(params);
            if (response.data.code === 200) {
                const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
                
                const updatedUserInfo = {
                    ...storedUserInfo,
                    nickname: nickname
                };
                
                localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
                
                useUserStore.getState().updateUserInfo(updatedUserInfo);
                
                toast('닉네임이 성공적으로 변경되었습니다.');
                navigate('/');
            }
        } catch (error) {
            setError(error.message || '닉네임 변경에 실패했습니다.');
        }
    };

    const handleBackClick = () => {
        window.history.back();
    };

    const isValidNickname = !error && nickname.trim().length > 0;

    return(
        <div className="nicknameEdit">
            <div className="content">

                <Header title="닉네임 변경" handleBack={handleBackClick}/>
 
                <div className="content_body">
                    <div className="nickname_instruction">닉네임을 입력해 주세요</div>
                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">닉네임</div>
                        <input 
                            type="text" 
                            className={`nickname_input ${error?'error':''}`}
                            onChange={handleChange} 
                        />
                        {error && <div className={`error_message nickname`}>{error}</div>}
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button 
                        label="변경" 
                        variant="btn_change" 
                        disabled={!isValidNickname} 
                        className={isValidNickname ? 'active' : 'disabled'} 
                        onClick={handleNicknameChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default NicknameEdit;