import React from 'react';
import { Link } from 'react-router-dom';

function Tab(props) {
    const tabs = props.tabs || [];

    const renderNavItem = (tab, index) => {
        const isLink = tab.label === '홈' ? '/' : tab.label === '마음처방' ? '/mindprescription' : tab.label === '동화검사' ? '/storytestpage' : null;
    
        // 아이콘의 클래스 이름을 조건부로 설정
        const iconClassName = (tab.label === '동화검사' || tab.label === '마음처방' || tab.label === '홈') 
            ? 'tab_icon_small'
            : 'tab_icon_large';
    
        // label이 없으면 다른 className 적용
        const itemClassName = tab.label ? 'nav_item' : 'nav_item_nolabel';
    
        return (
            <div className='item_container' key={index}>
                <Link to={isLink} style={{ textDecoration: "none", cursor: 'pointer' }}>
                    <div className={itemClassName} >
                        <div className='nav_icon'>
                            <img className={iconClassName} src={tab.icon} alt='탭 메뉴' />
                        </div>
                        {tab.label && isLink ? (
                                <div className='nav_label'>{tab.label}</div>
                        ) : (
                            tab.label && <div className='nav_label'>{tab.label}</div>
                        )}
                    </div>
                </Link>

            </div>
        );
    };
    

    return (
        <div className='NavBar_container'>
            <div className='nav_items'>
                {tabs.map((tab, index) => renderNavItem(tab, index))}
            </div>
        </div>
    );
}

export default Tab;