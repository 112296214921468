import React, { useState } from "react";
import Assimilation_img from "../../assets/img/Frame187.png";
import ButtonTab from "../../components/Tab/ButtonTab";
import Assimilation_img1 from "../../assets/img/assimilation_1.png";
import Assimilation_img2 from "../../assets/img/assimilation_2.png";
import Assimilation_img3 from "../../assets/img/assimilation_3.png";
import Assimilation_img4 from "../../assets/img/assimilation_4.png";
import Assimilation_img5 from "../../assets/img/assimilation_5.png";
import Assimilation_img6 from "../../assets/img/assimilation_6.png";
import Assimilation_img7 from "../../assets/img/assimilation_7.png";
import Assimilation_img8 from "../../assets/img/assimilation_8.png";


import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";


const DescriptionSection = ({ title, text }) => (
    <div className="description_section">
        <div className="section_title">{title}</div>
        <div className="description_content">{text}</div>
    </div>
);

const Step = ({ stepNumber, description, example }) => (
    <div className='step'>
        <div className='step_title'>STEP {stepNumber}</div>
        <div className='step_description'>{description}</div>
        <div className='step_example'>{example}</div>
    </div>
);

const ImpactStep = ({ title, content, image }) => (
    <div className="impact_step">
        <div>
            <img className='assimilation_image' src={image} alt="이미지" />
        </div>
        <div className="impact_title">{title}</div>
        <div className="description_content">{content}</div>
    </div>
);

const FriendsStep = ({ title, content, image }) => (
    <div className="friends_step">
        <div className="friends_title">{title}</div>
        {content.map((text, index) => (
            <div className="friends_content" key={index}>{text}</div>
        ))}
        {image &&
            (<div className="friends_step">
                <img className='assimilation_image' src={image} alt="이미지"/>
            </div>)}

    </div>
);

function Assimilation() {
    const navigate = useNavigate();
    const [backgroundType] = useState("bg-white");

    const handleBackClick = () => {
        window.history.back();
    };

    const handleButtonClick = () => {
        navigate('/storytestpage');
    }
    
    return (
        <div className="assimilation_container">
            <div className={`content ${backgroundType}`}>

                <Header title="동화 검사가 궁금하다면" handleBack={handleBackClick}/>
                
                <div className="content_body">
                    <div>
                        <img className="image_size" src={Assimilation_img} alt="이미지" />
                    </div>

                    <div className="description_section">
                        <div className="description_title">동화 검사가 무엇인가요?</div>
                        <div className="description_text">
                            비타민 웰빙 솔루션 동화는 생활 밀착형 스토리를 기반으로 하는 감성 동화입니다.
                            <br/>
                            <br/>

                            동화 검사를 진행하여 자녀 심리/정서 맞춤 솔루션을 제공하고, 아이의 성장 발달 문제를 예방하는 심리 정서 교육서입니다.
                        </div>

                    </div>

                    <DescriptionSection 
                        title="독서 치료 이론" 
                        text="동화책을 읽음으로써 문제가 되는 증상을 인문학적으로 치료하는 이론을 적용" 
                    />
                    <DescriptionSection 
                        title="모방 이론 (심리/교육학적 관점)" 
                        text="자신과 비슷한 특성이나 행동을 보이는 다른 사람들을 모방하여 자아 개념의 확립과 자아존중감의 발달을 지원하는 이론을 적용" 
                    />
                    <DescriptionSection 
                        title="색채 이론 (심리학적 관점)" 
                        text="아동의 심리적 안정과 긍정적인 시각화를 유도하고, 인지적 시각화를 확장하여 집중력과 학습 효과를 증대시키는 이론을 적용" 
                    />

                    <div className="description_section system_section">
                        <div className="section_title">동화 시스템</div>
                        <div className='steps_container'>
                            <div className='steps_row'>
                                <Step stepNumber={1} description="발달 상 어려움" example="ex) 환경변화" />
                                <Step stepNumber={2} description="현상 진단" example="행동/정서상태 확인" />
                            </div>
                            <div className='steps_row' style={{paddingTop:"8px"}}>
                                <Step stepNumber={3} description="가이드 제공" example="솔루션 제시" />
                                <Step stepNumber={4} description="건강한 몸맘뇌" example="형성 및 성장" />
                            </div>
                        </div>

                        <div className="positive_impact_container">
                            <div className="description_title">우리 아이에게 미치는<br/>긍정적인 영향 4가지</div>
                            <ImpactStep 
                                title="1. 독자 개발 캐릭터를 통한 접근성 용이" 
                                content="유 · 아동에게 친근함을 주는 캐릭터를 통해 스토리에 쉽게 몰입할 수 있도록 합니다." 
                                image={Assimilation_img1} 
                            />
                            <ImpactStep 
                                title="2. 아동의 성장 발달 인지 측정 및 분석" 
                                content="아동 집중력 범위 내에서 독서 치료 이론을 적용하여 내 아이의 성장 발달 문제를 알아볼 수 있습니다." 
                                image={Assimilation_img2}
                            />
                            <ImpactStep 
                                title="3. 동화 속 주제에 관한 문제 상황 직면" 
                                content="실감나는 동화 속 상황을 통해 내 아이가 비슷한 상황에 직면했을 때 문제 해결 능력을 향상시켜 줍니다." 
                                image={Assimilation_img3}
                            />
                            <ImpactStep 
                                title="4. 모방 및 경험 학습 이론 접목" 
                                content="아동 발달 특성 중 이야기 경험을 바탕으로 하여 생활 속에서 모방 학습 적용이 가능합니다." 
                                image={Assimilation_img4}
                            />

                            <div className="kikid_friends">
                                <div className="description_title">KIKID FRIENDS</div>
                                <div className="kikid_description">
                                    키키드 컴퍼니는 심리 및 지능과 관련된 캐릭터를 기획하고 제작합니다. 키키드 컴퍼니의 캐릭터들은 아동 심리 및 지능 발달 권위자 김동철 박사가 기획 및 제작하였습니다.
                                </div>
                                <div className="friends_step">
                                    <img className='assimilation_image' src={Assimilation_img5} alt="이미지" />
                                </div>
                            </div>

                            {[
                                {
                                    title: "퐁푸코 프렌즈",
                                    content: [
                                        "퐁푸코 프렌즈는 각 캐릭터마다 ‘순환, 자연, 우주’의 컨셉으로 이루어져 있으며, 그에 따른 자연 친화 지능, 대인 관계 지능, 공학 지능의 의미를 담고 있습니다.",
                                        "자연 친화 지능 ‘퐁이’, 대인 관계 지능 ‘푸리‘, 우주 공학 지능 ‘코리‘ 세 친구는 둘도 없는 단짝 친구들이며, 함께 다니면서 다양한 모험을 떠나는 국내 최초 아동 심리 · 지능 캐릭터입니다."
                                    ],
                                    image: Assimilation_img6
                                },
                                {
                                    title: "퐁이 FONG-I",
                                    content: [
                                        "자연 순환 지능을 가진 물의 상징 ‘퐁이’",
                                        "몸의 구성이 물로 채워져 있어 기분에 따라 물의 색이 변하기도 한다. 뱃속에 살아있는 물고기를 꺼내 먹거나 다시 넣어두기도 한다. 선물도 한다. 음악을 좋아하며 자유로운 여행을 즐긴다."
                                    ],
                                    image: Assimilation_img7
                                },
                                {
                                    title: "푸리 FURI",
                                    content: [
                                        "대인관계 지능을 가진 식물, 땅의 상징 ‘푸리’",
                                        "머리 위의 꽃봉우리 모양을 바꿔 기분을 표현하곤 한다. 기타, 카우보이 모자와 우비 모자를 소유하고 있다. 항상 주변에 동물 곤충, 식물이 풍부하며, 지렁이 친구와 종달새 찍이와 단짝 친구이다."
                                    ],
                                    image: Assimilation_img8
                                },
                                {
                                    title: "코리 CORI",
                                    content: [
                                        "우주 공학 지능을 가진 우주의 상징 ‘코리’",
                                        "엉뚱하고 호기심이 많은 코리는 로봇 공학, 기초과학에 관심이 많다. 변화무쌍한 성격 탓에 친구들과 장난치기 좋아하는 키키드 프렌즈의 대표 장난꾸러기. 아빠와 함께 전용 우주선을 타는 것을 좋아한다."
                                    ],

                                }
                            ].map((friend, index) => (
                                <FriendsStep 
                                    key={index}
                                    title={friend.title}
                                    content={friend.content}
                                    image={friend.image}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <ButtonTab
                    buttonText="동화 검사 바로가기"
                    onClick={handleButtonClick}
                />
            </div>
        </div>
    );
}

export default Assimilation;
