import Badge from "../Badge/Badge";
import dayjs from "dayjs";
import {useEffect, useRef, useState} from "react";
import dompurify from "dompurify";


const DropDownItem = ({
                          type = '',
                          dropClass = '',
                          badge = {},
                          handleDropDown,
                          listData,
                          isOpen,
                          openDotActionPopup,
                          closeDropDown = () => {} // 기본값 설정

                      }) => {
    // type :  inquiry -1:1문의 , counsel  - 상담

    const contentRef = useRef(null);
    const dropDownRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);

        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                closeDropDown();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, closeDropDown]);
    return (
        <div ref={dropDownRef} onClick={handleDropDown}
             className={`dropdown_item   ${dropClass} ${isOpen ? 'open' : ''}`}>

            <div className="badge_item">

                <Badge size={badge.size} badgeText={badge.name} badgeColor={badge.color}/>
            </div>
            {/*타이틀*/}
            <p className="drop_title ">
                <span className="ellipsis_clamp"
                      dangerouslySetInnerHTML={{__html: dompurify.sanitize(listData.subject)}}
                >

                    </span>
            </p>

            {/*컨텐츠*/}
            <div className="drop_content" ref={contentRef}
                 style={{maxHeight: isOpen ? `${contentHeight}px` : '0', margin: isOpen ? '8px 0' : ''}}>

                {/*질문*/}
                <p className="drop_text " dangerouslySetInnerHTML={{__html: dompurify.sanitize(listData.contents)}}></p>

                {/*답변*/}
                {((type === 'mbqna' || type === 'counsel') && listData?.re_contents) && (
                    <p className="answer_box" dangerouslySetInnerHTML={{__html: dompurify.sanitize(listData.re_contents)}}></p>

                )}

            </div>

            {/*버튼*/}
            {(type === 'mbqna' || type === 'counsel') && (
                <div className="drop_etc">
                    <div className="group_line">
                        <p className="group_item">{dayjs(listData.regist_date).format('YYYY.MM.DD')}</p>
                        <p className="group_item">{listData.board_type}</p>
                    </div>
                    <button type="button" className="dot_btn" onClick={openDotActionPopup}></button>
                </div>
            )}

        </div>

    )

}


export default DropDownItem