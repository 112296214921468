import {Link} from "react-router-dom";
import React from "react";
import alarm_off from "../../assets/icon/icon-black-bell.png";
import alarm_on from "../../assets/icon/Bell_pin_1.png";
import useUserStore from "../../store/user/userStore";
import useCommonStore from "../../store/common/useCommonStore";


const AlarmButton = () => {

    const {memberTokens} = useUserStore.getState();
    const {alarmCnt} = useCommonStore()


    if (!memberTokens) return



    return (

        <Link to='/alert'><img src={`${alarmCnt > 0 ? alarm_on : alarm_off}`} className='Icon_alarm' alt="알림 아이콘"/>
        </Link>
    )
}


export default AlarmButton