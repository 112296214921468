import React from 'react';

const RefundPopup = ({ isOpen, onClose, onYesClick }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='modal-wrapper'>
                    <div className="modal-title">환불을 진행하시겠습니까?</div>
                </div>
                <div className="modal-buttons">
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>아니요</button>
                    </div>
                    <div className="modal-button-wrapper">
                        <button className="modal-button-yes" onClick={() => { onYesClick(); onClose(); }}>네</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default RefundPopup;
