import React, { useState } from "react";
import Intro_Main_img from "../../assets/img/intro.png";
import Intro_img from "../../assets/img/intro_1.png";
import Intro_img2 from "../../assets/img/intro_2.png";
import Header from "../../components/Header/Header";

const DescriptionSection = ({ title, text }) => (
    <div className="description_section">
        <div className="section_title">{title}</div>
        <div className="description_content">{text}</div>
    </div>
);


const ImpactStep = ({ title, content }) => (
    <div className="impact-container">
        <div className="impact-title">{title}</div>
        <div className="impact-content">{content}</div>
    </div>
);


function Intro() {
    const [backgroundType] = useState("bg-white");

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="assimilation_container">
            <div className={`content ${backgroundType}`}>

                <Header title="회사소개" handleBack={handleBackClick}/>
                
                <div className="content_body">
                    <div>
                        <img className="image_size" src={Intro_Main_img} alt="이미지" />
                        <div className="description_section">
                            <div className="description_title">아이큐비타민은<br/>어떤 회사인가요?</div>
                            <div className="description_text">
                                ㈜ 아이큐비타민은 국내 최고의 심리 콘텐츠를 기획 및 개발하고 헬스케어 데이터 비즈니스를 운영합니다.
                                <br/><br/>
                                심리 헬스케어 빅데이터를 기반으로 AI, DL 기술을 접목시켜 사람들의 몸, 맘, 뇌가 건강한 통합 헬스케어를 지향합니다.
                            </div>

                        </div>
                    </div>

                    <div style={{marginTop:"40px"}}>
                        <div className="description_title">우리의 지향점이 궁금해,</div>
                        <div className="description_section">
                            <img className="Intro_img" src={Intro_img} alt="이미지" />
                        </div>
                    </div>

                    <DescriptionSection 
                        title="비전" 
                        text="모든 인간은 신체적 건강, 정서적 안정, 창의적 균형을 이루어야 합니다. 개인의 전인적 발달을 위해 균형 있는 몸, 맘, 뇌의 건강과 성장을 추구하며, 아름다운 삶과 행복을 지향하고자 합니다." 
                    />
                    <DescriptionSection 
                        title="미션" 
                        text="우리는 인간의 몸, 맘, 뇌의 문제 뿐 아니라 사회적인 관점까지 포함하여 다각적으로 일상과 사회를 연구합니다. 혁신적이고 실질적 변화를 이뤄낼 수 있도록 다양한 영역에서의 심리 콘텐츠를 개발하고자 합니다." 
                    />

                        <div className="positive_impact_container">
                            <div className="description_title">우리가 생각하는<br/>핵심 가치를 알아봐요!</div>
                            <div className="description_section">
                                <img className="Intro_img" src={Intro_img2} alt="이미지" />
                            </div>
                            <ImpactStep 
                                title="사랑" 
                                content="우리는 개인을 가장 아름답고 소중한 존재로 생각한다. 그들을 사랑하는 마음으로 심리 콘텐츠를 개발하고 제공한다." 
                            />
                            <ImpactStep 
                                title="진실성" 
                                content="우리는 정직과 윤리의식에 기반한 경영을 한다. 환경 및 사회적 책임을 다하며, 지속적으로 성장하는 기업이 된다." 
                            />
                            <ImpactStep 
                                title="열정" 
                                content="개인 심리·신체 건강을 위하여 심리·사회 문화를 연구한다. 각자의 전문성을 개발하며 질적인 콘텐츠 제공을 노력한다." 
                            />
                            <ImpactStep 
                                title="책임" 
                                content="몸·뇌의 통합 심리 콘텐츠를 제공하여 개인의 삶에서 지속가능한 변화가 일어날 수 있도록 콘텐츠에 대한 책임을 가진다." 
                            />
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Intro;
