import React, { useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Call_arrow from "../../assets/img/call_arrow.png";
import Call_arrow2 from "../../assets/img/Call_arrow2.png";
import Call_img from "../../assets/img/dummy_3.png";
import Call_img2 from "../../assets/img/dummy_4.png";
import ButtonTab from "../../components/Tab/ButtonTab";

function CallPaymentDetail() {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [currentImages, setCurrentImages] = useState(
        Array(3).fill(Call_arrow) 
    );
    const callArrowContainerRef = useRef(null);

    const callTypes = [
        {
            title: "건강하게 자라기",
            details: "신체발달",
            count: 11,
            images: [Call_img, Call_img, Call_img2, Call_img, Call_img, Call_img2],
        },
        {
            title: "건강하게 자라기",
            details: "신체발달",
            count: 11,
            images: [Call_img, Call_img, Call_img2, Call_img, Call_img, Call_img2],
        }
    ];

    const handleBackClick = () => {
        window.history.back();
    };

    const handleImageClick = (index) => {
        const newCurrentImages = [...currentImages];
        if (expandedIndex === index) {
            newCurrentImages[index] = Call_arrow; 
            setExpandedIndex(null);
        } else {
            newCurrentImages[index] = Call_arrow2;
            setExpandedIndex(index);
        }
        setCurrentImages(newCurrentImages);
    };

    const handleMouseDown = (e) => {
        const container = callArrowContainerRef.current;
        const startX = e.pageX - container.offsetLeft;
        const scrollLeft = container.scrollLeft;

        const handleMouseMove = (e) => {
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        };

        const handleMouseUp = () => {
            container.removeEventListener('mousemove', handleMouseMove);
            container.removeEventListener('mouseup', handleMouseUp);
            container.style.cursor = 'grab';
        };

        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseup', handleMouseUp);
        container.style.cursor = 'grabbing';
    };

    return (
        <div className="CallPayment">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick} />
                <div className="info_wrapper">
                    <Badge badgeColor="primary-60" badgeText="동화" />
                    <div className="intro_age_group">건강하게 자라기 시리즈</div>
                    <div className="intro_call_info">60권</div>
                </div>


                    <div style={{marginTop:"20px"}}>
                    {callTypes.map((callType, index) => (
                        <div className="call_payment_Detail_wrapper" key={index}>
                            <div className="call_type_container">
                                <div className="call_type_info">
                                    <div className="call_type_title">{callType.title}</div>
                                    <div className="call_type_count">
                                        <div className="call_type_details" style={{fontSize:"15px"}}>
                                            {callType.details}
                                            <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                                            <span>{callType.count}권</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="call_arrow_container" onClick={() => handleImageClick(index)}>
                                    <img src={currentImages[index]} alt="Call_arrow" className="Call_arrow_img" />
                                </div>
                            </div>
                            {expandedIndex === index && (
                                <div
                                    className="call_img_data"
                                    onMouseDown={handleMouseDown}
                                    ref={callArrowContainerRef}
                                >
                                    {callType.images.map((img, imgIndex) => (
                                        <img key={imgIndex} src={img} alt={`Call_arrow_${imgIndex}`} className="Call_img" />
                                    ))}
                                </div>
                            )}
                            <div className="payment_line"></div>
                        </div>
                ))}
                    </div>

                <div />
            </div>
            <ButtonTab buttonText="전체 구매하기" />
        </div>
    );
}

export default CallPaymentDetail;
