import { useEffect } from 'react';
import { loginUser } from '../../services/Auth/auth';
import useUserStore from '../../store/user/userStore';

export const useAutoLogin = () => {
    useEffect(() => {
        const checkAutoLogin = async () => {
            const autoLoginEnabled = localStorage.getItem('autoLogin') === 'true';
            const savedId = localStorage.getItem('savedId');
            const savedPassword = localStorage.getItem('savedPassword');
            const accessToken = localStorage.getItem('access_token');

            if (autoLoginEnabled && savedId && savedPassword && !accessToken) {
                try {
                    const response = await loginUser(savedId, atob(savedPassword), true);
                    
                    if (response?.data?.code === 200) {
                        const { access_token, userInfo } = response.data.data;
                        
                        localStorage.setItem('access_token', access_token);
                        localStorage.setItem('userInfo', JSON.stringify(userInfo));
                        
                        useUserStore.getState().updateTokens(access_token);
                        useUserStore.getState().updateUserInfo(userInfo);
                    }
                } catch (error) {
                    console.error('자동 로그인 실패:', error);
                    localStorage.removeItem('autoLogin');
                    localStorage.removeItem('savedId');
                    localStorage.removeItem('savedPassword');
                }
            }
        };

        checkAutoLogin();
    }, []);
}; 