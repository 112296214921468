import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";
import instance from "../../../services/services";
import { GENERAL_GOODS_URL, PAYCANCEL_URL } from "../../../utils/urls";
import { usePaymentComplete } from "../../../hooks/payment/usePaymentComplete";
import RefundPopup from "../../../components/Popup/RefundPopup";

function SynthesisInformation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    console.log(location.state.goods_seq, '::location');
    const [selectedGender, setSelectedGender] = useState("female");
    const [age, setAge] = useState(""); // 나이 입력값 상태
    const [name, setName] = useState(""); // 이름 입력값 상태
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [isRefundPopOpen, setIsRefundPopOpen] = useState(false);
    const [data, setData] = useState([]);
    usePaymentComplete();
;
    const queryParams = new URLSearchParams(location.search);
    const goodsSeq = queryParams.get("goods_seq") || location.state.goods_seq; // url 에서 goodsSeq 값 가져오기
    // const orderId = queryParams.get("orderId"); // url 에서 orderId 값 가져오기

    useEffect(() => {
        const fetchSynthesisData = async () => {
            try {
                const response = await instance.post(GENERAL_GOODS_URL, {
                    goods_seq: goodsSeq
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error("데이터 조회 실패");
                }
            } catch (error) {
                console.error("error 발생");
            }
        };
        fetchSynthesisData();
    }, [goodsSeq]);

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleNextClick = () => {
        if(isButtonEnabled){
        navigate('/synthesistestdetail');
        }
    }

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleGenderChange = (gender) => {
        setSelectedGender(gender);
    };

    const handleRefundClick = () => {
        setIsRefundPopOpen(true);
    };
    
    const handleRefund = async () => {
        const orderSeq = location.state?.order_seq || searchParams.get('orderId');
        
        try {
            const response = await instance.post(PAYCANCEL_URL, {
                order_seq: orderSeq,
                item_option_seq: '임시값'
            });

            // 서버 응답의 메시지를 표시
            alert(response.data.message || '환불 처리 중 오류가 발생했습니다.');
            
            // 성공한 경우에만 메인 페이지로 이동
            if (response.data.code === 200) {
                navigate('/');
            }
            
            // 팝업 닫기
            setIsRefundPopOpen(false);
            
        } catch (error) {
            console.error('환불 처리 오류:', error);
            alert(error.response?.data?.message || '환불 처리 중 오류가 발생했습니다.');
        }
    };

    const isButtonEnabled = age.trim() !== "" && name.trim() !== "";

    return (
        <div className="ChildrenInformation">
            <div className="content">
                <Header title="종합 검사" handleBack={handleBackClick}/>
                <div className="intro_body">
                    <div className="intro_section">
                        <div className="intro_title_assessment">종합</div>
                        <div className="intro_age_group">{data?.goods_name}</div>
                        <div className="assessment_meta">
                            <div className="assessment_question_count">{data?.qstCnt} 문항</div>
                        </div>
                        <div className="intro_content">{data?.goods_detail}</div>
                        <div className="assessment_refund" onClick={handleRefundClick}>환불 진행하기</div>
                    </div> 
                </div>
                    <div className="line_height"/>

                    <div className="content_body">
                        <div className="age_input_section">
                            <div className="input_label">나이 입력</div>
                            <input className="age_input" type="text"
                                placeholder="만 나이로 기재해 주세요"
                                value={age}
                                onChange={(e) => setAge(e.target.value)} />
                        </div>

                        <div className="gender_input_section">
                            <div className="input_label">성별 입력</div>
                            <div className="gender_selection">
                            <div
                                className={`gender_option ${selectedGender === "female" ? "selected" : ""}`}
                                onClick={() => handleGenderChange("female")}
                            >
                                여자
                            </div>
                            <div
                                className={`gender_option ${selectedGender === "male" ? "selected" : ""}`}
                                onClick={() => handleGenderChange("male")}
                            >
                                남자
                            </div>
                            </div>
                        </div>

                        <div className="name_input_section">
                            <div className="input_label">이름 입력</div>
                            <input className="name_input"
                                    type="text" 
                                    placeholder="이름을 기재해 주세요"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <ul className="refund_phrase marker_list">
                            <li className="dash_item">검사 진행 후에는 환불이 불가합니다.</li>
                        </ul>
                    </div>

                    
                <div className="Edit_btn_change">
                    <Button  
                        label="검사 시작하기" 
                        variant="btn_change" 
                        className={isButtonEnabled ? "active" : "disabled"}
                        onClick={handleNextClick}
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick} 
                />
                <RefundPopup
                    isOpen={isRefundPopOpen}
                    onClose={() => setIsRefundPopOpen(false)}
                    onYesClick={handleRefund}
                />
            </div>
        </div>
    );
}

export default SynthesisInformation;
