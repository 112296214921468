import DropDownItem from "../../components/Page/DropDownItem";
import Header from "../../components/Header/Header";
import TextTab from "../../components/Tab/TextTab";

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useBoardTypeList} from "../../hooks/board/useBoardTypeList";
import {useBoardList} from "../../hooks/board/useBoardList";


function MyInquiryList() {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const previousTypeRef = useRef('');
    const initialParams = {
        boardid: 'faq',
        board_type: '',
        searchText: '',
        offset: 1,
    };
    const {
        boardList,
        setParams,
        params,
        setBoardList,
        ref
    } = useBoardList("faq", initialParams);
    const {
        fetchBoardType,
        getSelectList
    } = useBoardTypeList('faq')
    const [activeIdx, setActiveIdx] = useState(null) // 드롭다운 활성화 된 인덱스
    const [activeTab, setActiveTab] = useState('') // 활성화된 탭




    // 뒤로가기
    const handleBackClick = () => {
        navigate(-1)
    }


    // 탭클릭
    const onTabClick = (item) => {
        setActiveTab(item.value);
        queryParams.set('kind', item.value);
        navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
    };


    // 드롭다운 클릭
    const handleDropDown = (event, idx) => {
        event.stopPropagation();
        setActiveIdx(activeIdx === idx ? null : idx)
    }

    // 뱃지 타입에 따라 ui 변경
    const inquiryBadge = (name) => {
        return {size: 'sm', name: name, color: 'primary-50'}
    }



    useEffect(() => {
        fetchBoardType();
    }, [fetchBoardType]);


    useEffect(() => {
        const newQueryType = queryParams.get("kind")||'';
        if (previousTypeRef.current !== newQueryType) {
            // 초기화
            setParams({
                boardid: 'faq',
                board_type: newQueryType,
                searchText: '',
                offset: 1,
            });
            setBoardList([]);
            setActiveIdx(null)
            previousTypeRef.current = newQueryType;
            setActiveTab(newQueryType)
        }
    }, [queryParams, setParams, setBoardList])






    return (


        <div className="inquiry_container">
            <div className="content">

                <Header title="FAQ" handleBack={handleBackClick}/>

                <div className="my_content_body inner_btm">
                    <TextTab
                        activeValue={activeTab}
                        tabList={getSelectList()}
                        onTabClick={onTabClick}
                    />
                    <div>

                        {boardList.map((faq, idx) => (
                            <DropDownItem key={faq.b_seq}
                                          listData={faq}
                                          type={params.type}
                                          isOpen={activeIdx === idx}
                                          handleDropDown={(event) => handleDropDown(event, idx)}
                                          faqData={faq}
                                          badge={inquiryBadge(faq.board_type_name)}
                                          dropClass="btm_border_box"
                            />
                        ))}
                        <div ref={ref}/>
                    </div>


                </div>
            </div>

        </div>

    )
}


export default MyInquiryList;