import React, {useState} from "react";
import Arrow from '../../assets/img/ic_arrow.png';
import CheckBasic from '../../assets/img/radio.png';
import Check from '../../assets/img/radio_click.png';
import Button from "../../components/Button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {useAppleLogin, useKakaoLogin, useNaverLogin} from "../../hooks/login/snsLogin";
import {KAKAO_REDIRECT_URI, NAVER_REDIRECT_URI} from "../../utils/urls";

const agreementItems = [
    {text: "약관 전체 동의", isMandatory: false},
    {text: "(필수) 만 14세 이상", isMandatory: true},
    {text: "(필수) 셀닥 서비스 이용약관", isMandatory: true},
    {text: "(필수) 개인정보수집 및 이용동의", isMandatory: true},
    {text: "(선택) 개인정보 마케팅 활용 동의", isMandatory: true},
    {text: "(선택) 광고성 정보 수신동의", isMandatory: true},
];

function Agreement() {
    const navigate = useNavigate();
    const location  = useLocation()
    const [checkedItems, setCheckedItems] = useState(Array(agreementItems.length).fill(false));
    const naverLoginInfo = useNaverLogin(NAVER_REDIRECT_URI); // 네이버 로그인 유저정보
    const kakaoLoginInfo = useKakaoLogin(KAKAO_REDIRECT_URI); // 카카오 로그인 유저정보
    const appleLoginInfo = useAppleLogin('https://www.iqvitamininside.com/agreement')//애플 로그인
    const handleBackClick = () => navigate('/');

    const toggleCheck = (index) => {
        const newCheckedItems = [...checkedItems];

        if (index === 0) { // "약관 전체 동의" 항목 클릭 시
            const allChecked = newCheckedItems.every(item => item); // 모든 항목이 체크되었는지 확인
            newCheckedItems.fill(!allChecked); // 체크 상태를 반전시킴
        } else {
            newCheckedItems[index] = !newCheckedItems[index]; // 특정 항목의 체크 상태 반전
            // 만약 특정 항목이 체크되면 "약관 전체 동의"도 체크
            if (newCheckedItems[index]) {
                newCheckedItems[0] = newCheckedItems.slice(1).every(item => item); // 모든 항목이 체크된 경우 "약관 전체 동의"도 체크
            } else {
                newCheckedItems[0] = false; // 특정 항목이 해제되면 "약관 전체 동의" 해제
            }
        }

        setCheckedItems(newCheckedItems);
    };

    // 모든 필수 항목이 체크되어 있는지 확인
    const allMandatoryChecked = checkedItems.slice(1, 4).every(item => item);

    const handleNextClick = () => {


        const snsData = naverLoginInfo.snsInfo?.fromSns ? naverLoginInfo.snsInfo : kakaoLoginInfo.snsInfo?.fromSns ? kakaoLoginInfo.snsInfo : location.state
console.log(appleLoginInfo,'appleLoginInfo')
        if (allMandatoryChecked) {
            if (snsData) {
                navigate('/userinfoForm', {
                    state: {
                        fromSns: snsData?.fromSns,
                        provider: snsData?.provider,
                        snsUserInfo: snsData?.snsUserInfo
                    }
                });
            } else {
                navigate('/userinfoForm')
            }
        }
    };


    return (
        <div className="agreement">
            <div className="content">

                <Header title="약관 동의" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="nickname_instruction">약관 동의가 필요해요</div>
                    <div className="agreement_items">
                        {agreementItems.map((item, index) => (
                            <div
                                className={`agreement_item ${index === 0 ? 'agreement_item_highlighted' : ''}`}
                                key={index}
                                onClick={() => toggleCheck(index)}
                            >
                                <div className="check_icon">
                                    <img
                                        className="check_image"
                                        src={checkedItems[index] ? Check : CheckBasic}
                                        alt="check"
                                    />
                                </div>
                                <div className="agreement_text">{item.text}</div>
                                {item.isMandatory && (
                                    <div className="arrow_icon">
                                        <img className="arrow_image" width={16} src={Arrow} alt="arrow"/>
                                    </div>
                                )}
                            </div>
                        ))}

                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button
                        label="다음"
                        variant="btn_next"
                        disabled={!allMandatoryChecked}
                        className={allMandatoryChecked ? 'active' : ''}
                        onClick={handleNextClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default Agreement;
