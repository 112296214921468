import {create} from 'zustand';

const useMyPageStore = create((set, get) => ({
    listCtgData: [],
    setListCtg: (list, isAllList=false) => set({
        listCtgData: isAllList
            ? [{name: '전체', value: ''}, ...list]
            : list,
    }),
    getSelectList: () => {
        const listCtgData = get().listCtgData;

        const list = listCtgData.map((tab) => {
            if (tab.value !== '') {
                return {
                    name: tab.value,  // value를 name에 사용
                    value: tab.codecd, // codecd를 value에 사용
                }
            }
            return tab
        });

        return list;
    },
}));

export default useMyPageStore;

