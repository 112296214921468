import React from 'react';

function ButtonTab({ buttonText, onClick  }) {
    return (
        <div className='btnTab_container'>
            <div className='button_container'>
                <button className='custom_button' onClick={onClick}> {buttonText} </button>
            </div>

        </div>
    );
}

export default ButtonTab;
