import React from "react";
import Button from "../../components/Button/Button";
import success_check from '../../assets/img/success_check.png';
import Success_Img from '../../assets/img/Frame 427319156.png';
import { useNavigate, useLocation } from "react-router-dom";


function JoinSuccess(){
    const navigate = useNavigate();
    const location = useLocation();
    const userName = location.state?.userName || '사용자';

    const handleSuccesClick = () => {
        navigate('/login');
    }
    return(
        <div className="passwordsuccess">
            <div className="content">

                <div className="content_body">
                    <div className="success_message_container">
                        <div className="success_icon_wrapper">
                            <img src={success_check} alt="success_check" className="success_check"/>
                        </div>
                        <div className="success_message">
                            {userName}님<br/>회원가입을 완료했어요
                        </div>
                    </div>
                    <div className="success_image_container">
                        <img src={Success_Img} alt="Success_Img" className="Success_Img"/>
                    </div>

                    <div className="Edit_btn_change">
                        <Button 
                            label="로그인 페이지로"  
                            variant="btn_complete"
                            onClick={handleSuccesClick} 
                        />
                    </div>
                </div>


                <div className="Edit_btn_change">
                        <Button 
                            label="로그인 페이지로"  
                            variant="btn_complete"
                            onClick={handleSuccesClick} />
                </div>
                
            </div>
        </div>
    )
}

export default JoinSuccess;