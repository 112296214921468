export function passwordvisible(e, value) {
    e.preventDefault(); // 기본 클릭 동작 방지

    if (value) {
        const inputElement = document.querySelector(`.login_input[value="${value}"]`);
        if (inputElement) {
            inputElement.focus();
        }
    }
}
