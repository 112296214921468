import React from "react";

const Header = ({title = '', isShowBack = true, handleBack,isRight=false,rightClassName='close_btn'}) => {
    return (

        <header className="layout_header">
            <div className="header_inner">
                {isShowBack && (
                    <button
                        type="button"
                        className="back_btn header_left_btn"
                        onClick={handleBack}
                    ></button>
                )}
                {
                    title && (
                        <h2 className="header_title">{title}</h2>
                    )
                }

                {isRight &&
                    <button
                        type="button"
                        className={`header_right_btn ${rightClassName}`}
                        onClick={handleBack}
                    ></button>
                }
            </div>
        </header>
    );
}

export default Header;
