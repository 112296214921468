// 선택지 타입별 스타일 설정
const overlayStyles = {
    2: {  // 2지선다 (가로)
        position: 'absolute',
        width: '18%',
        height: '13%',
        maxWidth: '550px',
        cursor: 'pointer',
        zIndex: 10,
        pointerEvents: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    3: {  // 3지선다 (가로)
        position: 'absolute',
        width: '14%',
        height: '11.5%',
        maxWidth: '550px',
        cursor: 'pointer',
        zIndex: 10,
        pointerEvents: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    4: {  // 4지선다 (가로)
        position: 'absolute',
        width: '12%',
        height: '10%',
        maxWidth: '550px',
        cursor: 'pointer',
        zIndex: 10,
        pointerEvents: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    5: {  // 5지선다 (세로)
        position: 'absolute',
        width: '3%',
        height: '3%',      // 세로 배치에 맞게 크기 조정
        maxWidth: '550px',
        cursor: 'pointer',
        zIndex: 10,
        pointerEvents: 'auto',
        transform: 'translate(530%, 0%)'
    },
    6: {  // 6지선다 (가로)
        position: 'absolute',
        width: '8%',
        height: '8%',
        maxWidth: '550px',
        cursor: 'pointer',
        zIndex: 10,
        pointerEvents: 'auto',
        transform: 'translate(-50%, -50%)'
    }
};

// answer_type에 따라 선택지를 렌더링하는 함수
const renderChoiceOverlay = (question, onAnswer, answeredQuestions) => {
    const choiceCount = parseInt(question.answer_type);

    const getPosition = (index, count) => {
        const positions = {
            2: { left: 39 + (index * 22.5), top: '58%' },    // 가로 배치
            3: { left: 31 + (index * 19), top: '57.8%' },    // 가로 배치
            4: { left: 25 + (index * 17), top: '55%' },      // 가로 배치
            5: { left: '96%', top: 42.2 + (index * 4.5) },      // 세로 배치
            6: { left: 15 + (index * 14), top: '51%' }       // 가로 배치
        };
        return positions[count];
    };

    return (
        <div key={question.qst_seq}>
            {[...Array(choiceCount)].map((_, index) => {
                const position = getPosition(index, choiceCount);
                const choiceValue = index + 1;

                return (
                    <div
                        key={`${question.qst_seq}-${index}`}
                        onClick={() => onAnswer(question.qst_seq, index)}
                        className={`choice-overlay choice-${choiceCount}`}
                        style={{
                            ...overlayStyles[choiceCount],
                            left: `${position.left}%`,
                            top: typeof position.top === 'number' ? `${position.top}%` : position.top,
                            backgroundColor: answeredQuestions[question.qst_seq] === choiceValue
                                ? '#ff0000'
                                : '#ff0000ad'
                        }}
                    />
                );
            })}
        </div>
    );
};

// 메인 렌더링 함수
export const renderQuestionOverlay = (question, onAnswer, answeredQuestions) => {
    // answer_type이 2~6 사이인 경우에만 렌더링
    const answerType = parseInt(question.answer_type);
    if (answerType >= 2 && answerType <= 6) {
        return renderChoiceOverlay(question, onAnswer, answeredQuestions);
    }
    return null;
}; 