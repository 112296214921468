import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../../components/Header/Header";
import React, {useEffect, useMemo, useRef, useState} from "react";
import TextTab from "../../components/Tab/TextTab";
import MyTestItems from "./MyTestItems";
import MyReportItems from "./MyReportItems";
import MyPaymentItems from "./MyPaymentItems";
import {useBoardList} from "../../hooks/board/useBoardList";
import {usePaymentComplete} from "../../hooks/payment/usePaymentComplete";

const MyPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [queryType, setQueryType] = useState(queryParams.get("type") || "");
    const [activeTab, setActiveTab] = useState(''); // 활성화된 탭
    const previousTypeRef = useRef('');
    const previousKindRef = useRef('');
    const currentTabList = useRef([]); // listType 따른 탭 리스트
    const initialParams = {
        "goods_type": [],
        "limit": 10,
        "offset": 1
    };
    const {
        ref,
        boardList,
        setParams,
        setBoardList
    } = useBoardList(queryType, initialParams);

    usePaymentComplete() // 결제 승인 api
    
    const tabList = useMemo(() => ({
        payment: [
            {name: '전체', value: ''},
            {name: '결제 내역', value: '25'},
            {name: '환불 내역', value: '85'},
        ],
        test: [
            {name: '전체', value: ''},
            {name: '동화 검사', value: 'story'},
            {name: '일반/종합 검사', value: 'general, compr'},

        ],
        report: [
            {name: '전체', value: ''},
            {name: '동화 검사', value: 'story'},
            {name: '일반/종합 검사', value: 'general, compr'},
        ],
    }), []);


    const handleBackClick = () => {
        navigate('/');
    };


    const onTabClick = (item) => {
        setActiveTab(item.value);
        queryParams.set('kind', item.value);
        navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
    };

    // 제목 설정
    const getHeaderTitle = () => {
        if (queryType === 'test') {
            return '내 심리 검사'
        } else if (queryType === 'report') {
            return '내 리포트';
        } else if (queryType === 'payment') {
            return '결제정보';
        }
        return '내 리포트';
    }

    useEffect(() => {
        const newQueryType = queryParams.get("type") || '';
        const newQueryKind = queryParams.get("kind") || '';

        // 타입이나 종류가 변경되었을 때만 실행
        if (previousTypeRef.current !== newQueryType || previousKindRef.current !== newQueryKind) {
            setQueryType(newQueryType);

            //queryType 결제 일때만 다름
            const updatedParams = newQueryType === 'payment'
                ? {step: newQueryKind, limit: 10, offset: 1}
                : {goods_type: newQueryKind ? newQueryKind.split(',') : [], limit: 10, offset: 1};

            // "일반/종합 검사"에 해당하는 데이터 요청
            if (newQueryKind === 'general, compr') {
                updatedParams.goods_type = ['general', 'compr'];
            }

            setParams(updatedParams);
            setBoardList([]);


            previousTypeRef.current = newQueryType;
            previousKindRef.current = newQueryKind;
            setActiveTab(newQueryKind);
        }

        // 현재 탭 리스트 설정
        currentTabList.current = tabList[newQueryType] || [];
    }, [queryParams, tabList, setParams, setBoardList]);



    return (
        <div className="assimilation_container">
            <div className="content">
                <Header title={getHeaderTitle()} handleBack={handleBackClick}/>
                <div className="my_content_body">
                    <TextTab
                        activeValue={activeTab}
                        tabList={currentTabList.current}
                        onTabClick={onTabClick}
                    />
                    <div className="my_list_section">

                        {queryType === 'test' ? (
                            <MyTestItems listData={boardList}/>
                        ) : queryType === 'report' ? (
                            <MyReportItems listData={boardList}/>
                        ) : (
                            <MyPaymentItems listData={boardList}/>
                        )}
                        <div ref={ref}/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MyPage;
