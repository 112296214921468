import React from 'react';
import { useNavigate } from "react-router-dom";

const ReportPop = ({ isOpen, onClose, onYesClick }) => {
    const navigate = useNavigate();
    if (!isOpen) return null;
    
    
    const handleYesClick = () => {
        onYesClick();
        onClose();
        navigate('/my-list?type=report');
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='modal-wrapper'>
                    <div className="modal-title">리포트를 확인하시겠습니까?</div>
                </div>
                <div className="modal-buttons">
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>아니요</button>
                    </div>
                    <div className="modal-button-wrapper">
                        <button className="modal-button-yes" onClick={handleYesClick}>네</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default ReportPop;
