import React from "react";
import {NAVER_REDIRECT_URI} from "../../utils/urls";
import {useNaverLogin} from "../../hooks/login/snsLogin";



const NaverLoginButton = () => {
    const {handleNaverLogin} = useNaverLogin(NAVER_REDIRECT_URI);
    return (
        <>
            <div id="customNaverButton" className="sns_btn left_icon naver" onClick={handleNaverLogin}>
                네이버로 로그인
            </div>
            <div id="naverIdLogin" style={{display: 'none'}}></div>
        </>
    );
};

export default NaverLoginButton;
