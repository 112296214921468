import React, {useState} from "react";
import Footer_Open from "../../assets/icon/footer_open.png";
import Footer_Layer from "../../assets/img/footer_layer.png";
import Footer_img from "../../assets/img/Footer_img.png";

function Footer() {
    const [isOpen, serIsopen] = useState(false)

    const toggleFooter = () => {
        serIsopen(!isOpen);
    }

    return (
        <div className={`footer_container ${isOpen ? 'active' : ''}`}>
            <div className="footer_brand" onClick={toggleFooter}>
                <div className="brand_info">
                    (주)아이큐비타민
                </div>
                <div className="footer_toggle">
                    <img className="footer_whether" onClick={toggleFooter} src={Footer_Open} alt="이미지"/>
                </div>
            </div>
            <div className="footer_info">
                <div>대표 김진아 | 사업자 등록번호 117-81-885776</div>
                <div>서울특별시 강서구 마곡중앙4로 18 그란트타워B 520호, 521호</div>
                <div>통신판매업 신고번호 2020-서울강서-1154</div>
                <a
                    href="https://www.dvaxbio.co.kr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer_layer"
                >
                    <img
                        className="Footer_img"
                        src={Footer_img}
                        alt="DvaxBio 로고"
                    />
                </a>

                <a
                    href="https://www.kpc.or.kr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer_layer"
                >
                    <img
                        className="footer_layer_img"
                        src={Footer_Layer}
                        alt="한국출판문화산업진흥원 로고"
                    />
                </a>

            </div>
            <div className="footer_rights">
                ©아이큐비타민 All right reserved
            </div>
        </div>

    )
}

export default Footer;