import React, {useState} from "react";
import dayjs from "dayjs";
import Header from "../../components/Header/Header";
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {useBoardList} from "../../hooks/board/useBoardList";
import dompurify from "dompurify";

function NoticeList() {
    const navigate = useNavigate();
    const {b_seq} = useParams();
    const [backgroundType] = useState("bg-white");
    const initialParams = {
        boardid: "notice",
        offset: 1
    };
    const {
        boardList,
        ref,
        topData,
    } = useBoardList("notice", initialParams);
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleDetail = (seq) => {
        navigate(`/notice/${seq}`);
    }
    return (

        <div className="alert">
            <Header title="공지사항" handleBack={handleBackClick}/>
            <div className={`content ${backgroundType}`}>
                {!b_seq && (
                    <div className="default_margin">
                        {topData && (<div

                            key={topData.b_seq} // 고유한 b_seq를 key로 사용
                            className="alert_item"
                            onClick={() => handleDetail(topData.b_seq)}
                        >
                            <div className="alert_content">

                                [공지] <span
                                dangerouslySetInnerHTML={{__html: dompurify.sanitize(topData.subject)}}></span>
                            </div>
                            <div className="alert_time">
                                {dayjs(topData.date).format('YYYY.MM.DD')}
                            </div>
                        </div>)}
                        {boardList.map((notice, idx) => (
                            <div
                                key={notice.b_seq} // 고유한 b_seq를 key로 사용
                                className="alert_item"
                                onClick={() => handleDetail(notice.b_seq)}
                            >
                                <div
                                    className="alert_content"
                                    dangerouslySetInnerHTML={{__html: dompurify.sanitize(notice.subject)}}
                                ></div>
                                <div className="alert_time">
                                    {dayjs(notice.date).format('YYYY.MM.DD')}
                                </div>
                            </div>

                        ))}
                        <div ref={ref}/>
                    </div>
                )}


                <Outlet/>
            </div>
        </div>
    );
}

export default NoticeList;
