import axios from 'axios';
import useUserStore from '../store/user/userStore';
import {toast} from "react-toastify";
import {getRemainingTime} from "../utils/token";

export const instance = axios.create({
    baseURL: 'https://admin.iqvitamininside.com',
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

// 요청 인터셉터 설정
instance.interceptors.request.use((config) => {
    const {memberTokens, logout, userInfo, refreshMemberToken} = useUserStore.getState(); // store에서 토큰값 가져옴

    // 토큰이 없을 경우 localStorage에서 확인
    const token = memberTokens || localStorage.getItem('accessToken');

    if (memberTokens) {
        const remainingTime = getRemainingTime(userInfo);

        // 토큰 만료
        if (remainingTime <= 0) {
            logout();
            toast('로그인이 필요합니다.')
            window.location.href = '/login';
        }
        // 7일 기준 토큰 갱신
        if (userInfo.keepLoginChk === 'Y') {
            const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000
            if (remainingTime < sevenDaysInMs) {
                refreshMemberToken()
            }
        }

    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 응답 인터셉터 추가
instance.interceptors.response.use(
    (response) => {
//토큰 없음
        if (response.data.code === -300) {
            toast('로그인이 필요합니다.')
            window.location.href = '/login';
        }
// 토큰 만료
        if (response.data.code === 301) {
            toast('로그인이 필요합니다.')
            window.location.href = '/login';
        }
        return response;
    },
    (error) => {
        toast('페이지를 새로 고침하거나 잠시 후에 다시 시도해 주세요.')
        return Promise.reject(error);
    }
);

export default instance;