import React, { useRef, useEffect, useState } from "react";
import '../../styles/pages/report.css';
import Chart from 'chart.js/auto';
import instance from "../../services/services";
import { INSPECTION_REPORT_URL } from "../../utils/urls";
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function Report({ reportData }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [reportResult, setReportResult] = useState(null);

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await instance.post(INSPECTION_REPORT_URL, {
                    report_seq: reportData.report_seq
                });
                
                if (response.data.code === 200) {
                    setReportResult(response.data.data);
                    console.log("리포트 데이터:", response.data.data);
                }
            } catch (error) {
                console.error("리포트 데이터 조회 중 에러 발생:", error);
            }
        };

        if (reportData?.report_seq) {
            fetchReportData();
        }
    }, [reportData]);

    // 차트 데이터 정의
    const data = {
        labels: reportResult?.inspection_result?.map(item => item.area_name) || [],
        datasets: [
            {
                data: reportResult?.inspection_result?.map(item => item.score_percent) || [],
                backgroundColor: ["#FF5272", "#1FBCAA", "#FFD444", "#6093FF"],
                borderWidth: 0,
                borderRadius: Number.MAX_VALUE,
            },
        ],
    };
    const customPlugin = {
        id: 'moveBarsToFront',
        afterDatasetsDraw(chart) {
            chart.data.datasets.forEach((dataset, index) => {
                if (chart.isDatasetVisible(index)) {
                    const meta = chart.getDatasetMeta(index);
                    if (meta && meta.dataset && typeof meta.dataset.draw === 'function') {
                        // 데이터셋의 draw 메서드를 호출
                        meta.dataset.draw(chart.ctx);
                    }
                    meta.data.forEach(element => {
                        if (element && typeof element.draw === 'function') {
                            // 각 요소의 draw 메서드를 호출
                            element.draw(chart.ctx);
                        }
                    });
                }
            });
        }
    };
    // Chart.js 구성 객체
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const config = {
        type: "bar",
        data: data,
        options: {
            devicePixelRatio: 2, //해상도
            indexAxis: "y", // 가로 막대 그래프
            responsive: true, // 크기 자동 조정
            maintainAspectRatio: false, // 비율 유지하지 않음
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                    text: "유 · 아동 교조증 행동 검사 결과",
                },
                annotation: {
                    annotations: [
                        {
                            type: 'box',
                            xMin: 0, // x축 최소값
                            xMax: 31, // 첫 구간의 최대값
                            backgroundColor: '#ffffff',
                            borderWidth: 0,
                            label: {
                                content: '',
                                enabled: true,
                                position: 'center',
                            }
                        },
                        {
                            type: 'box',
                            xMin: 31, // x축 최소값
                            xMax: 53, // 첫 구간의 최대값
                            backgroundColor: '#E2FCEE',
                            borderWidth: 0,
                            label: {
                                content: '낮은 수준 (31~52)',
                                enabled: true,
                                position: 'center',
                            }
                        },
                        {
                            type: 'box',
                            xMin: 53, // 두 번째 구간 시작
                            xMax: 81, // 두 번째 구간 끝
                            backgroundColor: '#FDFDDB',
                            borderWidth: 0,
                            label: {
                                content: '보통 수준 (53~80)',
                                enabled: true,
                                position: 'center',
                            }
                        },
                        {
                            type: 'box',
                            xMin: 81, // 세 번째 구간 시작
                            xMax: 100, // 세 번째 구간 끝
                            backgroundColor: '#FFEBEB', // 빨간색
                            borderWidth: 0,
                            label: {
                                content: '높은 수준 (81~100)',
                                enabled: true,
                                position: 'center',
                            }
                        },
                    ],
                }
            },
            scales: {
                x: {
                    min: 0,
                    max: 100,
                    stepSize: 10,
                    title: {
                        display: false,
                        text: "점수",
                    },
                    ticks: {
                        stepSize: 10,
                        beginAtZero: true,
                    },
                },
                y: {
                    display: false,
                },
            },
        },plugins: [customPlugin]
    };

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        chartInstance.current = new Chart(ctx, config);

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [config]);
    const reportLegendStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };    
    return (
        <div className="view-report">
            <div className="report-content">
                <div className="report-wrapper">
                    <div className="report-title">
                        <div className="title-icon1"></div>동화 결과<div className="title-icon2"></div>
                    </div>
                    <div className="report-result">
                        <div className="report-result-title">
                            {reportResult?.inspection_info?.inspection_title || "검사 결과"}
                        </div>
                        <div className="report-graph">
                            <canvas ref={chartRef} className="chart-canvas" width="400" height="120"></canvas>
                        </div>
                        <div className="report-legend" style={reportLegendStyle}>
                            {data.datasets[0].backgroundColor.map((color, index) => (
                                <div key={index} className="legend-item">
                                    <div
                                        className="legend-color-box"
                                        style={{ backgroundColor: color }}
                                    ></div>
                                    <div className="legend-label">{data.labels[index]}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="report-class">
                        31~52 낮은수준 / 53~80 보통 수준 / 81~100 높은 수준
                    </div>
                    <div className="report-table">
                        <table>
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>원점수</th>
                                    <th>백분율(%)</th>
                                    <th>수준</th>
                                    <th>종합 해설</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportResult?.inspection_result?.map((result, index) => (
                                    <tr key={index}>
                                        <td>{result.area_name}</td>
                                        <td>{result.total_score}</td>
                                        <td>{result.score_percent}</td>
                                        <td>{result.inspection_status}</td>
                                        <td>
                                            {result.summary && <>{result.summary}<br/><br/></>}
                                            {result.summary_detail}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Report;