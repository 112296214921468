import Header from "../../components/Header/Header";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import useUserStore from '../../store/user/userStore';
import {toast} from "react-toastify";
import {updateNickname} from "../../services/Information/information";
import useMyInfo from "../../hooks/common/useMyInfo";

function Setting() {
    const navigate = useNavigate();
    const {memberTokens, logout} = useUserStore.getState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const {myInfo} = useMyInfo();
    const [isPush, setPush] = useState('')
    useEffect(() => {
        if (myInfo.push) {
            setPush(myInfo.push);
        }
    }, [myInfo.push]);
    const handleBackClick = () => {
        navigate(-1);
    };
    const handleLogout = () => {
        logout();
        navigate('/');
        toast('로그아웃되었습니다.')

    };

    const handlePush = async (e) => {

        try {
            const newValue=  e.target.checked ? 'Y' : 'N'
            setPush(newValue)
            const updatedUserInfo = {
                push: newValue,
                nickname: myInfo.nickname,
            };
            const response = await updateNickname(updatedUserInfo);
            if (response.data.code === 200) {

                toast(`${newValue==='Y' ? '알림을 설정하셨습니다.' : '알림을 해제하셨습니다.'}`);

            }
        } catch (error) {
            console.error(error.message || '닉네임 변경에 실패했습니다.');
        }
    }

    useEffect(() => {
        setIsLoggedIn(!!memberTokens)
    }, [memberTokens]);


    return (
        <div className="setting_container">
            <div className="content">
                <Header title="앱 설정" handleBack={handleBackClick}/>

                <div className="setting_content_body inner_btm">
                    {isLoggedIn &&
                        <div className="setting_box">
                            <p className="setting_title">일반 알림 설정</p>
                            <ul className="setting_list">
                                <li className="setting_item">
                                    <p className="setting_label">일반 알림 설정</p>
                                    <label className="toggle_switch">
                                        <input checked={isPush === 'Y'} type="checkbox" onChange={handlePush}/>
                                        <span className="slider"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>}

                    <div className="setting_box">
                        <p className="setting_title">서비스 안내</p>
                        <ul className="setting_list">
                            <li className="setting_item">
                                <Link className="setting_label" to="/terms">이용약관</Link>
                            </li>
                            <li className="setting_item">
                                <Link className="setting_label" to="/privacy-policy">개인정보처리방침</Link>
                            </li>
                            <li className="setting_item">
                                <p className="setting_label">버전정보</p>
                                <p className="app_ver">V6.0.0</p>
                            </li>
                        </ul>
                    </div>
                    {isLoggedIn &&
                        <div className="setting_box">
                            <p className="setting_title">계정관리</p>
                            <ul className="setting_list">
                                <li className="setting_item">
                                    <button
                                        className="setting_label"
                                        type="button"
                                        onClick={handleLogout}
                                    >
                                        로그아웃
                                    </button>
                                </li>

                                <li className="setting_item">
                                    <Link className="setting_label" to="/withdrawal">
                                        회원 탈퇴
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Setting;