import React from "react";
import Button from "../../components/Button/Button";
import Fail from "../../assets/img/Fail_img.png";
import {useLocation, useNavigate} from "react-router-dom";


function IdFindFail(){
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const handleFailClick = () => {
        navigate('/join');
    }

    const handleFindClick = () => {
        if(queryParams.get('type') ==='password'){
            navigate('/passwordfind')
        }else{
            navigate('/idfind')
        }
    }
    return(
        <div className="passwordsuccess">
            <div className="content">
                <div className="success_body">
                    <div className="success_message" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        <div className="fail_image_container" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <img src={Fail} className="fail_img" alt="이미지"/>
                        </div>
                        <div className="registration_message">
                            <div className="success_text">입력하신 정보로</div>
                            <div className="success_text">등록된 회원이 없어요</div>
                            <div className="safety_message" style={{paddingTop:'8px'}}>정보를 다시 확인하고 시도해 주세요.</div>
                        </div>
                    </div>
                </div>

                <div className="error_btn_change">
                        <Button
                            label="가입하러 가기"
                            variant="btn_join"
                            onClick={handleFailClick} />
                        <Button
                            label="다시 찾아볼래요"
                            variant="btn_complete"
                            onClick={handleFindClick} />
                </div>

            </div>
        </div>
    )
}

export default IdFindFail;