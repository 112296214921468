import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import OutPop from "../../components/Popup/OutPop";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Ellipse from "../../assets/img/Ellipse 100.png";
import { SINGLE_TEST_URL, INSPECTION_INFO_URL, PAYCANCEL_URL } from "../../utils/urls";
import { instance } from "../../services/services";
import RefundPopup from '../../components/Popup/RefundPopup';
import {usePaymentComplete} from "../../hooks/payment/usePaymentComplete";

function StoryInformation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [selectedGender, setSelectedGender] = useState("female");
    const [age, setAge] = useState("");
    const [name, setName] = useState("");
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [testData, setTestData] = useState(null);
    const [itemOptionSeq, setItemOptionSeq] = useState(null);
    const [isRefundPopOpen, setIsRefundPopOpen] = useState(false);
    const { orderId } = usePaymentComplete();

    usePaymentComplete() // 결제 승인 api


    // 기존 데이터 조회
    useEffect(() => {
        const fetchTestData = async () => {
            try {
                // location.state와 searchParams에서 데이터 추출
                const stateGoodsSeq = location.state?.goods_seq;
                const paramGoodsSeq = searchParams.get('goods_seq');
                const goodsSeq = stateGoodsSeq || paramGoodsSeq;
                
                if (!goodsSeq) {
                    console.error('상품 정보가 없습니다');
                    return;
                }

                const response = await instance.post(SINGLE_TEST_URL, {
                    goods_seq: goodsSeq
                });

                if (response.data.code === 200) {
                    setTestData(response.data.data);
                } else {
                    console.error('검사 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('검사 데이터 조회 오류:', error);
            }
        };

        // location.state나 searchParams 중 하나라도 있으면 API 호출
        if (location.state?.goods_seq || searchParams.get('goods_seq')) {
            fetchTestData();
        }
    }, [location.state, searchParams]);

    // INSPECTION_INFO_URL API 호출을 위한 useEffect 추가
    useEffect(() => {
        const fetchInspectionInfo = async () => {
            try {
                const goodsSeq = location.state?.goods_seq || searchParams.get('goods_seq');
                const orderSeq = location.state?.order_seq || searchParams.get('orderId');

                if (goodsSeq && orderSeq) {
                    const response = await instance.post(INSPECTION_INFO_URL, {
                        goods_seq: goodsSeq,
                        order_seq: orderSeq
                    });

                    if (response.data.code === 200) {
                        setItemOptionSeq(response.data.data.item_option_seq);
                    } else {
                        console.error('검사 정보 조회 실패:', response.data.message);
                    }
                }
            } catch (error) {
                console.error('검사 정보 조회 오류:', error);
            }
        };

        fetchInspectionInfo();
    }, [location.state, searchParams]);

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleGenderChange = (gender) => {
        setSelectedGender(gender);
    };

    const handleVideoClick = () => {
        const goodsSeq = location.state?.goods_seq || searchParams.get('goods_seq');
        const orderSeq = location.state?.order_seq || searchParams.get('orderId');
        
        const testInfo = {
            ...testData,
            goods_seq: goodsSeq,
            orderId: orderSeq,
            age: age,
            gender: selectedGender === "female" ? "female" : "male",
            name: name,
            item_option_seq: itemOptionSeq
        };
        
        navigate('/videoplayer', { 
            state: { 
                Data: testInfo 
            } 
        });
    };

    const handleComplete = () => {
        navigate('/storycomplete')
    }

    const isButtonEnabled = age.trim() !== "" && name.trim() !== "";

    const handleRefundClick = () => {
        setIsRefundPopOpen(true);
    };

    const handleRefund = async () => {
        const orderSeq = location.state?.order_seq || orderId;
        
        try {
            const response = await instance.post(PAYCANCEL_URL, {
                order_seq: orderSeq,
                item_option_seq: itemOptionSeq
            });

            // 서버 응답의 메시지를 표시
            alert(response.data.message || '환불 처리 중 오류가 발생했습니다.');
            
            // 성공한 경우에만 메인 페이지로 이동
            if (response.data.code === 200) {
                navigate('/');
            }
            
            // 팝업 닫기
            setIsRefundPopOpen(false);
            
        } catch (error) {
            console.error('환불 처리 오류:', error);
            alert(error.response?.data?.message || '환불 처리 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className="ChildrenInformation">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick}/>
                <div className="info_wrapper">
                    <Badge badgeColor="primary-60" badgeText="동화" />
                    <div className="intro_payment_group">{testData?.goods_name}</div>
                </div>
                <div className="intro_body" style={{padding:"0px 24px 24px 24px"}}>
                    <div className="assessment_section" style={{paddingTop:"16px", borderBottom:"0px"}}>
                        <div className="payment_info">
                            <div className="payment_title_container">
                                <div className="payment_title">{testData?.story_title}</div>
                                <div className="assessment_subtitle" style={{paddingTop:"4px"}}>{testData?.story_title}</div>
                                <div className="call_type_details" style={{paddingTop:"6px"}}>
                                    <span className="emotion_behavior">{testData?.story_title_sub}</span>
                                    <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                                    <span className="k_cast">{testData?.inspection_abbr}</span>
                                </div>
                            </div>
                            <div className="payment_image_container">
                                <img src={testData?.image_url} alt="Payment_img" className="payment_img" />
                            </div>
                        </div>
                        <div className="payment_content">{testData?.goods_detail}</div>
                    </div>
                        <div className="refund_btn" onClick={handleRefundClick}>환불 진행하기</div>
                </div>

                <div className="line_height"/>

                <div className="content_body">
                    <div className="age_input_section">
                        <div className="input_label">나이 입력</div>
                        <input className="age_input" type="text"
                            placeholder="만 나이로 기재해 주세요"
                            value={age}
                            onChange={(e) => setAge(e.target.value)} />
                    </div>

                    <div className="gender_input_section">
                        <div className="input_label">성별 입력</div>
                        <div className="gender_selection">
                        <div
                            className={`gender_option ${selectedGender === "female" ? "selected" : ""}`}
                            onClick={() => handleGenderChange("female")}
                        >
                            여자
                        </div>
                        <div
                            className={`gender_option ${selectedGender === "male" ? "selected" : ""}`}
                            onClick={() => handleGenderChange("male")}
                        >
                            남자
                        </div>
                        </div>
                    </div>

                    <div className="name_input_section">
                        <div className="input_label">이름 입력</div>
                        <input className="name_input"
                                type="text"   
                                placeholder="이름을 기재해 주세요"
                                value={name}
                                onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="refund_info_container">
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text">재검사 고객의 경우 환불이 불가합니다.</div>
                        </div>
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text" onClick={handleComplete}>동화 리포트는 3회까지만 제공됩니다.</div>
                        </div>
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button  
                        label="검사 시작하기" 
                        variant="btn_change" 
                        className={isButtonEnabled ? "active" : "disabled"}
                        onClick={handleVideoClick}
                        disabled={!testData || !isButtonEnabled}
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <RefundPopup
                    isOpen={isRefundPopOpen}
                    onClose={() => setIsRefundPopOpen(false)}
                    onYesClick={handleRefund}
                />
            </div>
        </div>
    );
}

export default StoryInformation;
