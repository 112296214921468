

const PostActionPopup = ({isOpen,onClose,handleDelete}) => {
if(!isOpen)return null
    return (
        <>

            <div className="dimmed"></div>

            <div className="action_popup">
                <div className="action_list">
                    <button onClick={handleDelete} className="action_button action_delete" type="button">삭제하기</button>
                    <button onClick={onClose} className="action_button action_cancel" type="button">취소</button>
                </div>

            </div>

        </>
    )
}


export default PostActionPopup