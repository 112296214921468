import React, {useEffect, useMemo, useState} from "react";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import ShoppingPop from "../../components/Popup/ShoppingPop";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {SINGLE_TEST_URL, CART_URL} from "../../utils/urls";
import instance from "../../services/services";
import useUserStore from '../../store/user/userStore';
import { TOSS_PAYMENT_URL } from "../../utils/urls";
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { formatLocalString } from "../../utils/validation";


function SeriesSinglePayment() {
    const { memberTokens } = useUserStore();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [searchParams] = useSearchParams();
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [singleData, setSingleData] = useState(null);

    const goodsSeq = location.state?.goodsSeq || searchParams.get('goods_seq');

    useEffect(() => {
        const fetchSingleData = async () => {
            try {
                const errorMessage = searchParams.get('message');
                const errorCode = searchParams.get('code');
                
                if (errorMessage && errorCode) {
                    console.log('결제 실패:', errorMessage);
                }

                if (!goodsSeq) {
                    console.error('상품 정보가 없습니다');
                    return;
                }

                const response = await instance.post(SINGLE_TEST_URL, {
                    goods_seq: goodsSeq
                });

                if (response.data.code === 200) {
                    setSingleData(response.data.data);
                } else {
                    console.error('단일 상품 데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('단일 상품 데이터 조회 오류:', error);
            }
        };

        fetchSingleData();
    }, [goodsSeq, searchParams]);

    const handleShoppingClick = async () => {
        try {
            if (!memberTokens) {
                console.error('토큰이 없습니다');
                navigate('/login');
                return;
            }

            const cartData = {
                "goods_seq": String(goodsSeq),
                "ea": "1"
            };

            const response = await instance.post(CART_URL, cartData, {
                headers: {
                    'Authorization': `Bearer ${memberTokens}` // 헤더에 토큰값 포함시켜야함
                }
            });

            if (response.data.code === 200) {
                setIsOutPopOpen(true);
            } else {
                console.error('장바구니 담기 실패:', response.data.message);
            }
        } catch (error) {
            console.error('장바구니 API 호출 오류:', error);
        }
    };

    const handleBackClick = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    }

    const handleDirectPurchase = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken') || memberTokens;
            
            if (!storedToken || !singleData) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login');
                return;
            }

            const paymentRequestBody = {
                member_seq: "4",
                settlePrice: Number(singleData.price),
                order_kind: "story",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: singleData.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: singleData.goods_name,
                    inspection_title: singleData.inspection_title,
                    original_price: singleData.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody);
            
            if (response.data.code === 200) {
                const paymentInfo = response.data.data.data;

                try {
                    const clientKey = process.env.NODE_ENV === 'development' 
                        ? 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'  // 테스트 클라이언트 키
                        : process.env.REACT_APP_TOSS_CLIENT_KEY;   // 실제 클라이언트 키

                    const tossPayments = await loadTossPayments(clientKey);
                    await tossPayments.requestPayment('카드', {
                        amount: paymentInfo.amount,
                        orderId: paymentInfo.orderId,
                        orderName: paymentInfo.orderName,
                        customerName: paymentInfo.customerName,
                        successUrl: process.env.NODE_ENV === 'development'
                            ? `http://localhost:3000/storyinformation?goods_seq=${singleData.goods_seq}`
                            : `https://www.iqvitamininside.com/storyinformation?goods_seq=${singleData.goods_seq}`,
                        failUrl: process.env.NODE_ENV === 'development'
                            ? `http://localhost:3000/seriesSinglepayment?goods_seq=${singleData.goods_seq}`
                            : `https://www.iqvitamininside.com/seriesSinglepayment?goods_seq=${singleData.goods_seq}`,
                    });
                } catch (tossError) {
                    console.error('토스 결제창 호출 실패:', tossError);
                    window.location.href = process.env.NODE_ENV === 'development'
                        ? `http://localhost:3000/seriesSinglepayment?goods_seq=${singleData.goods_seq}`
                        : `/seriesSinglepayment?goods_seq=${singleData.goods_seq}`;
                }
            } else {
                alert(response.data.message || '결제 요청이 실패했습니다.');
                window.location.href = process.env.NODE_ENV === 'development'
                    ? `http://localhost:3000/seriesSinglepayment?goods_seq=${singleData.goods_seq}`
                    : `/seriesSinglepayment?goods_seq=${singleData.goods_seq}`;
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className="childrenDetail">
            <div className="content">
                <Header title="동화 검사" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-60" badgeText="동화" />
                        <div className="intro_payment_group">{singleData?.goods_name}</div>
                    </div>

                    <div className="assessment_section" style={{padding:"16px 0px"}}>
                        <div className="payment_info">
                            <div className="payment_title_container">
                                <div className="payment_title">{singleData?.story_title}</div>
                                <div className="assessment_subtitle" style={{paddingTop:"4px"}}>{singleData?.inspection_title}</div>
                                <div className="call_type_details" style={{paddingTop:"6px"}}>
                                    <span className="emotion_behavior">{singleData?.story_title_sub}</span>
                                    <span className="vertical-bar" style={{ background: "#B4B5B9" }}></span>
                                    <span className="k_cast">{singleData?.inspection_abbr}</span>
                                </div>
                            </div>
                            <div className="payment_image_container">
                                <img src={singleData?.image_url} alt="Payment_img" className="payment_img" />
                            </div>
                        </div>
                        <div className="payment_content">{singleData?.goods_detail}</div>
                    </div>

                    <div className="price_info">
                        <div className="price_label">가격정보</div>
                        <div className="price_value"> {singleData?.price ? `${formatLocalString(singleData.price)}원` : '0원'}</div>
                    </div>
                </div>

                <div className="Edit_btn_complete">
                    <Button  
                        label="장바구니 담기" 
                        variant="btn_join" 
                        onClick={handleShoppingClick}
                    />
                    <Button  
                        label="바로 구매하기" 
                        variant="btn_complete" 
                        onClick={handleDirectPurchase}
                        disabled={!singleData}
                    />
                </div>
                <ShoppingPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                />
            </div>
        </div>
    );
}

export default SeriesSinglePayment;
