import {useCallback, useEffect, useState} from "react";
import {getMyInfo} from "../../services/MyPage/mypage";
import {toast} from "react-toastify";
import useUserStore from "../../store/user/userStore";

const useMyInfo = () => {
    const {memberTokens} = useUserStore();
    const [ myInfo , setMyInfo] = useState({})

    const getMyInfoData = useCallback(async () => {
        try {
            const response = await getMyInfo();
            if (response.data.code === 200) {
                console.log(response.data.data,'response.data.data')
                setMyInfo(response.data.data); // myInfo 상태 업데이트
            } else {
                toast(response.data.message); // 실패 메시지 알림
            }
        } catch (e) {
            console.error("마이페이지 정보 가져오기 오류:", e);
        }
    }, [setMyInfo]);

    useEffect(() => {
        if (memberTokens) {
            getMyInfoData();
        }
    }, [memberTokens, getMyInfoData]);

    return {myInfo};
};

export default useMyInfo;
