import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {KAKAO_CLIENT_ID, NAVER_CLIENT_ID} from "../../utils/urls";
import axios from "axios";
import {decodeToken} from "../../utils/token";
import {toast} from "react-toastify";
import {loginAppleUser, loginKakaoUser, loginNaverUser} from "../../services/Join/Join";
import useUserStore from "../../store/user/userStore";

const {naver} = window;

export const useSnsUserLogin = () => {
    const {updateTokens, updateUserInfo} = useUserStore()
    const navigate = useNavigate();
    const loginSnsUser = useCallback(async (type, params) => {

        const loginData = {
            ...(type === 'naver' ? {naver_access_token: params.idToken} : {id_token: params.idToken})
        };
        try {
            let response;
            switch (type) {
                case 'naver':
                    response = await loginNaverUser(loginData);
                    break;
                case 'kakao':
                    response = await loginKakaoUser(loginData);
                    break;
                case 'apple':
                    response = await loginAppleUser(loginData);
                    break;
                default:
                    console.warn(`Unsupported SNS type: ${type}`);
                    return;
            }

            if (response.data.code === 200) {
                updateTokens(response.data.data.access_token);
                updateUserInfo(decodeToken(response.data.data.access_token))
                toast('로그인 되었습니다.')
                navigate('/')
            } else if(response.data.code === 400){
                // 회원이 아닌경우
                toast(response.data.message)
                navigate('/join')
            }
        } catch (error) {

        }
    }, [updateTokens, navigate, updateUserInfo]); // 의존성 배열에 convertedData를 포함시켜야 함

    return {loginSnsUser};

}
export const useNaverLogin = (redirectUri) => {
    const location = useLocation();
    const [snsInfo, setSnsInfo] = useState(null);

    const naverLogin = useMemo(() => {
        return new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: redirectUri,
            loginButton: {color: "green", type: 3, height: "48"},
        });
    }, [redirectUri]);

    useEffect(() => {
        if (redirectUri && location.pathname === "/login") { // '/login' 페이지에서만 실행
            naverLogin.init();
        }
    }, [naverLogin, redirectUri, location.pathname]);
    const handleNaverLogin = () => {
        naverLogin.authorize();
    };
    useEffect(() => {
        const params = new URLSearchParams(location.hash.replace("#", "") || location.search);

        if (params.has("access_token")) {
            naverLogin.getLoginStatus((status) => {
                if (status) {
                    const snsUser = {
                        fromSns: true,
                        provider: 'naver',
                        snsUserInfo: {
                            id: '',
                            email: naverLogin.user.email,
                            name: naverLogin.user.name,
                            nickname: naverLogin.user.nickname,
                            id_token: naverLogin.accessToken.accessToken,
                        }
                    };
                    setSnsInfo(snsUser);
                }
            });
        }
    }, [location.hash,location.search,location.key, naverLogin]);

    return {handleNaverLogin, snsInfo};
};

export const useKakaoLogin = (redirectUri) => {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const code = queryParams.get('code');
    const [snsInfo, setSnsInfo] = useState(null);
    const handleKakaoLogin = () => {
        window.location.href =
            `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=openid`; // 카카오 로그인 페이지로 리디렉션
    };

    const handelKakaoLoginToken = useCallback(async () => {
        try {
            // 1. 카카오 API에서 액세스 토큰과 ID 토큰 요청
            const tokenResponse = await axios.post(
                'https://kauth.kakao.com/oauth/token',
                new URLSearchParams({
                    grant_type: 'authorization_code',
                    client_id: KAKAO_CLIENT_ID,
                    redirect_uri: redirectUri,
                    code: code,
                    scope: 'profile_nickname profile_image account_email'
                })
            );

            console.log('카카오 토큰 응답:', tokenResponse.data);
            const {access_token, id_token} = tokenResponse.data;

            // 2. 사용자 정보 요청
            const userInfoResponse = await axios.get('https://kapi.kakao.com/v2/user/me', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            const userInfo = userInfoResponse.data;


            const snsUser = {
                fromSns: true,
                provider: 'kakao',
                snsUserInfo: {
                    id: userInfo.kakao_account?.email,
                    email: userInfo.kakao_account?.email,
                    name: userInfo.properties?.name || "",
                    nickname: userInfo.properties?.nickname || "",
                    id_token: id_token
                }
            };
            setSnsInfo(snsUser);
        } catch (error) {
            console.error('카카오 로그인 처리 중 오류 발생:', error);

        }
    }, [code, redirectUri])

    useEffect(() => {
        if (code) {
            handelKakaoLoginToken()
        }
    }, [code, handelKakaoLoginToken]);

    return {handleKakaoLogin, snsInfo}
}


export const useAppleLogin = (redirectUri) => {
    const [snsInfo, setSnsInfo] = useState(null);
    useEffect(() => {
        if (window.AppleID) {
            // signOut 함수 호출 제거하고 바로 초기화
            window.AppleID.auth.init({
                clientId: 'com.iqvitamininside.service',
                scope: 'name email',
                redirectURI: redirectUri,
                state: crypto.randomUUID(),
                nonce: crypto.randomUUID(),
                usePopup: true,
                responseType: 'code id_token',
            });
        }
    }, [redirectUri]);

    const handleAppleLogin = useCallback(async () => {
        try {
            if (!window.AppleID) {
                throw new Error('애플 로그인이 초기화되지 않았습니다.');
            }

            const data = await window.AppleID.auth.signIn();
            console.log('애플 로그인 응답:', data);

            const idToken = data?.authorization?.id_token;
            let decoded = {};

            if (idToken) {
                decoded = await decodeToken(idToken);
                console.log('디코딩된 데이터:', decoded);

                const snsUser = {
                    fromSns: true,
                    provider: 'apple',
                    snsUserInfo: {
                        id: "",
                        email: decoded?.email,
                        name: decoded?.name || '',
                        nickname: "",
                        id_token: idToken
                    }
                };
                console.log('설정할 snsInfo:', snsUser); // snsUser 설정 시점 확인
                setSnsInfo(snsUser);
            }
        } catch (error) {
            console.error('Apple Login Failed:', error);

            if (error.error === 'popup_closed_by_user') {
                toast('사용자가 로그인을 취소했습니다.');
            }
        }
    }, []);

    return {snsInfo, handleAppleLogin}
}