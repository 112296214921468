import instance from "../services";


/**
 * 알림 리스트
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getAlarmList = async (params) => {
    try {
        return await instance.post('/api/alarm/list', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 알림 개수
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getAlarmCount = async () => {
    try {
        return await instance.post('/api/alarm/count');
    } catch (error) {
        console.error(error);
        throw error;
    }
}