import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

const StyledTextList = styled(Swiper)`


    height: 56px;
    padding: 0 24px;

`;

const StyledTextTab = styled(SwiperSlide)`

    color: ${({$isActiveTab}) => ($isActiveTab ? "var(--neutral-black)" : "var(--neutral-300)")};
    font: var(--p1);
    letter-spacing: var(--p1-letter-spacing);
    line-height: 56px;
    width: auto;

`;

const TextTab = ({tabList, activeValue, onTabClick}) => {
    const handleActiveTab = (item) => {
        onTabClick(item); // 부모로 탭 값 전달
    };

    return (

        <StyledTextList
            spaceBetween={12}
            slidesPerView={'auto'}
        >
            {tabList.map((item, index) => (
                <StyledTextTab
                    onClick={() => handleActiveTab(item)}
                    key={index}
                    $isActiveTab={item.value === activeValue}
                >
                    {item.name}
                </StyledTextTab>
            ))}
        </StyledTextList>



    );
};

export default TextTab;
