import React, {useState} from 'react';
import Tab from '../../components/Tab/Tab';
import Icon_story_test from '../../assets/img/ic_Book_open.png';
import Icon_Mind from '../../assets/img/Tab_Frame.png';
import Icon_home from '../../assets/img/ic_home.png';
import Icon_menu from '../../assets/icon/Sort.png';
import Counsel_img from '../../assets/img/counsel_img.png';
import {Link} from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import AlarmButton from "../../components/Button/AlarmButton";

// 재사용 가능한 정보 컴포넌트
const InfoContainer = ({ title,highlight, description, details }) => (
    <div className='info_container' >
        <div className='info_container_title'>
            <span className='content_label'>{highlight}</span> {title}
        </div>
        <div className='intro_description' style={{marginTop:"10px"}}>{description}<br/><br/>{details}</div>
    </div>
);

function MindPrescription() {
    const [backgroundType] = useState("bg-white");
    const [isMenuOpen, setIsMenuOpen] = useState(false); 

    const tabsData = [
        { icon: Icon_story_test, label: '동화검사' },
        { icon: Icon_home, label: '홈' },
        { icon: Icon_Mind, label: '' },
    ];

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev); // 메뉴 상태 토글
    };

    const alertIcons = (
        <div className='title_icon'>
            <AlarmButton/>

            <img className='icon menu_icon' onClick={toggleMenu} style={{ cursor:'pointer'}} src={Icon_menu} alt="메뉴 아이콘" />
        </div>
    );


    return (
        <div className='MindPrescription'>
            <div className={`content ${backgroundType}`}>
                <div className="main_title">
                    {/* TODO 신청하기 페이지로 이동  */}
                    <Link to="/" className="counsel_title" style={{textDecoration: "none"}}>상담을 신청해 보세요!</Link>
                    {alertIcons}
                    <NavBar isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}/>

                </div>

                <div className='content_body'>
                    <div className='image_container'>
                        <img className='image_size' src={Counsel_img} alt="상담 이미지" />
                    </div>

                    <div className='info_container'>
                        <div className='intro_title'>
                            KIM DONG CHUL<br/>PSYCHOLOGY CARE<br/>RESEARCH INS.
                        </div>
                        <div className='intro_description'>
                            김동철 심리케어 연구소는 인본주의를 바탕으로 하여 심리 콘텐츠를 개발하고 실현해 나가는 데 그 목적을 둔 R&D 센터입니다.
                            <br/>
                            <br/>
                            인간의 내면에만 초점을 맞추었던 과거의 심리학 이론에서 벗어나 실생활에 활용 가능한 심리 콘텐츠를 확장 및 데이터화하여 바이오 심리 공학 콘텐츠를 지향하는 선도적인
                            연구를 진행합니다.
                        </div>

                    </div>

                    <InfoContainer
                        highlight="심리 콘텐츠"
                        title="개발 연구"
                        description="심리 콘텐츠를 접목한 미래 산업형 모델을 기획하고, 빅데이터화한 융복합형 심리 공학을 연구합니다." 
                        details="교육, 인문, 사회 공학 등의 분야와 심리학 이론을 결합한 새로운 미래 산업 콘텐츠를 기획 · 개발 · 연구하며, 이를 통하여 인간의 감각, 지각, 사고, 성격, 기능, 적성의 영역에서 삶의 질을 높여나가고, 건강한 몸, 맘, 뇌를 이루어 긍정적인 삶의 변화를 이끌어갈 수 있는 주체자로 성장할 수 있게 합니다." 
                    />

                    <InfoContainer
                        highlight="심리 검사"
                        title="개발"
                        description="기존의 심리학 이론과 현장에서의 이론 접목을 기반으로 연령, 발달 및 증상별로 분류하고, 임상에 따른 각 대상의 발달 문화적 속성을 분석 및 연구합니다." 
                        details="공간지각이론과 아동 심리 특화 이론을 기반으로 하며, 다감각적인 요소의 발달 이론을 활용하여 신뢰도와 타당성을 검증한 심리 검사를 개발합니다." 
                    />

                    <Link to="/my-inquiry?type=counsel">
                        <div className='submit_button_container'>
                            <button className='submit_button'>상담 신청하기</button>
                        </div>
                    </Link>
                </div>
            </div>
            <Tab tabs={tabsData} />
        </div>
    );
}

export default MindPrescription;
