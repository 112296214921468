import {useCallback, useState} from "react";
import {deleteInquiryList} from "../../services/Board/board";
import {toast} from "react-toastify";


export const useBoardDelete = () => {
    // 삭제
    const [b_seq , setB_seq] = useState('')
    const deleteBoardPost = useCallback(async () => {
        try {
            const response = await deleteInquiryList({b_seq})
            if (response.data.code === 200) {
                toast("삭제되었습니다.");
            } else {
                toast(response.data.message);
            }
        } catch (e) {
            console.error(e);
            toast("등록에 실패했습니다. 다시 시도해주세요.");
        }
    }, [b_seq])
    return {deleteBoardPost,setB_seq,b_seq}
}