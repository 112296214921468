import React, {useEffect, useMemo, useState} from "react";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import Badge from "../../../components/Badge/Badge";
import {formatLocalString} from "../../../utils/validation";
import {useLocation, useNavigate} from "react-router-dom";
import {GENERAL_GOODS_URL, TOSS_PAYMENT_URL} from "../../../utils/urls";
import instance from "../../../services/services";
import {loadTossPayments} from '@tosspayments/payment-sdk';
import useUserStore from "../../../store/user/userStore";

function ChildrenPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const {memberTokens} = useUserStore();
    const [data, setData] = useState(null);
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const goodsSeq = location.state?.goods_seq; // goods_seq를 가져옵니다.


    const handleBack = () => {
        if (queryParams.get('code') === 'PAY_PROCESS_CANCELED') {
            navigate('/');
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await instance.post(GENERAL_GOODS_URL, {
                    goods_seq: goodsSeq || queryParams.get('goods_seq')  // goods_seq를 사용하여 데이터 요청
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error('데이터 조회 실패:', response.data.message);
                }
            } catch (error) {
                console.error('API 요청 오류:', error);
            }
        };

        fetchData();
    }, [goodsSeq, queryParams]);

    const handlePayment = async () => {
        try {
            const storedToken = localStorage.getItem('accessToken') || memberTokens;
            if (!storedToken || !data) {
                console.error('토큰 또는 상품 정보가 없습니다');
                navigate('/login');
                return;
            }

            const paymentRequestBody = {
                member_seq: "4", // 회원 번호
                settlePrice: Number(data.price), // 결제 금액
                order_kind: "general",
                use_point: null,
                mode: "choice",
                payment: "card",
                items: [
                    {
                        goods_seq: data.goods_seq,
                        option_seq: "",
                        ea: "1"
                    }
                ],
                validation_data: {
                    goods_name: data.goods_name,
                    inspection_title: data.inspection_title,
                    original_price: data.price
                }
            };

            const response = await instance.post(TOSS_PAYMENT_URL, paymentRequestBody);

            if (response.data.code === 200) {
                const paymentInfo = response.data.data.data;

                try {
                    const clientKey = process.env.NODE_ENV === 'development'
                        ? 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'  // 테스트 클라이언트 키
                        : process.env.REACT_APP_TOSS_CLIENT_KEY;   // 실제 클라이언트 키

                    const tossPayments = await loadTossPayments(clientKey);
                    await tossPayments.requestPayment('카드', {
                        amount: paymentInfo.amount,
                        orderId: paymentInfo.orderId,
                        orderName: paymentInfo.orderName,
                        customerName: paymentInfo.customerName,
                        successUrl: process.env.NODE_ENV === 'development'
                            ? `http://localhost:3000/childrenInformation?goods_seq=${data.goods_seq}`
                            : `https://www.iqvitamininside.com/childrenInformation?goods_seq=${data.goods_seq}`,
                        failUrl: process.env.NODE_ENV === 'development'
                            ? `http://localhost:3000/childrenpayment?goods_seq=${data.goods_seq}`
                            : `https://www.iqvitamininside.com/childrenpayment?goods_seq=${data.goods_seq}`,
                    });
                } catch (tossError) {
                    console.error('토스 결제창 호출 실패:', tossError);
                    window.location.href = process.env.NODE_ENV === 'development'
                        ? `http://localhost:3000/childrenpayment?goods_seq=${data.goods_seq}`
                        : `/childrenpayment?goods_seq=${data.goods_seq}`;
                }
            } else {
                alert(response.data.message || '결제 요청이 실패했습니다.');
                window.location.href = process.env.NODE_ENV === 'development'
                    ? `http://localhost:3000/childrenpayment?goods_seq=${data.goods_seq}`
                    : `/childrenpayment?goods_seq=${data.goods_seq}`;
            }
        } catch (error) {
            console.error('결제 처리 중 에러:', error);
            alert('결제 처리 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
    };

    return (
        <div className="childrenDetail">
            <div className="content">
                <Header title="일반 검사" handleBack={handleBack}/>
                <div className="content_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-50" badgeText="일반"/>
                        <div className="intro_age_group">{data?.goods_name_title}</div>
                        <div className="intro_age_info">{data?.goods_name_sub}</div>
                    </div>

                    <div className="assessment_section">
                        <div className="assessment_info">
                            <div className="assessment_details">
                                <div className="assessment_title ellipsis1">{data?.inspection_title}</div>
                                <div className="assessment_meta group_line" style={{paddingTop: "6px", gap: "0px"}}>
                                    <div className="assessment_code group_item">{data?.inspection_abbr}</div>
                                    <div className="assessment_question_count group_item">{data?.qstCnt} 문항</div>
                                </div>
                            </div>
                            <div className="children_image_container">
                                <img className="Children_Img" src={data?.image_url} alt="Children_Img"/>
                            </div>
                        </div>
                    </div>

                    <div className="price_info">
                        <div className="price_label">가격정보</div>
                        <div className="price_value">
                            {data ? formatLocalString(data.price) : '0'}원
                        </div>
                    </div>
                </div>

                <div className="Edit_btn_change">
                    <Button
                        label="바로 구매하기"
                        variant="btn_complete"
                        onClick={handlePayment}
                    />
                </div>
            </div>
        </div>
    );
}

export default ChildrenPayment;
