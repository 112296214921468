import React from "react";
import Arrow from '../../assets/img/ic_arrow.png';
import Button from '../../components/Button/Button';
import {Link, useLocation} from "react-router-dom";
import Header from "../../components/Header/Header";
import useUserStore from "../../store/user/userStore";


function Edit() {
    const location = useLocation();  // useLocation 훅 사용
    const navBarEmail = location.state?.email;


    const { userInfo } = useUserStore(); // 사용자 정보


    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="edit">
            <div className="content">
                <Header title="내 정보 수정" handleBack={handleBackClick}/>
                <div className="content_body">

                    <div className="edit_section">
                        <div className="edit_label">닉네임</div>
                        <div className="edit_value">
                            <div className="edit_nickname">{userInfo?.nickname}</div>
                            <Link to="/nicknameedit">
                                <div className="arrow_icon"><img src={Arrow} alt="arrow"/></div>
                            </Link>
                        </div>
                    </div>
                    <div className="edit_section">
                        <div className="edit_label">아이디</div>
                        <div className="edit_value">{userInfo?.userid}</div>
                    </div>
                    <div className="edit_section">
                        <div className="edit_label">이메일</div>
                        <div className="edit_value">{navBarEmail}</div>
                    </div>
                    <Link to="/passwordchange">
                        <Button label="비밀번호 변경하기" variant="btn_edit"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Edit;