import React, {useState} from "react";
import Button from "../../../components/Button/Button";
import OutPop from "../../../components/Popup/OutPop";
import {useNavigate} from "react-router-dom";
import Complete_Img from "../../../assets/img/complete_img.png";

import ReportPop from "../../../components/Popup/ReportPop";
import RetestPop from "../../../components/Popup/RetestPop";
import Header from "../../../components/Header/Header";

function TestComplete() {
    const navigate = useNavigate();
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [isReportPopOpen, setIsReportPopOpen] = useState(false);
    const [isRetestPopOpen, setIsRetestPopOpen] = useState(false);

    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleReportClick = () => {
        setIsReportPopOpen(true); // ReportPop 열기
    };

    const handleRetestClick = () => {
        setIsRetestPopOpen(true); // RetestPop 열기
    };

    return (
        <div className="TestComplete">
            <div className="content">

                <Header title="일반 검사" handleBack={handleBackClick}/>

                <div className="content_body">
                    <div className="intro_section">

                        <div className="completion_message">심리 발달 탄력성(어린이)<br />검사를 완료했어요</div>
                    </div>
                    <div className="Complete_image_section">
                        <img className="Complete_Img" src={Complete_Img} alt="Complete" />
                    </div>
                    <ul className="completion_text_list marker_list">
                        <li className="completion_text_item">리포트 확인 전에는 무제한 재검사가 가능합니다.</li>
                        <li className="completion_text_item">리포트 확인 후에는 재검사가 불가합니다.</li>
                    </ul>
                </div>
                <div className="button_section">
                    <Button  
                        label="재검사하기" 
                        variant="btn_join" 
                        onClick={handleRetestClick} // 재검사하기 클릭 시 RetestPop 열기
                    />
                    <Button 
                        label="리포트 보기" 
                        variant="btn_complete" 
                        onClick={handleReportClick} // 리포트 보기 클릭 시 ReportPop 열기
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <ReportPop
                    isOpen={isReportPopOpen}
                    onClose={() => setIsReportPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <RetestPop
                    isOpen={isRetestPopOpen}
                    onClose={() => setIsRetestPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
            </div>
        </div>
    );
}

export default TestComplete;
