import {useCallback} from "react";
import {createInquiryList} from "../../services/Board/board";
import {toast} from "react-toastify";

export const useBoardWrite = (formData) => {
    // 작성
    const handleBoardWrite = useCallback(async () => {
        try {
            const response = await createInquiryList(formData);
            if (response.data.code === 200) {
                toast("등록되었습니다.");
            } else {
                toast(response.data.message);
            }
        } catch (e) {
            console.error(e);
            toast("등록에 실패했습니다. 다시 시도해주세요.");
        }
    }, [formData]);

    return {handleBoardWrite};
}
