import React from "react";
import Badge from "../../components/Badge/Badge";
import {formatLocalString} from "../../utils/validation";

function MyPaymentList({ listData }) {

    const testBadgeType = {
        '결제': { name: '결제', color: 'neutral-50' },
        '환불': { name: '환불', color: 'red' },
    };




    return (
        <div className="my_list my_test">
            {listData.map((item, index) => (
                <div className="assessment_info childrenDetail" key={index}>
                    <div className="assessment_details">
                        <div className="assessment_badge">
                            <Badge badgeText={testBadgeType[item.stepTxt].name} badgeColor={testBadgeType[item.stepTxt].color} />
                        </div>
                        <div className="assessment_title ellipsis1">{item.goods_name}</div>
                        <div className="assessment_meta group_line" style={{ paddingTop: "6px" }}>
                            <div className="assessment_code group_item">{formatLocalString(item.price)}원 {item.stepTxt}</div>
                            <div className="assessment_code group_item">{item.registDateTxt}</div>
                        </div>
                    </div>
                    <div className="children_image_container">
                        <img className="Children_Img" src={item.list_image} alt="Children_Img" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MyPaymentList;
