import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import Header from "../../components/Header/Header";
import {instance} from "../../services/services";
import {CERTIFICATION_CHECK, PASSWORDFIND_URL} from "../../utils/urls";
import {verifyPossession} from "../../services/Information/information";
import {numericRegex} from "../../utils/validation";
import {toast} from "react-toastify";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import useToggle from "../../hooks/common/usePopupToggle";

function PasswordFind() {
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [certification, setCertification] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [isTimeOver, setIsTimeOver] = useState(false);
    const [timer, setTimer] = useState(120);
    const [certificationMsg, setCertificationMsg] = useState('');

    const confirmPopup = useToggle(false);
    useEffect(() => {
        let interval = null;
        if (isResendDisabled && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);

        } else if (timer === 0) {
            setIsResendDisabled(false);
            setTimer(120);
            setIsTimeOver(true)

        }
        return () => clearInterval(interval);
    }, [isResendDisabled, timer]);

    const handleBackClick = () => {
        window.history.back();
    };

    const handleInputChange = (setter) => (e) => {
        if (e.target.name === 'certification') {
            const numericValue = numericRegex(e.target.value);
            return setter(numericValue)
        }
        setter(e.target.value)
    };

    const handleSendCode = async () => {
        try {
            if (!id.trim() || !phoneNumber.trim()) {
                alert('아이디와 휴대폰 번호를 입력해주세요.');
                return;
            }
            setIsTimeOver(false)
            const response = await verifyPossession({
                userId: id,
                phoneNumber: phoneNumber,
                idCheck:'Y'
            });
            if (response.data.code === 200) {
                // 임시로 인증번호 전송 성공 처리
                setIsResendDisabled(true);
                setTimer(120);
                toast("인증번호가 발송되었습니다.");
            } else {
                navigate('/idfindfail?type=password');
            }

        } catch (error) {
            console.error('인증번호 발송 실패:', error);
        }
    };

    const handleNextClick = async () => {
        try {
            // 1step:: 인증번호 체크
            const certificationRes = await instance.post(CERTIFICATION_CHECK, {
                cert_code: certification,
                phoneNumber: phoneNumber
            });
            if (certificationRes.data.code === 200) {
                const verifyResponse = await instance.post(PASSWORDFIND_URL, {
                    userid: id,
                    user_name: '',
                    mem_phone: phoneNumber,
                    certification: certification
                });

                if (verifyResponse.data.code === 200) {
                    // 일반 회원

                    if (verifyResponse.data.data.join_path === 'direct') {
                        navigate('/newpassword', {
                            state: {
                                userid: id,
                                user_name: '',
                                mem_phone: phoneNumber
                            }
                        });
                    } else {
                        // sns 회원
                        confirmPopup.open();
                    }
                } else {
                    navigate('/idfindfail');
                }
            } else {
                setCertificationMsg(certificationRes.data.message);

            }

        } catch (error) {
            console.error('비밀번호 찾기 실패:', error);
        }
    };

    const isVerifyButtonEnabled = id.trim().length > 0 && phoneNumber.trim().length > 0;

    const isValidForm = isVerifyButtonEnabled && certification.trim().length === 6;

    return (
        <div className="idfind">
            <div className="content">
                <Header title="비밀번호 찾기" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="nickname_instruction">
                        아이디 및 휴대폰 번호를<br/>입력해 주세요
                    </div>
                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">아이디</div>
                        <input
                            type="text"
                            className="login_input"
                            value={id}
                            onChange={handleInputChange(setId)}
                            placeholder="아이디 입력"
                        />
                    </div>

                    <div className="nickname_input_container default_input">
                        <div className="nickname_label label">휴대폰 번호</div>
                        <div style={{position: 'relative'}}>
                            <input
                                type="text"
                                className="nickname_input"
                                value={phoneNumber}
                                name="certification"
                                onChange={handleInputChange(setPhoneNumber)}
                                placeholder="휴대폰 번호 입력"
                            />
                            <button
                                className="resend_button"
                                onClick={handleSendCode}
                                disabled={isResendDisabled || !isVerifyButtonEnabled}
                                style={{position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)'}}
                            >
                                인증번호 전송
                            </button>
                        </div>
                        <div style={{position: 'relative'}}>
                            <input
                                style={{marginTop: "8px"}}
                                type="text"
                                disabled={isTimeOver}
                                className="nickname_input"
                                maxLength={6}
                                value={certification}
                                onChange={handleInputChange(setCertification)}
                                placeholder="인증번호 입력"
                            />
                            {isResendDisabled && (
                                <div className="resend_time">
                                    <span>
                                        {Math.floor(timer / 60)}분 {String(timer % 60).padStart(2, '0')}초
                                    </span>
                                </div>
                            )}
                            {isTimeOver && (
                                <div className="resend_time">
                                    <span>
                                     인증번호 시간이 만료되었습니다.
                                    </span>
                                </div>
                            )}
                            {
                                certificationMsg && <div className="resend_time">
                                    <span>
                                     {certificationMsg}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button
                        label="비밀번호 변경"
                        variant="btn_next"
                        onClick={handleNextClick}
                        disabled={!isValidForm}
                        className={isValidForm ? 'active' : 'disabled'}
                    />
                </div>
            </div>
            <ConfirmPopup
                onYesClick={()=>navigate('/login')}
                isOpen={confirmPopup.isOpen}
                onClose={confirmPopup.close}
                isShowClose={false}
                desc="SNS 계정으로 가입하신 경우 해당 SNS 계정의 비밀번호를 찾거나 변경하실 수 없습니다. 가입하닌 플랫폼(네이버)에서 비밀번호 찾기를 진행해 주세요."
                title="SNS 가입 회원 안내"
            />
        </div>


    );
}

export default PasswordFind;
