import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {joinAppleUser, joinKakaoUser, joinNaverUser} from "../../services/Join/Join";

/**
 * 커스텀 훅: SNS 회원가입을 처리합니다.
 * @param {string} type - SNS 타입 ('naver', 'kakao', 'apple')
 * @param {object} params - 회원가입에 필요한 파라미터
 * @returns {object} - createSnsUser 함수
 */
const useSnsJoin = (type, params) => {
    const navigate = useNavigate();


    const createSnsUser = useCallback(async () => {

        const convertedData = {
            email: params.email,
            user_name: params.name,
            nickname: params.nickname,
            mem_phone: params.phonenumber,
            ...(type === 'naver' ? {naver_access_token: params.idToken} : {id_token: params.idToken})

        };
        try {
            let response;
            switch (type) {
                case 'naver':
                    response = await joinNaverUser(convertedData);
                    break;
                case 'kakao':
                    response = await joinKakaoUser(convertedData);
                    break;
                case 'apple':
                    response = await joinAppleUser(convertedData);
                    break;
                default:
                    console.warn(`Unsupported SNS type: ${type}`);
                    return;
            }

            if (response.data.code === 200) {
                navigate('/joinsuccess', {state: {userName: convertedData.user_name}});
            } else {
                navigate('/joinfail');
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
            navigate('/joinfail');
        }
    }, [type, params, navigate]); // 의존성 배열에 convertedData를 포함시켜야 함

    return {createSnsUser};
};

export default useSnsJoin;
