import React from 'react';

const OutPop = ({ isOpen, onClose, onYesClick,title='' ,desc='' ,isShowClose=true}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='modal-wrapper'>
                    <div className="modal-title">{title}</div>
                    {desc &&
                        <div className="modal-message">{desc}</div>}

                </div>
                <div className="modal-buttons">
                    {isShowClose &&
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>취소</button>
                    </div>
                    }
                    <div className="modal-button-wrapper">
                        <button className="modal-button-yes" onClick={()=> {
                            onYesClick();
                            onClose();
                        }}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default OutPop;
