import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Icon_menu from '../../assets/icon/Sort.png';
import Main_arrow from '../../assets/icon/main_arrow.png';
import N500Arrow from '../../assets/icon/icon-n500-Rarrow.png';
import body_img from '../../assets/img/img1.png';
import body_img2 from '../../assets/img/img2.png';
import body_img3 from '../../assets/img/img3.png';
import Icon_story_test from '../../assets/img/ic_Book_open.png';
import Icon_heart_prescription from '../../assets/img/Vector215.png';
import Icon_frame from '../../assets/img/Frame.png';
import Footer from '../../components/Footer/Footer';
import Tab from '../../components/Tab/Tab';
import NavBar from '../../components/NavBar/NavBar';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import Badge from "../../components/Badge/Badge";
import {MAIN_STORYLIST_URL} from '../../utils/urls';
import instance from '../../services/services';
import useUserStore from '../../store/user/userStore';
import {useBoardList} from "../../hooks/board/useBoardList";
import AlarmButton from "../../components/Button/AlarmButton";
import FreePopup from '../../components/Popup/FreePopup';

function Main() {
    const navigate = useNavigate();
    const tabsData = [
        {
            icon: Icon_story_test,
            label: '동화검사',
        },
        {
            icon: Icon_frame,
            label: '',
        },
        {
            icon: Icon_heart_prescription,
            label: '마음처방',
        },
    ];
    const initialParams = {
        boardid: "notice",
        offset: 1
    };
    const {
        fetchBoardList,
        topData,
    } = useBoardList("notice", initialParams,true);
    const [backgroundType] = useState("bg-light");
    const [images, setImages] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [storyList, setStoryList] = useState([]);
    const { userInfo,memberTokens } = useUserStore();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleFreeTrialClick = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    const handleYesClick = () => {
        navigate('/freeVideo');
    };

    useEffect(() => {
        setIsLoggedIn(!!memberTokens);
    }, [memberTokens]);  // userInfo가 변경될 때마다 실행

    const handelGeneralClick = () => {
        navigate('/generaltest')
    }

    const handleSynthesisClick = () => {
        navigate('/synthesistest')
    }

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev); // 메뉴 상태 토글
    };

    const handleButtonClick = () => {
        navigate('/storytestpage');
    }

    useEffect(() => {
        fetchStoryList();

    }, []);
    useEffect(()=>{
        fetchBoardList();
    },[fetchBoardList])
    // 동화 검사 이미지 
    useEffect(() => {
        if (storyList.length > 0) {
            // 원본 이미지 배열을 3번 복사
            const storyImages = storyList.map(story => story.image);
            const duplicatedImages = [...storyImages, ...storyImages, ...storyImages];
            setImages(duplicatedImages);
        }
    }, [storyList]);

    const fetchStoryList = async () => {
        try {
            const response = await instance.post(MAIN_STORYLIST_URL);
            if (response.data.success) {
                setStoryList(response.data.data);
            }
        } catch (error) {
            console.error('통신 실패', error);
        }
    };

    return (
        <div className="main">
            <div className={`content ${backgroundType}`}>
                <div className="main_title">
                    <div className="greeting">
                        {isLoggedIn && memberTokens ? (
                            <div className='login_user'>
                                <span className='greeting_name'>{userInfo.nickname}님,</span> 안녕하세요!
                            </div>
                        ) : (
                            <Link to="/login" style={{textDecoration: "none"}}>
                                <div className='login_section'>
                                    <div className='main_login'>로그인 해주세요</div>
                                    <div className='main_arrow'>
                                        <img className='main_arrow_img' src={Main_arrow} alt='Main_arrow'/>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className='title_icon'>

                        <AlarmButton  />
                        <img src={Icon_menu} className='Icon_menu' alt="메뉴 아이콘" onClick={toggleMenu}/>
                    </div>
                    <NavBar isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}/>
                </div>




                <div className="content_body">
                    <div className='notification_container' onClick={()=>navigate(`/notice/${topData.b_seq}`)}>
                        <div className='notification'>
                            <div className='notification_title'>공지</div>
                            <div className='notification_message ellipsis1'>{topData.subject}
                            </div>
                            <img className='notification_arrow' src={N500Arrow} alt='arrowIcon'/>
                        </div>
                    </div>

                    <div className="image_container">
                        <img className='body_img' src={body_img} alt='이미지'/>
                        <div className="overlay_content">
                            <div className="overlay_title">
                                동화로 내 아이의<br/>
                                심리를 알아보세요
                            </div>
                            <div className='button_wrapper'>
                                <button className="overlay_button" onClick={handleFreeTrialClick} >무료 체험하기</button>
                                <FreePopup 
                                    isOpen={isPopupOpen}
                                    onClose={handlePopupClose}
                                    onYesClick={handleYesClick}
                                />
                            </div>
                        </div>
                    </div>

                    <Link to='/assimilation' style={{textDecoration: "none"}}>
                        <div className="image_section">
                            <div className="image_wrapper">
                                <img className="body_img2" src={body_img2} alt="이미지"/>
                            </div>
                            <div className="text_content">
                                <div className="text_title">동화 검사가 궁금하다면</div>
                                <div className="text_subtitle">
                                    자세히 알아보기
                                    <div>
                                        <img className="text_icon_vector" src={N500Arrow} alt="arrowIcon"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>


                    <div className="image_section_container">
                        <div className="image_section_content">
                            <div className="image_item" onClick={handleButtonClick}>
                                <div className="image_wrapper">
                                    <img className="body_img3" src={body_img3} alt="이미지"/>
                                </div>
                                <div className="text_content">
                                    <div className="text_title">동화 검사</div>
                                    <div className="text_subtitle">
                                        동화 검사 바로가기
                                        <div>
                                            <img className="text_icon_vector" src={N500Arrow} alt="arrowIcon"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image_item_rolling">
                            {images.length > 0 && (
                        <Swiper
                                modules={[Autoplay]}
                                spaceBetween={8}
                                slidesPerView={"auto"}
                                autoplay={{
                                    delay: 0.5,
                                    disableOnInteraction: false,
                                }}
                                speed={5000}
                                loop={true}
                                freemode={true}
                            >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img className="body_img4" src={image} alt="이미지"/>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                            )}
                        </div>
                    </div>

                    <div className="assessment_container">
                        <div className="general_assessment" onClick={handelGeneralClick}>
                            <Badge badgeText='일반' badgeColor="primary-50"/>
                            <div>
                                <div className="assessment_item_health_check">마음 건강 점검</div>
                                <div className="assessment_item">심리 발달
                                    <br/>탄력성 검사
                                </div>
                            </div>

                        </div>

                        <div className="general_assessment" onClick={handleSynthesisClick}>
                            <Badge badgeText='종합' badgeColor='secondary-50'/>
                            <div>
                                <div className="assessment_item_health_check">성장 케어 분석</div>
                                <div className="assessment_item" style={{paddingTop: "6px"}}>몸맘뇌 성장
                                    <br/>종합 심리 검사
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer/>
                    </div>
                    <div>
                        <Tab tabs={tabsData}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;