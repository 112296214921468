import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import OutPop from "../../../components/Popup/OutPop";
import Header from "../../../components/Header/Header";
import Badge from "../../../components/Badge/Badge";
import { usePaymentComplete } from "../../../hooks/payment/usePaymentComplete";
import instance from "../../../services/services";
import { INSPECTION_INFO_URL, PAYCANCEL_URL, GENERAL_SYNTHESIS_URL } from "../../../utils/urls";
import RefundPopup from "../../../components/Popup/RefundPopup";


function ChildrenInformation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedGender, setSelectedGender] = useState("female");
    const [age, setAge] = useState(""); // 나이 입력값 상태
    const [name, setName] = useState(""); // 이름 입력값 상태
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [data, setData] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const order_seq = queryParams.get("orderId") || location.state?.order_seq;
    const goodsSeq = queryParams.get("goods_seq") || location.state?.goods_seq; // url 에서 goodsSeq 값 가져오기
    const [isRefundPopOpen, setIsRefundPopOpen] = useState(false);

    usePaymentComplete(); // 결제 완료 처리 

    useEffect(() => {
        const fetchGeneralData = async () => {
            try {
                const response = await instance.post(INSPECTION_INFO_URL, {
                    goods_seq: goodsSeq,
                    order_seq: order_seq
                });
                if (response.data.code === 200) {
                    setData(response.data.data);
                } else {
                    console.error("데이터 조회 실패");
                }
            } catch (error) {
                console.error("error 발생");
            }
        };
        fetchGeneralData();
    }, [goodsSeq, order_seq]);


    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleNextClick = async () => {
        if (isButtonEnabled) {
            try {
                const response = await instance.post(GENERAL_SYNTHESIS_URL, {
                    order_seq: data.order_seq,
                    item_option_seq: data.item_option_seq,
                    goods_seq: data.goods_seq,
                    age_txt: age,
                    gender: selectedGender,
                    test_user_name: name
                });

                console.log('검사 시작 요청 데이터:', {
                    order_seq: data.order_seq,
                    item_option_seq: data.item_option_seq,
                    goods_seq: data.goods_seq,
                    age_txt: age,
                    gender: selectedGender,
                    test_user_name: name
                });

                if (response.data.code === 200) {
                    navigate('/generaltestdetail', {
                        state: {
                            order_seq: data.order_seq,
                            goods_seq: data.goods_seq
                        }
                    });
                } else {
                    alert(response.data.message || '검사 시작 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('검사 시작 오류:', error.response?.data || error);
                alert(error.response?.data?.message || '검사 시작 중 오류가 발생했습니다.');
            }
        }
    };

    const handleRefundClick = () => {
        setIsRefundPopOpen(true);
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    const handleGenderChange = (gender) => {
        setSelectedGender(gender);
    };

    const handleRefund = async () => {
        // URL의 orderId 파라미터 값을 우선적으로 사용
        const orderSeq = queryParams.get("orderId") || location.state?.order_seq || data?.order_seq;
        
        try {
            // 필요한 값이 있는지 확인
            if (!orderSeq || !data?.item_option_seq) {
                console.error('환불 처리에 필요한 데이터 누락:', { orderSeq, item_option_seq: data?.item_option_seq });
                return;
            }

            const response = await instance.post(PAYCANCEL_URL, {
                item_option_seq: data?.item_option_seq,
                order_seq: data?.order_seq
            });

            // 서버 응답의 메시지를 표시
            alert(response.data.message || '환불 처리 중 오류가 발생했습니다.');
            
            // 성공한 경우에만 메인 페이지로 이동
            if (response.data.code === 200) {
                console.log('성공');
            }
            
            // 팝업 닫기
            setIsRefundPopOpen(false);
            
        } catch (error) {
            console.error('환불 처리 오류:', error.response || error);
            alert(error.response?.data?.message || '환불 처리 중 오류가 발생했습니다.');
        }
    };

    const isButtonEnabled = age.trim() !== "" && name.trim() !== "";

    return (
        <div className="ChildrenInformation">
            <div className="content">


                <Header title="일반 검사" handleBack={handleBackClick}/>
                <div className="intro_body">
                    <div className="intro_section">
                        <Badge badgeColor="primary-50" badgeText="일반"/>
                        <div className="intro_age_group">{data?.goods_name}</div>
                        <div className="assessment_meta group_line">
                            <div className="assessment_code">{data?.inspection_abbr}</div>
                            <div className="assessment_bar">&#124;</div>
                            <div className="assessment_question_count">{data?.question_count} 문항</div>
                        </div>
                        <div className="assessment_refund" onClick={handleRefundClick}>환불 진행하기</div>
                    </div> 
                </div>
                    <div className="line_height"/>

                    <div className="content_body">
                        <div className="age_input_section">
                            <div className="input_label">나이 입력</div>
                            <input className="age_input" type="text"
                                placeholder="만 나이로 기재해 주세요"
                                value={age}
                                onChange={(e) => setAge(e.target.value)} />
                        </div>

                        <div className="gender_input_section">
                            <div className="input_label">성별 입력</div>
                            <div className="gender_selection">
                            <div
                                className={`gender_option ${selectedGender === "female" ? "selected" : ""}`}
                                onClick={() => handleGenderChange("female")}
                            >
                                여자
                            </div>
                            <div
                                className={`gender_option ${selectedGender === "male" ? "selected" : ""}`}
                                onClick={() => handleGenderChange("male")}
                            >
                                남자
                            </div>
                            </div>
                        </div>

                        <div className="name_input_section">
                            <div className="input_label">이름 입력</div>
                            <input className="name_input"
                                    type="text" 
                                    placeholder="이름을 기재해 주세요"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <ul className="refund_phrase marker_list">
                            <li>검사 진행 후에는 환불이 불가합니다.</li>
                        </ul>
                    </div>


                <div className="Edit_btn_change">
                    <Button  
                        label="검사 시작하기" 
                        variant="btn_change" 
                        className={isButtonEnabled ? "active" : "disabled"}
                        onClick={handleNextClick}
                    />
                </div>
                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <RefundPopup
                    isOpen={isRefundPopOpen}
                    onClose={() => setIsRefundPopOpen(false)}
                    onYesClick={handleRefund}
                />
            </div>
        </div>
    );
}

export default ChildrenInformation;
