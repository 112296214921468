import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Badge from "../../components/Badge/Badge";
import Ellipse from "../../assets/img/Ellipse 100.png";
import Button from "../../components/Button/Button";
import CompleteImg from "../../assets/img/storyComplete.png";
import OutPop from "../../components/Popup/OutPop";
import ReportPop from "../../components/Popup/ReportPop";
import RetestPop from "../../components/Popup/RetestPop";
import { useNavigate } from "react-router-dom";

function StoryComplete(){
    const navigate = useNavigate();
    const [test] = useState(true);
    const [isOutPopOpen, setIsOutPopOpen] = useState(false);
    const [isReportPopOpen, setIsReportPopOpen] = useState(false);
    const [isRetestPopOpen, setIsRetestPopOpen] = useState(false);


    const handleBackClick = () => {
        setIsOutPopOpen(true); // OutPop 열기
    };

    const handleReportClick = () => {
        setIsReportPopOpen(true); // ReportPop 열기
    };

    const handleRetestClick = () => {
        setIsRetestPopOpen(true); // RetestPop 열기
    };

    const handleModalYesClick = () => {
        navigate("/");
    };

    
    return(
        <div className="StoryComplete">
            <div className="content">
            <Header title="대충 하면 되는거 아니야?" handleBack={handleBackClick} />
                <div className="content_body">
                    <div className="complete_wrapper">
                        <Badge badgeColor="primary-60" badgeText="동화" />
                        <div className="intro_payment_group">동화 검사를 완료했어요</div>
                        <div className="report_results">리포트 결과를 확인해 보세요</div>
                    </div>

                    <div className="CompleteImg_container">
                        <img src={CompleteImg} alt="CompleteImg" className="CompleteImg"/>
                    </div>

                    <div className="refund_info_container">
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text">리포트 확인 전에는 무제한 재검사가 가능합니다.</div>
                        </div>
                        <div className="refund_info_item">
                            <div className="ellipse_container">
                                <img src={Ellipse} alt="Ellipse" className="Ellipse"/>
                            </div>
                            <div className="refund_text">동화 리포트는 3회까지만 제공됩니다.</div>
                        </div>
                    </div>
                    

                </div>

                <div className="button_section">
                {test ? (
                    <>
                    <Button  
                        label="재검사하기" 
                        variant="btn_join" 
                        onClick={handleRetestClick} 
                    />
                    <Button 
                        label="리포트 보기" 
                        variant="btn_complete" 
                        onClick={handleReportClick} 
                    />
                    </>
                ) : (

                    <Button  
                        label="동화 다시보기" 
                        variant="btn_join" 
                    />
                )}
                </div>

                <OutPop
                    isOpen={isOutPopOpen}
                    onClose={() => setIsOutPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <ReportPop
                    isOpen={isReportPopOpen}
                    onClose={() => setIsReportPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />
                <RetestPop
                    isOpen={isRetestPopOpen}
                    onClose={() => setIsRetestPopOpen(false)}
                    onYesClick={handleModalYesClick}
                />



            </div>
        </div>
    )
}

export default StoryComplete;