import instance from "../services";


/**
 * 공지사항 리스트 API
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getNoticeList = async (params) => {
    try {
        return await instance.post('/api/board/notice/list', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/**
 * 공지사항 상세 API
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getNoticeDetail = async (params) => {
    try {
        return await instance.post('/api/board/notice/detail', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}


/**
 * FAQ 타입리스트
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getFaqTypeList = async () => {
    try {
        return await instance.post('/api/board/faq/type');
    } catch (error) {
        console.error(error);
        throw error;
    }
}


/**
 * FAQ 리스트
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getFaqList = async (params) => {
    try {
        return await instance.post('/api/board/faq/list', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}


/**
 * 1:1 신청 타입리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getInquiryTypeList = async () => {
    try {
        return await instance.post('/api/board/common/qnaType');
    } catch (error) {
        console.error(error);
        throw error;
    }
}
/**
 * 상담 신청 타입리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getCounselTypeList = async () => {
    try {
        return await instance.post('/api/board/common/counselType');
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 1:1 문의/상담 리스트
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getInquiryList = async (params) => {
    try {
        return await instance.post('/api/board/common/list', params);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 1:1 문의/상담 등록
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const createInquiryList = async (params) => {
    try {
        return await instance.post('/api/board/common/upload', params,{
            headers: {
                'Content-Type': 'multipart/form-data',
                processData: false,
            }
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 1:1 문의/상담 수정
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const updateInquiryList = async (params) => {
    try {
        return await instance.post('/api/board/common/modify', params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    processData: false,
                }
            });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * 1:1 문의/상담 삭제
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const deleteInquiryList = async ({b_seq}) => {
    try {
        return await instance.post('/api/board/common/delete', {b_seq});
    } catch (error) {
        console.error(error);
        throw error;
    }
}