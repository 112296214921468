import Header from "../../components/Header/Header";
import React, {useEffect, useState} from "react";
import Button from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {getWithdrawalType, withdrawAccount} from "../../services/MyPage/mypage";
import useUserStore from "../../store/user/userStore";
import {toast} from "react-toastify";


function Withdrawal() {
    const navigate = useNavigate()
    const {logout}  = useUserStore()
    const [reasonCode, setReasonCode] = useState('')
    const [withdrawalType, setWithdrawalType] = useState({})
    const getWithdrawalReason = async () => {
        try {
            const response = await getWithdrawalType()
            if (response.data.code === 200) {

                let listType = []
                for (const key in response.data.data) {
                    listType = [...listType, {label: response.data.data[key], value: key, id: `reason${key}`}]
                }
                setWithdrawalType(listType)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getWithdrawalReason()
    }, []);
    const isValidCheck = !!reasonCode
    const handleBackClick = () => {
        navigate(-1)
    }

    const checkWithdrawalReason = async (event) => {
        setReasonCode(event.target.value)

    }

    const withdrawalUser = async () => {

        try {
            const response = await withdrawAccount(Number(reasonCode))
            if(response.data.code===200){
                toast('탈퇴되었습니다.')
                logout()
                navigate('/')
            }else{
                toast(response.data.message)
            }

        }catch (e){
            console.error(e)
        }
    }
    return (
        <div className="withdrawal_section">
            <div className="content">

                <Header title="회원 탈퇴" handleBack={handleBackClick}/>
                <div className="content_body">
                    <div className="title_box">
                        <h6 className="title">정말 탈퇴하실건가요?</h6>
                        <p className="sub_para">그동안 아이큐 비타민을 이용하시면서 불편했던 점이나<br/>
                            개선사항을 알려주시면 적극 반영하도록 힘쓰겠습니다.</p>
                    </div>

                    <div className="withdrawal_box setting_box">
                        <p className="sub_title">계정을 삭제하려는 이유가 궁금해요</p>
                        <ul className="radio_iist setting_list">
                            {withdrawalType.length > 0 &&
                                    withdrawalType.map((reason, idx) => (
                                        <li className="default_radio setting_item" key={reason.id}>
                                            <label htmlFor={reason.id} className="setting_label">{reason.label}</label>
                                            <input value={reason.value} type="radio" id={reason.id} name="service"
                                                   onChange={event => checkWithdrawalReason(event)}/>
                                        </li>))

                            }
                        </ul>
                    </div>
                    <div className="withdrawal_box setting_box">
                        <p className="sub_title">계정을 삭제하려는 이유가 궁금해요</p>
                        <p className="sub_para">
                            1. 회원 탈퇴 시 회원님의 정보는 법률에 의거한 고객 정보 보호 정책에 따라 관리됩니다.
                            <br/><br/>
                            2. 탈퇴한 회원 정보는 복구할 수 없으며, 탈퇴 후 10일 이내 동일 아이디와 동일 휴대폰 번호로는 재가입이 불가능합니다.
                            <br/><br/>
                            3. 탈퇴시 기존에 이용했던 개인 정보(검사 관련) 및 서비스에 대한 확인은 불가합니다.
                            <br/><br/>
                            4. 연결된 SNS 계정을 해제하시고, 탈퇴해 주십시오. 연결 해제 없이 계속 진행하시는 경우 SNS 서비스 내에서 삭제되지 않습니다.
                        </p>
                    </div>
                </div>
                <div className="Edit_btn_change">
                    <Button
                        onClick={withdrawalUser}
                        label="탈퇴하기"
                        variant="btn_change"
                        disabled={!isValidCheck}
                        className={isValidCheck ? "active" : "disabled"}
                    />
                </div>
            </div>

        </div>
    )
}


export default Withdrawal