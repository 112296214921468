import React from 'react';
import { useNavigate } from "react-router-dom";

const FreeClosePopup = ({ isOpen, onClose, onYesClick }) => {
    const navigate = useNavigate();
    if (!isOpen) return null;
    
    
    const handleYesClick = () => {
        onYesClick();
        onClose();
        navigate('/');
    };

    return (
        <div className="modal-overlay" style={{background: 'none'}}>
            <div className="modal-content">
                <div className='modal-wrapper'>
                    <div className="modal-title">무료 체험을 종료하시겠습니까?</div>
                </div>
                <div className="modal-buttons">
                    <div className="modal-button-wrapper">
                        <button className="modal-button-no" onClick={onClose}>취소</button>
                    </div>
                    <div className="modal-button-wrapper">
                        <button className="modal-button-yes" onClick={handleYesClick}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    ); 
};

export default FreeClosePopup;
