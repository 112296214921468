import Header from "../../components/Header/Header";
import React from "react";
import {useNavigate} from "react-router-dom";


function Terms() {
    const navigate = useNavigate()
    const handleBackClick = () => {
        navigate(-1)
    }
    return (
        <div className="terms_container">
            <div className="content">
                <Header title="이용약관" handleBack={handleBackClick}/>

                <div className="content_body">
                    <h6 className="head_title terms_text">이용약관</h6>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제1조 목적
                        </p>
                        <div className="terms_desc terms_text">
                            본 회원 약관은 주식회사 아이큐비타민(이하 “IQV”)이 운영하는 웹 사이트에서 회원에게 제공하는 모든서비스,정보,자료의 이용과 관련하여 IQV과 회원 간의 권리 및
                            의무,책임사항,기타 필요한 사항을 규정함을 목적으로 합니다.
                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제2조 정의
                        </p>

                        <div className="terms_desc terms_text">
                            회원, 서비스, ID, 비밀번호, 계정 정보, 비회원,<br/>
                            <p>“회원”이란 본 약관에 동의하고 IQV에 개인정보를 제공하여 IQV이 관리/운영하는 웹 사이트 및 어플리케이션 등의
                                회원 등록 절차를 완료한 이용자로서 IQV가 제공하는 서비스를 이용할 수 있는 이용자를 의미합니다.</p>
                            <p>“서비스”란 IQV가 제공하여 회원이 이용할 수 있는 상품 구입 및 배송, 검사 진행 및 결과 확인, 디지털 도서 등의
                                서비스를 의미합니다.</p>
                            <p>“ID”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 결정하고 IQV가 승인하는 이메일을
                                의미합니다.</p>
                            <p>“비밀번호”라 함은 회원이 신청한 ID와 일치되는 회원임을 확인하고, 회원의 정보 및 권익, 기타 보안 사항의 보호를
                                위해 회원 자신이 정한 문자, 숫자, 특수
                                문자의 조합을 의미합니다.</p>
                            <p>“계정 정보”라 함은 회원의 ID, 비밀번호, 성명, 성별, 생년월일 등 회원이 IQV에 제공한 일반 정보 및 서비스를
                                이용하면서 생성된 정보를 통합하는
                                의미입니다.</p>
                            <p>“비회원”이라 함은 회원 가입 절차를 진행하지 않았으나 IQV가 제공하는 서비스를 일부 이용할 수 있는 자를
                                의미합니다.</p>
                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제3조 약관의 명시 및 효력
                        </p>
                        <div className="terms_desc terms_text">
                            <p>본 약관의 내용은 회원에게 서비스 화면을 통해 알리거나 이메일 발송 또는 연결 화면을 제공하는 기타의 방법으로 회원에게 공지하여 효력이 발생합니다.</p>
                            <p>IQV은「 약관의 규제에 관한 법률 」,「 정보통신망 이용 촉진에 관한 법률 」,「 전자상거래법 」,「 전자서명법 」,「 전자거래기본법 」등 관련 법령을
                                위배하지 않는 범위에서 본 약관을 개정할 수 있으며,개정된 약관은 제1항과 같은 방법으로 공지함으로써 효력이 발생합니다.</p>
                            <p>IQV은 회원이본 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.</p>
                            <p>IQV은 본 약관을 개정할 경우,현행 약관과 함께 개정된 약관의 적용일자,개정 전후의 내용 및 개정 사유를 이용자가 알기 쉽게 명시하여 IQV의 웹 사이트 초기
                                화면 또는 연결 화면,이메일 등을 통해 공지합니다.IQV의 웹 사이트 초기 화면 또는 연결 화면을 통해 개정될 약관의 공지가 게시되는 경우,그 적용일자 7일
                                이전(단,회원에게 불리하거나 중대한 사항의 변경인 경우,30일 이전)부터 적용일자 이후 14일 이후의(단,회원에게 불리하거나 중대한 사항의 변경인 경우,30일
                                이후)기간 동안 공지가 게시됩니다.</p>
                            <p>IQV이 본 약관을 개정한 이후,개정된 약관 정보를 알지 못해 발생하는 회원의 피해는 IQV에서 책임지지 아니합니다.</p>
                            <p>IQV이 본 약관을 개정할 경우,회원은 개정된 약관에 대해 거부할 권리가 있습니다. 회원은 변경된 약관이 공지된 후 14일 이내에 거부 의사를 표명할 수
                                있습니다. 만약 회원이 개정된 약관이 공지된 14일 이내에 거부의 의사 표시를 하지 않는 경우,개정 약관에 동의하는 것으로 간주합니다.</p>
                            <p>회원은 IQV의 개정된 약관 적용에 동의하지 않거나 거부하는 경우,IQV는 당해 회원과의 계약을 해지할 수 있습니다.</p>
                            <p>본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관한 사항은 「약관의 규제에 관한 법률 」, 「전자상거래 등에서의 소비자보호에 관한 법률 」,「정보통신망
                                이용촉진 및 정보보호 등에 관한 법률」,공정거래위원회가 정하는 「 전자상거래 등에서의 소비자보호 지침 및 관계법령 」또는 상견례에 따라 결정됩니다.</p>

                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제4조 회원 가입
                        </p>
                        <p className="terms_desc terms_text">
                            <p>회원가입을 하고자 하는 이용자는 본약관의 내용에 대하여 동의를 하고, IQV의 웹 사이트의회원가입 페이지에서 제공하는 양식을 완성하여 회원 가입 신청을
                                진행하여야 합니다.</p>
                            <p>제1항의 회원가입이 완료된 이후,심리 검사, 디지털 동화 등을 포함한 IQV의 서비스 일체를 이용하기 위하여(만 14세 미만의 미성년자의 경우)성명,
                                성별,생년월일,형제의 유무,형제 내의 서열 등을,(성인의 경우)성명,성별,생년월일,학력,직업 군등의추가적인 정보를 반드시 입력 및 등록하여야 합니다.추가적인
                                정보를 입력및 등록하지 아니한 경우,서비스 이용이 제한될 수 있습니다.</p>
                            <p>회원이 되고자하는 이용자는 이용 신청 시,제1항 및 제2항에서 요구하는 정보 항목에 대하여 실제 정보를 기재하여야 합니다. 실명 또는 정보를 허위로 기재하거나
                                타인의 명의를 도용한 경우,본약관에 의거하여해당 회원은 회원의권리를 주장할 수 없고, IQV은사전 통보 후,환급 없이 서비스 이용계약을 취소하거나 해지할 수
                                있습니다.</p>
                            <p>회원가입은 회원의 서비스 이용 약관에 대한 동의,회원의 이용 신청,그리고회원의 이용 신청내용에 대한 IQV의 승인으로 성립합니다. 서비스 이용 약관에 관한
                                동의는 회원 가입 이용 신청 당시,IQV웹 사이트의이용약관에 ‘동의함’을 선택하거나 기타 ‘동의’ 버튼 등을 통해 의사표시를 간주합니다.</p>
                            <p>IQV은 회원이 되고자 하는 자에게 실명 확인 및 본인 인증, 기타 필요한 정보를 요구할 수 있으며,그 절차와 방식은 관련 법령에 따릅니다.</p>
                            <p>회원은 회원 가입신청 시,입력한 당사자 정보가 변경되는 경우 이를 즉시 변경하여야 합니다.회원 당사자의 부주의로 정보를 수정하지 않아변경 전의 정보를 기반으로
                                발생한통지 및 연락 등에 의해 해당 회원에게 발생한 손해는 회원 정보를 변경하지 않은 회원의 책임으로 합니다.</p>
                            <p>회원이 입력한 개인정보는 [개인 정보 취급 방침]에 따라 철저히 관리및 보호됩니다.</p>

                        </p>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제5조 IQV의 의무
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 회원 개인 정보 보호<br/> 관련IQV는 회원의 정보를 별도로 규정하는 [개인 정보 취급 방침]및 관련 법령에 의거하여 철저히 보호,관리하며,양질의 서비스를
                                운영하거나 개선하는 데에만 사용하고,상업적인 목적으로 타 기관 및 개인 또는 제3자에게 양도하지 않습니다.</p>
                            <p> 법령 및 약관에 의거하여 행동 및 노력<br/>IQV는 법령과 본 약관에서 정한 바에 따라 권리의 행사와 의무의 이행에 성실하게 임하려 노력하며, 안정적인
                                서비스를 지속적으로 제공할 의무가 있습니다.또한,법령과 본 약관이 금지하거나 공공의 질서와 사회의 질서, 미풍양속에 반하는 행위를 하지 않습니다.</p>
                            <p> 지속적인 서비스 제공 및 복구<br/> IQV는 지속적이고 안정적인 서비스 제공하는 의무에 기반하여 서비스 및 시스템 개선 과정 중 설비에 문제가 생기거나
                                데이터등이 멸실 된 때에는 천재지변,비상사태,해결이 곤란한 기술적 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을
                                다합니다.단, 협력사의 설비에 장애 및 문제가 발생하거나 협력사의 고의 또는 과실로 인하여 데이터 등이 멸실 된 때에는 IQV에 고의 또는 중과실이 없는 한
                                IQV은 면책됩니다.</p>
                            <p> 불만, 의견,민원 제기 관련<br/>IQV는 회원으로부터 제기되는 의견이나 불만,제안이 합당하다고 인정할 경우, 회원의 요구를 최대한 반영할 수 있도록 신속히
                                처리하여야 합니다. 만일, 어떠한 사정으로 인하여 신속한 처리가 곤란한 경우, 회원에게 그 사유와 예상되는 처리 일정을 통보하여야 합니다.</p>
                            <p> 광고 게재 관련<br/>IQV는 서비스의 운영 및 확장과 관련하여 홈페이지,웹 페이지 서비스 화면, 이메일 등에 광고 등을 게재할 수 있습니다.</p>
                            <p> 회원 손해 관련<br/>IQV이 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우,그러한 손해가 IQV의 고의나 과실에 의해 발생한 경우에 한하여 책임을
                                부담하며,그 책임의 범위는 통상 손해 범위에 한합니다.또한,IQV는 상품이나 용역에 대하여 표시, 광고의 공정화에 관한 법률 제3조에 근거하여 소정의 부적절한
                                표시,광고 행위를 함으로써 이용자의 손해가 발생한 경우,그러한 손해가 IQV의 고의나 과실에 의해 발생한 경우에 한하여 이를 배상할 책임을 집니다.</p>
                            <p> 기타 수단을 통한 정보 전달 가능성 관련<br/>IQV는 회원의 편의성 및 혜택 증대와 서비스 품질의 향상을 위하여 문자, 이메일등을 통해 다음과 같은 사항을
                                알릴 수 있습니다.
                                <br/>
                                서비스 변경 사항 안내<br/>
                                신규 상품 및 서비스 제공 안내
                            </p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제6조 회원의 의무
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, IQV가 통지하는 사항을 준수할 의무가 있습니다.</p>
                            <p> 회원은 다음 행위(이에 준하는 행위 포함)를포함하여기타 IQV의 업무에 방해되거나 위반되는 행위를 하여서는 안 됩니다.해당 행위를 하는 경우,IQV는 회원의
                                서비스 이용 제한 및 적법한 조치를 포함한 제재를 가할 수 있습니다.<br/>
                                - 본 웹 사이트의 서비스를 통해 얻은 정보를 IQV과 사전 논의 및 승낙 없이 서비스 이용 외의 목적으로 서비스를 복제,모방,분해,변형하는 행위 - 리버스
                                엔지니어링,디컴파일,디스어셈블 및 기타 일체의 가공 행위를활용한 모든 콘텐츠 복제,모방,분해,변형하는 행위<br/>
                                - 본 웹 사이트의 서비스를 통해 얻은 정보를 IQV과 사전 논의 및 승낙 없이 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위<br/>
                                - 본 웹 사이트의 운영 시스템,컴퓨터 소프트웨어 및 하드웨어 등을 포함한 운용 장비의 정상적인 작동을 방해하고 파괴할 목적으로 고안된 프로그램을 작동하거나
                                전자우편으로 발송하는 행위 -바이러스,악성코드,기타 컴퓨터 코드 등의 프로그램을 고의 또는 과실로 설치 및 유포하는 행위 - 바이러스,악성코드,기타 컴퓨터
                                코드 등의 프로그램과 파일을 포함하고 있는 자료를 고의 또는 과실로 게시하거나 전자우편으로 발송하는 행위<br/>
                                - 회원 계정의 생성 및 회원 정보의 등록 과정에서 허위(과장,축소,변형 등을 포함하며 이에 한정하지 않음)로 작성하여 회원 가입을 하는 행위<br/>
                                - 비정상적으로 서비스를 이용하는 행위
                                <br/>- 반복적으로 회원 탈퇴와 재가입을 하는 행위 - 정당한 사유 없이 다수의 계정으로 서비스를 이용(심리 검사 진행 및 디지털 동화 구매 등을 포함)
                                하는 행위 -동일 또는 유사한 내용의 게시글을 2회 이상 반복하여 작성하는 행위<br/>
                                - 본 웹 사이트의 서비스를 이용하여 웹 사이트의 회원을 포함한 불특정 다수를 대상으로 광고를 게시하거나 전자 우편을 전송하는 등 영리 목적의 활동을 하는
                                행위<br/>
                                - 본사 IQV의 업무를 방해하는 모든 행위 <br/>- 게시글 (문의 게시판과 본사 연락망 등을 포함한 모든 게시글 포함)의 도배 또는 스팸 <br/>-
                                ·다른
                                회원의 계정 정보를 요청하는 행위 <br/>- 본 웹 사이트의 원활한 서비스 제공을 위해 필요한 연락과 요청을 거부 또는 지연하는 행위 <br/>- IQV의
                                사전 승낙 없이
                                진행하는 모든 광고,영업,영리 목적의 활동,정치 활동 등의 제공 서비스의 도메인과 일치하지 않거나 부합하지 않는 행위<br/> - IQV이 지정하는 이용 요금
                                지불
                                방식에 따르지 않거나 이용 요금 지급 계약을 거부 또는 지연하는 행위 <br/>- 이용 요금 (유료 서비스비용 및 제품 비용을 포함한 기타 IQV에 지급하여야
                                하는
                                일체의 대금 지급을 의미)을 미납 또는 지연하는 행위 <br/>- IQV가 2회 이상 안내,공지 및 거절한 사항에 대하여 반복적으로 문의 또는 요청하는
                                행위<br/>
                                - 공공질서,사회질서,미풍양속 등을 위반하는 모든 행위 <br/>- 미풍양속에 반하는 정보(과도한 신체 노출,음란 행위를 묘사하거나,타인에게 성적 수치심이나
                                불쾌감을 유발할 수 있는 내용)를 IQV 웹 사이트에 등록하는 행위 - 사회 통념에 반하는 행위(비속어 또는 은어 사용,성희롱,외모 평가,반말 등) -
                                욕설,협박,폭언,폭행 등 포함하여 비방이나 명예를 훼손하는 행위 -성별,연령,장애,정치,종교,인종,거주 지역,직업,사회적 신분등에 대한 차별 및 편견을
                                조장하는 행위 - 기타 관련 법령에서 금하거나 기타 풍속과 사회 관념,통념상 허용되지 않는 행위 - 민사,형사,행정,진정 요청 등을 진행한 이후 이유 없음이
                                결정되는 상황 (당사자가 취소한 상황 포함)<br/>
                                - 타인의 권리를 침해하는 행위 <br/>- IQV과 타인(회원 포함)의 특허권,상품권,지적 재산권 등 모든 권리를 침해하거나 침해할 우려가 있는 행위 -
                                타인의 개인정보를 동의 없이 수집하여 저장및공개하는 등 타인의 개인정보를 침해하는 행위 - 기타 웹 해킹 프로그램,매크로 프로그램,기타 미러링 사이트 등을
                                이용하여 IQV과 타인의 모든 권리를 침해하거나 침해할 우려가 있는 행위 - 민사,형사,행정등 공적인 이의 제기를 진행한 이후 이유 없음이 결정되는 상황
                                (당사자가 취하한 상황 포함)<br/>
                                - 타인의 서비스 이용을 방해하는 행위 <br/>- 타인을 지속적으로 괴롭히는 행위 (스토킹 등)<br/>
                                - IQV, IQV의 임직원,IQV 웹 사이트 서비스의 관리자,다른 회원의 명의를 가장 또는 사칭하거나 도용하여 글을 게시하거나 회원들에게 전자우편을 발송하는
                                행위, 다른 회원의 ID를 도용하는 행위<br/>
                                - 원활한 서비스 제공에 악영향을 미칠 우려가 있는 행위 <br/>- IQV, 회원,제3자,공공의 이익을 저해할 우려가 있는 행위<br/> - 정크
                                메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 전자 우편이나 외설
                                또는 폭력적인 메시지, 화상, 음성 등이 담긴 전자 우편을 보내는 행위 -기타 공공의 질서,사회의 질서, 미풍양속에 반하는 정보를 공개 또는 게시하는
                                행위<br/>
                                - 본 조를 포함하여 본 약관에서 금지하는 행위 및 그에 준하는 행위와 IQV이 제공하는 서비스에서 정한 약관과 기타 서비스 이용에 관한 규정을 위반하는 행위
                                앞서 언급한 행위를 시도하거나 타인(회원을 포함한 제3자)이 언급한 행위를 하도록 유도 및 제안,알선하는 행위 및 그러한 우려가 있는 일체의 행위<br/>
                            </p>


                        </div>

                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제7조 회원의 권리
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 회원은 언제든지 다음의 권리를 요구 및 행사할 수 있습니다. <br/>① 개인정보 열람 <br/>② 오류 사항 발생 시, 개인정보 정정 또는 일부
                                삭제<br/> ③
                                개인정보 삭제 <br/>④ 개인정보 처리 정지 단,<br/> IQV가 회원에 대하여 반드시 필요로 하는 최소한의 회원 및 신상정보에 대해서는 회원 탈퇴의
                                경우가 아닌
                                이상 삭제가 불가합니다.</p>
                            <p> 제1항에 따른 권리 행사는 「 개인정보 보호법 」 시행규칙 별지 제8호 서식에 의한 서면, 전자우편, FAX 등을 통해 하실 수 있으며, IQV은 본인 확인을
                                위한 신분증 사본 확인 등 일련의 과정 및 증빙을 요구할 수 있습니다. 이는 권리 행사가 실제 본인의 의사와 일치하는지 확인하는 과정이며, 회원 본인으로
                                확인되고 개인정보에 관하여 정정 또는 삭제될 필요가 있다고 인정되는 경우, 지체 없이 그에 따른 조치를 취할 것입니다.</p>
                            <p> 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우, IQV은 정정 또는 삭제를 완료할 때까지 당해 해당 회원의 개인정보를 이용하거나 제공하지 않습니다.
                                제1항에 따른 권리 행사는 회원 본인뿐만 아니라 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있습니다. 이 경우 「 개인정보 보호법 」
                                시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.</p>
                            <p> IQV의 회원은 정보 제공에 동의한 이후에도 IQV가 향후 기타 패밀리 사이트에 회원 정보를 제공하는 것을 원하지 않을 경우 언제라도 정보 제공에 대한 동의를
                                철회 요청할 수 있습니다.</p>
                            <p> 회원은 IQV 웹 사이트 내의 각종 서비스 및 정보 이용에 관하여 발생하는 의견과 불만사항을 언제든지 IQV에 제안하고, 시정 또는 개선을 요구할 수
                                있습니다. IQV는 회원 개개인의 요구사항을 최대한 반영하기 위하여 합리적인 노력을 기울일 예정이나, 회원의 제안 및 시정 요구에 대해 모두 수용할 의무를
                                지는 것은 아니합니다.</p>
                            <p> 회원은 언제든지 IQV 웹 사이트의 회원 탈퇴를 요청할 수 있으며, 이러한 요청에 대하여 IQV는 회원의 개인정보 삭제 등 회원 탈퇴에 필요한 모든 조치를
                                즉각 취할 것입니다. 단, 제품 등의 거래가 완료되지 아니한 경우, 우선적으로 거래를 완료하여야 하고, 탈퇴 과정 중 거래를 철회 및 취소하는 경우, 그로
                                인한 불이익은 회원이 부담하여야 하며 거래의 이행이 완료되지 않은 경우, 완료될 때까지 회원 탈퇴가 제한될 수 있습니다.</p>
                            <p> 법정 대리인은 만 14세 미만 아동의 개인정보 수집 및 이용 또는 제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에 대한 열람 혹은 정정을
                                요구할 수 있습니다.<br/>
                                만 14세 미만 아동인 회원의 법정 대리인이 회원 정보 및 개인정보의 열람, 증명을 요구하는 경우, IQV는 서면, 전자우편, FAX를 통하여 대리관계를
                                증명하는 위임장 및 인감 증명서, 요청인 및 대리인의 신분증 사본 등의 증빙 자료를 요구할 수 있으며, 아동의 개인정보 관련 요구를 하는 자가 진정한 해당
                                아동의 법정 대리인인지 여부를 확인합니다.</p>

                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">

                            제8조 회원의 해지 및 자격 박탈
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV은 다음 각 호에 준하는 행위를 한 회원의 경우, 일정 기간 내 1회 시정할 것을 권고하고, 그 기간 동안 시정되지 않을 경우 서비스 이용 계약을 해지할
                                수 있습니다. 단, 회원이 현행법 위반 및 고의 또는 중대한 과실로 IQV에 손해를 입히거나, 입히려 한 경우, 사전 통보 절차 없이 서비스 이용 계약을
                                해지할 수 있습니다. <br/>① 회원 가입 시 입력 사항을 허위로 기재한 경우(비 실명 가입 등 제한하지 않음) <br/>② 동일한 사용자가 여러 개의
                                아이디를 생성하여 다중
                                등록한 경우<br/> ③ IQV의 웹 사이트 및 서비스를 이용하여 영리 목적의 광고를 시도한 경우</p>

                            <p> IQV은 회원이 본 약관을 위반한 경우 또는 다음 각 호에 해당하는 경우(이에 준하는 행위를 포함)에는 별도 사전 통보 절차 없이 서비스 이용 계약을
                                해지하고, 회원의 자격을 박탈할 수 있습니다.<br/> ① IQV의 운영에 관하여 허위 및 과장된 사실을 적시, 유포하여 IQV의 신뢰성 및 평판에 문제를
                                일으키는
                                경우 <br/>② IQV의 직원에게 폭언 및 욕설, 기타 음란한 언행을 하며 업무 환경을 해하거나 직원에게 불쾌감을 주는 등 심각한 폐해를 끼친다고 판단되는
                                경우<br/> ③
                                IQV의 웹 사이트를 이용하는 과정에서 사유 없는 잦은 연락 및 비난, 협박, 타당한 인과 관계가 없는 모든 피해 보상 요구, 소란 등으로 업무를 심각하게
                                방해하는 경우<br/> ④ IQV의 웹 사이트의 상품 및 서비스에 문제 및 이상이 없음에도 상습적으로 반품 및 손해 배상을 요구하거나, 상품 및 서비스를 일부
                                이용 후
                                반복적으로 전부 또는 일부를 반품하는 등의 업무를 심각하게 방해하는 경우<br/> ⑤ 타인에 대한 비방, 모욕, 허위 정보의 유포 및 게시, 욕설, 폭언
                                등으로 인하여
                                타인의 명예를 손상시키거나 불이익을 주는 경우, 타인에게 불쾌감을 주거나 심각한 폐해를 끼친다고 판단되는 경우 <br/>⑥ 타인의 서비스 아이디 및 비밀번호를
                                무단으로
                                도용한 경우 <br/>⑦ IQV의 웹 사이트를 이용하여 음란물을 거래 혹은 유포하는 행위, 서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해 및 방해하는
                                경우 ⑧
                                국익, 공익, 사회적 이익을 저해할 목적으로 서비스 이용을 계획 또는 실행한 경우,<br/> ⑨ IQV의 웹 사이트를 통하여 형사 처벌의 대상이 되거나 기타
                                실정법에
                                위반하는 등 범죄적 행위에 관련되는 경우 <br/>⑩ 기타 관련 법령이나 IQV이 정한 이용 조건에 위배되는 경우</p>

                            <p> 제2항에 의하여 IQV과의 회원 계약이 해지되는 경우, 회원에게 제공되는 서비스는 모두 중단되며, 회원의 활동과 관련하여 누적된 모든 사항은 모두 비공개
                                처리됩니다.</p>


                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제9조 면책 조항
                        </p>
                        <div className="terms_desc terms_text">
                            <p>IQV의 회원은 자신의 권한과 책임으로 IQV 웹 사이트 및 서비스에 정보, 의견, 자료, 사실 등을 게재하며, IQV는 서비스 과정에서 회원이나 제3자에 의해
                                개제된 정보, 의견, 자료, 사실 등의 정확성과 신뢰성 여부를 검증하거나 입증할 의무가 없습니다. 또한, IQV는 회원이 IQV의 웹 사이트 및 서비스에
                                게시한 정보나 의견의 정확성, 신뢰성, 진실성을 보장하지 않으며, 자세한 내용에 관하여는 어떠한 책임을 부담하지 아니합니다. 회원이 위 서비스 자료를 신뢰하고
                                취사선택하여 이용하는 과정에서 입게 된 모든 종류의 손해 등에 대해서 책임을 부담하지 아니합니다.</p>

                            <p>IQV는 다음의 경우(이에 준하는 경우 포함)에는 면책에 해당합니다. <br/>① 천재지변, 국가비상사태, 전시, 즉각적인 해결이 곤란한 기술적 결함 등 기타
                                불가항적인
                                사유로 서비스를 제공할 수 없는 경우<br/> ② 사전에 IQV 웹 사이트를 통해 고지된 웹 사이트 보완 및 보수 작업, 정기점검 작업, 전기통신 장비 교체
                                작업,
                                공사 등의 사유로 서비스가 중지되거나 서비스에 장애가 발생한 경우<br/> ③ 회원의 귀책사유로 인하여 서비스의 중지, 서비스 이용의 장애, 서비스 이용의
                                계약이
                                해지된 경우 <br/>④ IQV의 고의 또는 중대 과실이 없는 네트워크 환경으로 인하여 문제가 발생하는 경우<br/> ⑤ 회원의 개인적인 통신 장비 및 컴퓨터
                                환경 및
                                오류로
                                인하여 문제가 발생하는 경우 <br/>⑥ IQV의 고의나 중과실이 없이 회원 상호 간 또는 회원과 제3자 간에 서비스를 매개로 분쟁이 발생하거나 분쟁을 통해
                                손해가
                                발생한 경우<br/> ⑦ IQV의 고의나 중과실이 없이 회원 상호 간 또는 회원과 제3자 간에 서비스를 매개로 분쟁이 발생한 경우<br/> ⑧ IQV이
                                제공하는
                                서비스에 문제가
                                없었음에도 회원이 서비스를 이용하면서 주관적으로 기대하는 이익을 얻거나 상실한 경우, 회원이 서비스의 자료를 통해서 취사선택하고 활용하는 과정을 통해 손해를
                                얻거나 상실한 경우<br/> ⑨ 회원 자신의 회원 정보를 부정확하게 기재하거나, 기재하지 않아 손해가 발생한 경우 <br/>⑩ 관련 법령 및 정부 정책 등에
                                따라
                                서비스를
                                제공할 수 없는 경우</p>

                            <p>IQV은 본사에서 제공하는 무료 서비스 및 IQV의 내부 자체 기준에 의거해 선별하여 제공하는 타사/타 기관의 서비스와 관련하여 신뢰도를 보장하지 않으며,
                                어떠한 법적 책임을 지지 아니합니다.</p>

                            <p>회원의 본 약관의 규정을 위반하는 행위와 기타 불법적인 행위로 인하여 IQV에 손해가 발생하거나 법적인 제재를 받은 경우, 해당 회원은 IQV에서 발생하는 비용
                                (손해배상금, 소송비용, 변호사 비용을 포함하는 기타 제한을 두지 않는 비용)을 부담하여 모든 손해를 배상하여 보전해야 합니다. 이와 같은 손해로부터 반드시
                                IQV는 면책 됩니다.</p>


                        </div>
                    </article>

                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제10조 분쟁의 해결
                        </p>
                        <div className="terms_desc terms_text">
                            <p>IQV의 서비스 제공과 관련하여 회원의 귀책사유가 있을 경우, 어떠한 손해가 발생하더라도 IQV는 책임을 지지 아니합니다. </p>
                            <p>IQV가 제공하는 서비스와 관련하여 IQV와 회원 사이에 분쟁이 발생한 경우, IQV과 회원은 발생한 분쟁을 원만하게 해결하기 위해 필요한 모든 노력을 하여야
                                합니다. 그럼에도 불구하고 소송이 제기될 경우, 소송은 본사 IQV의 주소지에 해당하는 법원을 관할 법원으로 합니다.</p>
                            <p>IQV과 회원 간에 제기된 전자상거래 소송에는 국내법을 적용합니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제11조 서비스 이용 계약의 성립
                        </p>
                        <div className="terms_desc terms_text">
                            <p>IQV의 웹 사이트 내의 일체 서비스 이용 계약은 다음과 같은 IQV의 회원 정책에 따라 회원 가입 신청 양식에 의거 본인의 정보를 입력하는 등록 절차와 회원
                                약관 및 개인정보보호방침에 대한 ‘동의’를 체크한 후, 추가적인 아동 및 성인의 정보를 등록하는 절차를 통해 성립됩니다. 특정 회원 등급의 경우, IQV가
                                해당 등급에 대한 확인 절차를 거쳐 승인을 함으로써 성립됩니다.</p>
                            <p>IQV는 다음의 경우(이에 준하는 행위를 포함)에 해당하는 이용 신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.<br/>
                                - 회원 가입 시, 본인의 실명으로 신청하지 않은 경우<br/>
                                - 회원 가입 이용 신청서의 내용을 허위로 기재한 경우<br/>
                                - 다른 사람의 명의를 도용하여 회원 가입 신청한 경우<br/>
                                - 동일한 사용자가 중복하여 회원 가입 신청한 경우<br/>
                                - 미풍양속 및 사회적 질서, 공공의 질서를 저해할 목적으로 신청한 경우<br/>
                                - 기타 IQV가 정한 이용 신청 요건이 미완성인 경우<br/>
                            </p>
                            <p> 회원 및 이용자는 서비스 이용에 필요한 정보를 IQV에 제공하여야 하며, 이용자의 사용 과정상의 부주의로 인해 발생하는 모든 책임은 이용자에게 있습니다.
                            </p>
                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제12조 서비스의 내용 수정 및 변경
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV은 운영상의 필요 및 기술적 사양 변경에 따라 서비스 내용을 수정하거나 변경할 수 있습니다. 변경이 발생한 경우, 변경 사항(변경된 서비스의 내용 및
                                제공 예정 일자 등)을 IQV의 웹 사이트 메인 페이지 혹은 연결 화면을 통해 공지합니다. 이와 관련하여 IQV은 회원 또는 제3자에 어떠한 책임도 부담하지
                                않습니다.</p>
                            <p> IQV은 제품의 품절이 발생한 경우, 장차 체결되는 계약에 의해 제공할 제품의 내용을 변경할 수 있습니다. 변경이 발생한 경우, 제품의 내용 및 제공 예정
                                일자를 명시하여 현재 제품의 내용을 게시한 곳에 즉시 공지합니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제13조 서비스의 제공 및 중단
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV는 다음과 같은 서비스를 제공합니다. <br/>① IQV가 제공하는 제품에 관한 정보 제공, 구매 계약 체결 <br/>② IQV가 제공하는 서비스에 관한
                                정보
                                제공, 구매
                                계약 체결, 서비스 활동 참여<br/> ③ IQV가 규정한 기간 동안 제품 및 서비스의 이벤트 판매<br/> ④ 구매 계약 체결이 완료된 제품의
                                배송<br/> ⑤
                                특정한 상황
                                발생 시,
                                회원에 이메일을 통한 정보 제공 <br/>⑥ 기타 IQV가 정하는 업무 및 서비스</p>
                            <p> IQV은 회원의 제3, 4조에 따라 회원 가입 이용 신청을 승인한 때부터 서비스를 개시합니다. 다만, 특정한 회원 등급이나 IQV의 확인이 필요한 경우, 일부
                                서비스는 특정 시점 이후부터 개시할 수 있으며, 이를 회원 가입 시점을 기준으로 사전 또는 사후에 공지합니다.</p>
                            <p> 서비스 중 일부는 특정 회원에게만 제공될 수 있으며, 서비스를 일정 범위로 분할하여 이용자 별로 이용 가능한 시간을 별도 설정할 수 있습니다. 이런 특별한
                                상황이 발생하는 경우, 그 내용을 사전 또는 사후에 IQV 웹 사이트의 메인 화면 페이지 또는 연결 화면 페이지에 공지하거나 회원에게 이메일을 통해
                                공지합니다.</p>
                            <p> 서비스 제공은 연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 고객센터 및 문의 사항 관련한 고객 응대 서비스는 주말(토요일, 일요일)을 포함한 공휴일과
                                평일 오전 10시 이전, 오후 6시 이후에는 제한됩니다.</p>
                            <p> 제2항의 사안에도 불구하고, 다음의 상황에 해당하는 경우(이에 준하는 행위를 포함)에는 일정한 시간 및 기간 동안 IQV의 서비스가 제공되지 아니할 수
                                있으며, 해당 시간 및 기간 동안 IQV은 서비스를 제공할 의무가 없습니다.<br/> ① 천재지변, 국가비상사태, 전시, 정전 등의 IQV의 통제 및 관리가
                                불가능한
                                경우 <br/>② 컴퓨터, 서버 등 정보 통신 설비의 보수 점검, 교체, 정기 점검이 필요한 경우, 서비스의 수정 및 업데이트가 필요한 경우 <br/>③
                                서비스 설비의
                                장애
                                또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우 <br/>④ 관련 법령에서 특정 시간에는 서비스 제공을 금하는 경우, 관련 법령에서 특정
                                방법으로
                                서비스 제공을 금하는 경우 <br/>⑤ 해킹 등의 전자 침해적 사고, 통신 사고, 네트워크 상의 이슈, 이용자들의 비정상적인 콘텐츠 및 서비스 이용 문제 등에
                                적절한
                                대응이 필요한 경우 <br/>⑥ 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 IQV의 경영상 중대한 필요에 의한 경우</p>
                            <p> IQV은 제5항 사항에 해당되는 경우, 14일 전에 그 사실을 웹 사이트의 메인 화면 페이지 또는 연결 화면 페이지, 이메일 등을 통해 회원에게 고지합니다.
                                기간 동안 회원이 고지 내용을 인지하지 못한 부분에 대하여 IQV은 책임을 지지 아니합니다. 사전 고지가 불가한 부득이한 사정(긴급한 시스템 점검 및 교체,
                                시스템 또는 서비스 관리자의 고의나 과실 없는 디스크 장애:시스템 다운, 제3자 PC 통신 업체, 기간 통신 사업자 등의 사정으로 인한 서비스 제공의 불가 등
                                IQV이 통제 및 관리할 수 없는 사정)이 발생한 경우, 상황 종료 이후, 사후 통지할 수 있습니다.</p>
                            <p> 상단의 조항에 의거한 서비스 중지 상황으로 인해 본 서비스와 관련한 중요하고 기밀한 내용이 보관되지 못하거나 삭제, 메시지의 미 전송, 기타 통신 데이터의
                                손실 등이 발생할 수 있습니다. IQV의 고의 또는 과실이 없는 경우, 이에 대하여 책임을 지지 아니합니다. IQV의 사정으로 서비스를 일시적 또는 영구적으로
                                중단하여야 할 경우에 상기 항을 준용하며, 사전 고지 기간은 3개월로 합니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제14조 심리검사 및 상담서비스에 관한 책임의 제한
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV는 회원들이 실시한 심리 검사 내용 및 검사 결과가 회원 당사자와 서비스 관리자를 제외한 제3자에게 유출되지 않도록 최선을 다해 보안을 유지하려고
                                노력합니다. 그러나 다음과 같은 경우(이에 준하는 행위를 포함)에는 검사 내용과 검사 결과의 공개 및 상실에 대하여 IQV은 책임을 지지
                                아니합니다.<br/> ①
                                천재지변, 국가비상사태, 전시, 전쟁이나 기타 IQV가 통제 및 관리할 수 없는 상황에 의하여 검사 내용, 검사 결과가 공개 및 상실되었을 경우 <br/>②
                                시스템 및
                                장비 상의 문제로 IQV가 통제 및 관리할 수 없는 기술적 결함에 의하여 검사 내용, 검사 결과가 공개 및 상실되었을 경우<br/> ③ 이용자의 부주의로 회원
                                암호가
                                유출되어 검사 내용, 검사 결과가 공개되는 경우 <br/>④ 이용자 간의 회원 계정 공유로 인해 검사 내용, 검사 결과가 공개되는 경우</p>
                            <p> 서비스를 통해 발생한 심리 검사 내용, 검사 결과는 회원의 세부 및 사적인 신상 정보를 삭제 및 암호화한 뒤, 다음과 같은 목적으로 활용될 수
                                있습니다.<br/> ①
                                AI 프로세스를 위한 검사 결과 데이터 가공 및 활용<br/> ② DL/ML 분석을 위한 검사 결과 데이터 가공 및 활용 <br/>③ 학술 통계 및 활동을
                                위한 자료 가공 및 활용 검사의 개선 및 보수를 위한 통계 자료 활동 <br/>⑤ 마케팅 자료 활용을 위한 자료의 가공 콘텐츠 개발 연구 및 국가 정책 연구
                                활용을 위한 자료의 가공 및 활용</p>


                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제15조 이관 정책
                        </p>
                        <div className="terms_desc terms_text">
                            포인트와 온라인 코드 등 IQV에서 제공하는 제품, 상품, 서비스 등은 패밀리 사이트로 이관이 불가합니다.
                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제16조 게시물의 저작권
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV의 웹 사이트에서 IQV가 작성한 게시물의 저작권은 IQV에 귀속되며, 이용자는 회사의 동의 및 승낙 없이 이를 무단으로 사용할 수 없습니다.</p>
                            <p> IQV는 IQV의 웹 사이트의 서비스 내 모든 게시물에 대한 게시권을 갖습니다. 다음의 경우, 저작권자의 사전 동의 없이 게시물을 이용할 수 있으며, 원본의
                                내용을 크게 해치지 않는 범위에서 수정 및 재 가공할 수 있습니다. <br/>① IQV의 홍보 목적으로 미디어, 사이트, 기관 등에 콘텐츠를 제공하는
                                경우<br/> ② IQV가
                                제공하는 링크 사이트 및 제휴 관계를 맺고 있는 사이트 내에서의 콘텐츠 홍보, 서비스 안내 및 홍보를 수행하는 경우</p>
                            <p> 회원의 게시물이 타인의 저작권 등 모든 권리를 침해할 경우, IQV는 이에 대한 민사, 형사, 행정상의 책임을 지지 않습니다. 회원의 게시물에서 발생한 권리
                                침해 행위로 인해 IQV가 이의 제기 및 법적 제재를 받을 경우, 해당 회원은 IQV의 면책을 위해 노력하여야 하며, IQV가 면책되지 못할 경우, 그로 인해
                                발생하는 IQV의 모든 손해를 부담하여야 합니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제17조 게시물의 관리
                        </p>
                        <div className="terms_desc terms_text">
                            <p> 모든 게시물에 대한 책임은 게시물을 작성하고 게시한 회원에게 있으며, 게시물이 전달하는 정보의 정확성, 신빙성, 신뢰도 등에 대해서 IQV는 책임을 지지
                                아니합니다.</p>
                            <p> 회원이 직접 삭제한 게시물은 시스템 상에서 완전히 삭제되므로, IQV는 해당 게시물을 별도로 보관하거나 복구할 책임을 지지 아니합니다.</p>
                            <p> 게시물이 다음에 해당하는 경우(이에 준하는 행위를 포함), IQV는 이를 삭제 및 이동하거나 등록을 거부할 수 있고 서비스 내 표시 및 공람을 허용하지 않을
                                수 있습니다. <br/>① 공공질서, 사회적 질서와 미풍양속을 저해하는 내용인 경우, 현행법상 객관적으로 위반이 되거나 문제가 발생할 수 있는 내용인
                                경우<br/> ②
                                폭력적이거나 성적으로 음란한 내용인 경우 <br/>③ 타인에게 손해 또는 피해를 끼칠 수 있는 내용인 경우 - 개인이나 단체를 모욕 및 명예 훼손 등 피해를
                                줄 수
                                있는 의도를 담고 있는 내용 - 개인 신상 및 정보에 대한 내용으로 타인이나 단체의 명예를 침해할 수 있는 내용 - 타인의 지적 재산권이나 초상권 등 권리를
                                침해하는 내용 <br/>④ 불법 복제, 해킹, 기타 현행법을 위반하거나 저촉할 우려가 있는 내용인 경우<br/> ⑤ 광고나 홍보 등 영리를 목적으로 하는
                                상업적인
                                내용인 경우
                                <br/> ⑥ 정치적 판단이나 종교적 견해로 이용자 간의 위화감을 조장하거나 갈등을 유발하는 내용인 경우<br/> ⑦ IQV의 서비스 제공에 영향을 미치는
                                내용인 경우
                                -
                                IQV의 웹 사이트 내의 서비스에서 규정하고 있는 운영 원칙에 부합하지 않는 내용 - 동일한 내용을 반복 게시하여 다른 회원의 서비스 이용에 불편함을 유발하는
                                내용 ⑧ IQV가 피소, 고발될 수 있는 근거를 제공하는 내용인 경우 ⑨ 기타 관계 법령에 위배된다고 객관적으로 판단되는 내용인 경우</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제18조 휴면 계정
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV은 1년 동안 웹 사이트에 접속하지 않거나 서비스를 이용하지 않은 회원은 원활한 회원 관리를 위하여 휴면 계정으로 전환하고, 개인정보를 별도로 분리하여
                                보관합니다.</p>
                            <p> IQV은 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 회원에게
                                통지 가능한 방법으로 알리고 있습니다.</p>
                            <p> 휴면 계정으로 전환을 원하지 않으시는 경우, 휴면 계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면 계정으로 전환되었더라도 로그인을 하는 경우 회원의
                                동의에 따라 휴면 계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제19조 기타사항
                        </p>
                        <div className="terms_desc terms_text">
                            본 약관에서 규정하지 않은 사항과 약관의 해석에 관하여는 「 약관의 규제에 관한 법률 」, 「정보통신망 이용 촉진에 관한 법률 」, 「 전자상거래 법 」, 「
                            전자서명법 」, 「 전자거래기본법 」, 「 전자상거래 등에서의 소비자보호에 관한 법률 」, 공정거래위원회가 정하는 「 전자상거래 등에서의 소비자보호 지침 및 관계법령
                            」 등 관계 법령과 선례 및 관례를 따릅니다.
                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            제20조 만 14세 미만의 아동 및 청소년 보호 정책
                        </p>
                        <div className="terms_desc terms_text">
                            IQV은 만 14세 미만의 아동 및 청소년이 몸, 마음, 뇌가 건강하고 균형 있게 성장할 수 있도록 지원하기 위해 「 정보통신망 이용 촉진에 관한 법률 」, 「 정보
                            보호 등에 관한 법률 」, 「 청소년 보호법 」, 등에 근거하여 아동 및 청소년 보호 정책을 수립하여 시행하고 있습니다.<br/>
                            <p> 아동 및 청소년 보호를 위한 목표 및 기본 원칙 IQV은 아동 및 청소년이 신체적, 정서적으로 유해한 디지털 환경으로부터 보호받을 수 있게 조치하고, 아동 및
                                청소년에게 보다 유익하고 건강한 디지털 환경을 조성 및 제공하기 위해 노력합니다. 또한, 안전하게 서비스를 제공하기 위해 청소년 보호 정책을 명시하고
                                있습니다.</p>
                            <p> 아동 및 청소년 보호 업무 담당자 교육 시행 IQV은 아동 및 청소년 유해 매체에 대한 관리와 감독을 철저히 하기 위해 청소년 보호 업무 담당자 및 책임자를
                                지정하고, 정기적으로 교육을 진행하고 있습니다.</p>
                            <p> 유해 정보 관한 아동 및 청소년 접근 제한 및 관리 조치 IQV은 아동 및 청소년에게 위험하거나 유해한 콘텐츠를 생산하지 않음으로써 유해 정보의 발생 및
                                게시를 예방하고 있습니다. 만일 웹 사이트 이용 회원으로 인해 유해 콘텐츠가 게시되거나 운용될 시에는 즉각 해당 게시물을 삭제 조치하고 유해 콘텐츠를 이용한
                                회원에 강력한 제재를 가하여 유해 정보로 인한 피해를 최소화하겠습니다.</p>
                            <p> 아동 및 청소년 보호 책임자 및 담당자 연락 다음과 같이 아동 및 청소년 보호 책임자 및 담당자를 지정하여 아동 및 청소년을 위한 유해 정보의 차단 및 관리,
                                유해 디지털 정보로부터 청소년 보호 정책을 수립하는 등의 아동 및 청소년 보호 업무를 시행하고 있습니다.<br/><br/>
                                아동 및 청소년 개인정보 보호 책임자
                                <br/>- 성명: 김진아 <br/>- 직책: 대표 이사<br/> - 연락처: 02-6959-2377, kdccare@naver.com,
                                050-4023-2096(FAX)</p>
                            하기의 내용은 IQV의 검사 웹 사이트에 구매 기능이 추가되는 경우, 적용되는 약관입니다.<br/>
                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">

                            [구매 신청]
                        </p>
                        <div className="terms_desc terms_text">
                            IQV의 웹 사이트 이용자는 다음의 방법에 의하여 구매 신청이 가능하며, IQV는 이용자가 구매를 신청함에 있어서 다음의 각 내용을 이용자가 알기 쉽게 제공하여야
                            합니다. 단, IQV의 웹 사이트에 회원 가입을 진행한 회원일 경우, 제2호와 제4호의 적용을 제외할 수 있습니다.
                            <p> 서비스 및 제품 등의 검색 및 선택</p>
                            <p> 성명, 주소, 연락처, 이메일 주소 등의 입력</p>
                            <p> 약관, 환불 정책 등의 비용 부담과 관련한 내용에 대한 확인</p>
                            <p> 본 약관에 동의하고 제3호의 사항 및 내용을 동의하거나 거부하는 의사 표시</p>
                            <p> 서비스 및 제품 등의 구매 신청 및 이에 관한 확인</p>
                            <p> 비용 지불 방법과 서비스 및 제품의 결제 방법의 선택</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            [계약의 성립]
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV는 회원의 구매 신청이 있는 경우, 이메일로 수신 확인 및 계약 승인 통지를 전달하며, 수신 확인 및 계약 승인 통지가 회원에게 전달된 시점에 계약이
                                성립된 것으로 간주합니다.</p>
                            <p> IQV의 계약 승인 의사 표시에는 회원의 구매 신청에 대한 확인, 제품 및 서비스 등의 판매 가능 여부, 구매 신청의 정정 취소 등에 관한 정보를 포함하여야
                                합니다.</p>
                            <p> 이메일로 수신 확인 및 계약 승인 통지를 받은 회원은 이전의 구매 신청 의사와 불일치 등이 있는 경우, 수신 확인 통지를 받은 후 즉시 구매 신청 변경 및
                                취소를 요청할 수 있습니다. 제품의 배송 시작 이전에 회원의 요청이 있는 경우, IQV은 즉시 그 요청에 따라 처리하여야 합니다. 단, 회원이 이미 대금을
                                지불을 완료한 경우, 제X조의 청약철회 등에 관한 규정에 따릅니다.</p>
                            <p> IQV는 회원의 구매 신청에 대하여 다음 각 호에 해당하면 승인하지 않을 수 있습니다. 단, 미성년자와 계약을 체결하는 경우, 법정대리인의 동의가 반드시
                                필요하며, 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다. <br/>① 구매를 신청한 내용에 허위
                                기재, 과장
                                기재, 오기, 기재 누락이 있는 경우<br/>② 해당 구매 신청에 승낙하는 것이 IQV에 기술상 현저히 지장이 있다고 판단되는 경우</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            [결제 방법]
                        </p>
                        <div className="terms_desc terms_text">
                            QV에서 계약을 체결하는 제품 및 서비스에 대한 지불, 결제는 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
                            <p> 무통장 입금</p>
                            <p> 휴대폰 결제</p>
                            <p> 계좌 이체</p>
                            <p> 카드 결제</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            [결제 내용의 확인]
                        </p>
                        <div className="terms_desc terms_text">
                            회원의 제품 및 서비스 등의 결제 내역을 확인할 수 있도록 하여야 하며, 요청일로부터 14일 이내에 결제 내역을 이메일 등으로 고지합니다.
                            회원은 아래의 연락처로 결제 내역의 확인을 요청할 수 있습니다.<br/>
                            이 메일: kdccare@naver.com<br/>
                            전화 번호: 02-6959-2377<br/>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            [계약 철회 등]
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV와의 제품 및 서비스 등의 구매 관련 계약을 체결한 회원은 「 전자상거래 등에서의 소비자 보호에 관한 법률 」 제13조 제2항에 따른 계약 내용에 관한
                                서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 의미합니다)부터 7일
                                이내에는 청약의 철회를 할 수 있습니다.<br/> 단, 청약철회에 관하여 「 전자상거래 등에서의 소비자 보호에 관한 법률에 달리 정함이 있는 경우, 동 법
                                규정에
                                따릅니다.</p>
                            <p> 회원은 제품 등을 배송받은 뒤, 다음에 해당하는 경우(이에 준하는 행위를 포함)에는 반품 및 교환이 불가합니다.<br/> ① 회원의 단순 변심으로 인한 반품
                                또는 교환
                                요청이 결제 완료 후 14일이 경과한 경우 <br/>② 제품의 포장을 훼손하여 제품 등의 상품 가치가 현저히 감소한 경우 (단, 제품 등의 내용을 확인하기
                                위한 포장
                                개봉의 경우에만 계약 철회 가능)<br/> ③ 제품의 사용과 일부 소비에 의하여 제품 등의 상품 가치가 현저히 감소한 경우 <br/>④ 시간의 경과로 인해
                                재판매가
                                곤란할
                                정도로 제품 등의 상품 가치가 현저히 감소한 경우<br/>⑤ 회원에게 책임 있는 사유로 제품 등이 멸실 또는 훼손된 경우<br/> ⑥ 복제 가능성이 높은 제품
                                등의 포장을
                                훼손한 경우<br/> ⑦ 특별한 할인 혜택이 적용된 제품 등의 경우</p>
                            <p> IQV는 회원으로부터 제품 등을 반환받은 경우, 7 영업일 이내에 이미 전달받은 제품의 대금을 환급합니다. 회원의 결제 방법에 따라 즉시 제품 등의 대금을
                                환급하거나, 대금의 청구 정지 및 취소하도록 요청합니다.</p>
                            <p> 회원은 제1항, 제2항의 규정과 별도로 제품의 내용이 광고 및 제품의 상세 페이지의 내용과 다르거나 계약 내용과 다르게 이행된 때에는 제품을 공급받은 날로부터
                                14일 이내에 계약 철회 등을 할 수 있습니다.</p>
                            <p> 소비자의 단순 변심으로 인한 계약 철회의 경우, 공급받은 제품의 반품에 필요한 비용은 회원이 부담합니다. IQV는 계약 철회를 이유로 회원에게 부가적인 위약금
                                또는 손해 배상을 청구하지 않습니다. 단, 제4항에 의거해 계약 철회를 하는 경우 제품의 반환에 필요한 비용은 IQV가 부담합니다.</p>

                        </div>
                    </article>
                    <article className="terms_article">
                        <p className="sub_title terms_text">
                            [회원의 개인 신용정보 보호]
                        </p>
                        <div className="terms_desc terms_text">
                            <p> IQV는 회원이 안심하고 제품 및 서비스 등을 구매하고 이용할 수 있도록 결제와 관련된 개인 신용 정보를 최대한 보호합니다.</p>
                            <p> 개인 신용 정보 누출로 인한 사고가 발생하고, 이에 대해 IQV의 과실이 입증되는 경우, IQV는 사고에 따른 책임을 집니다.</p>
                            <p> 회원의 과실이나 부주의로 인해 개인 신용 정보가 누출되어 발생한 사고에 대해서는 회원에게 책임이 있으며, IQV는 이에 대하여 책임을 지지 아니합니다.</p>

                        </div>
                    </article>


                </div>
            </div>
        </div>

    )
}

export default Terms;